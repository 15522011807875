export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              SET_TOAST = 'SET_TOAST',
              //COMPANIES ACTIONS
              GET_COMPANIES = 'GET_COMPANIES',
              GET_COMPANY = 'GET_COMPANY',
              ERROR_COMPANIES = 'ERROR_COMPANIES',
              GET_CHANGES = 'GET_CHANGES',
              //ARTICLES ACTIONS
              GET_ARTICLES = 'GET_ARTICLES',
              ERROR_ARTICLES = 'ERROR_ARTICLES',
              CREATE_ARTICLE ='CREATE_ARTICLE',
              DELETE_ARTICLE = 'DELETE_ARTICLE',
              GET_ARTICLE = 'GET_ARTICLE',
              POST_ARTICLE = 'POST_ARTICLE',
              UPDATE_ARTICLE = 'UPDATE_ARTICLE',
              MESSAGE_ARTICLES = 'MESSAGE_ARTICLES',
              //MISSIONS ACTIONS
              GET_MISSIONS = 'GET_MISSIONS',
              ERROR_MISSIONS = 'ERROR_MISSIONS',
              MESSAGE_MISSIONS = 'MESSAGE_MISSIONS',
              UPDATE_MISSION= 'UPDATE_MISSION',
              //FILES_ACTIONS
              GET_FILES = 'GET_FILES',
              ERROR_FILES = 'ERROR_FILES',
              POST_FILE = 'POST_FILE',
              PUT_FILE = 'PUT_FILE',
              MESSAGE_FILES = 'MESSAGE_FILES',
              DELETE_FILE= 'DELETE_FILE',
              //USERS_ACTIONS
              GET_USERS = 'GET_USERS',
              ERROR_USERS = 'ERROR_USERS',
              CREATE_USER = 'CREATE_USER',
              UPDATE_USER = 'UPDATE_USER',
              DELETE_USER = 'DELETE_USER',
              MESSAGE_USER = "MESSAGE_USER",
              //PROJECT ACTIONS
              GET_PROJECTS = 'GET_PROJECTS',
              CREATE_PROJECT = 'CREATE_PROJECT',
              MESSAGE_PROJECTS = 'MESSAGE_PROJECTS',
              GET_PROJECT = 'GET_PROJECT',
              DELETE_PROJECT = 'DELETE_PROJECT',
              UPDATE_PROJECT = 'UPDATE_PROJECT',
              ERROR_PROJECT = 'ERROR_PROJECT',
              GET_PROJECTS_LIST = 'GET_PROJECTS_LIST',
              GET_COMPANIES_PUBLIC = 'GET_COMPANIES_PUBLIC',
              //AGENCIES ACTIONS
              GET_AGENCIES = 'GET_AGENCIES',
              POST_AGENCY = 'POST_AGENCY',
              GET_AGENCY = 'GET_AGENCY',
              DELETE_AGENCY = 'DELETE_AGENCY',
              UPDATE_AGENCY = 'UPDATE_AGENCY',
              ERROR_AGENCIES = 'ERROR_AGENCIES',
              MESSAGE_AGENCIES = 'MESSAGE_AGENCIES',
              GET_ZONES = 'GET_ZONES',
              //CONTACTS ACTIONS
              GET_CONTACTS = 'GET_CONTACTS',
              POST_CONTACT = 'POST_CONTACT',
              GET_CONTACT = 'GET_CONTACT',
              DELETE_CONTACT = 'DELETE_CONTACT',
              UPDATE_CONTACT ='UPDATE_CONTACT',
              ERROR_CONTACTS = 'ERROR_CONTACTS',
              MESSAGE_CONTACTS = 'MESSAGE_CONTACTS',
              GET_TAGS = 'GET_TAGS',
              POST_TAG = 'POST_TAG',
              //DOMAINS ACTIONS
              GET_DOMAINS = 'GET_DOMAINS',
              POST_DOMAIN = 'POST_DOMAIN',
              GET_DOMAIN = 'GET_DOMAIN',
              DELETE_DOMAIN = 'DELETE_DOMAIN',
              UPDATE_DOMAIN ='UPDATE_DOMAIN',
              ERROR_DOMAINS = 'ERROR_DOMAINS',
              MESSAGE_DOMAINS = 'MESSAGE_DOMAINS',
              //SUB DOMAINS ACTIONS
              GET_SUB_DOMAINS = 'GET_SUB_DOMAINS',
              POST_SUB_DOMAIN = 'POST_SUB_DOMAIN',
              GET_SUB_DOMAIN = 'GET_SUB_DOMAIN',
              DELETE_SUB_DOMAIN = 'DELETE_SUB_DOMAIN',
              UPDATE_SUB_DOMAIN ='UPDATE_SUB_DOMAIN',
              ERROR_SUB_DOMAINS = 'ERROR_SUB_DOMAINS',
              MESSAGE_SUB_DOMAINS = 'MESSAGE_SUB_DOMAINS',
              //INNOVATIONS ACTIONS
              GET_PAGE = 'GET_PAGE',
              UPDATE_PAGE = 'UPDATE_PAGE',
              ERROR_PAGE = 'ERROR_PAGE',
              MESSAGE_PAGE = 'MESSAGE_PAGE',
              RESET_PAGE = 'RESET_PAGE',
              SAVE_PAGE = 'SAVE_PAGE' ,
              //TALENTS ACTIONS
              GET_TALENTS = 'GET_TALENTS',
              POST_TALENT = 'POST_TALENT',
              DELETE_TALENT = 'DELETE_TALENT',
              UPDATE_TALENT = 'UPDATE_TALENT',
              ERROR_TALENTS = 'ERROR_TALENTS',
              MESSAGE_TALENTS = 'MESSAGE_TALENTS'
