import { getData , postData , putData ,  deleteData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_CONTACTS,
  POST_CONTACT,
  GET_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  ERROR_CONTACTS,
  GET_TAGS,
  POST_TAG
} from "./types"

export async function getTagsAction(dispatch , lang  , type ) {
  let url = `/tag/lang/${lang}/${type}`;
  var tags = [];

  await getData(ERROR_CONTACTS, url, dispatch , true ).then((response)=>{
    tags = response.tags
  });

  dispatch({
    type : GET_TAGS,
    payload : tags
  });

};

export async function createTagAction(dispatch , params ) {

  let url = "/tag"
  var tag;

  await postData(POST_TAG , ERROR_CONTACTS , url, dispatch , params, true ).then((response)=>{
    tag = response
  });

  dispatch({
    type : POST_TAG,
    payload : tag
  });

}

export async function getContactsAction(dispatch , lang ) {
  let url = "/contact/lang/" + lang;
  var contacts = [];

  await getData(ERROR_CONTACTS, url, dispatch , true ).then((response)=>{
    contacts = response.contacts
  });

  dispatch({
    type : GET_CONTACTS,
    payload : sortByUpdateDate(contacts)
  });

};

export async function createContactAction(dispatch , params ) {
  let url = "/contact"
  var contact;

  await postData(POST_CONTACT , ERROR_CONTACTS , url, dispatch , params, true ).then((response)=>{
    contact = response
  });

  dispatch({
    type : POST_CONTACT,
    payload : contact
  });

}

export async function getContactAction(dispatch , id ) {
  let url = "/contact/" + id
  var contact;

  await getData(ERROR_CONTACTS, url, dispatch , true ).then((response)=>{
    contact = response.contact
  });

  dispatch({
    type : GET_CONTACT,
    payload : contact
  });

}

export async function updateContactAction(dispatch , param ) {
  let url = "/contact/" + param._id
  var contact;

  await putData(UPDATE_CONTACT, ERROR_CONTACTS ,  url, dispatch, param , true ).then((response)=>{
    contact = response
  });

  dispatch({
    type : UPDATE_CONTACT,
    payload : contact
  });

}

export async function deleteContactAction(dispatch , id ) {
  let url = "/contact/" + id

  dispatch({
    type : DELETE_CONTACT,
    payload : id
  });

  await deleteData(ERROR_CONTACTS, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le contact a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_CONTACT,
    payload : id
  });

}