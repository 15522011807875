import { getData , putData } from './index';

import {
  GET_COMPANIES,
  GET_COMPANY,
  ERROR_COMPANIES,
  GET_CHANGES
} from "./types"

function geCompanyClassName(c) {
    const company = {...c}
    if(company.name === "Keran") {
      company.icon = "K";
      company.className = "K";
    }
    if(company.name === "Sce") {
      company.icon = "SCE";
      company.className = "SCE";
    } 
    if(company.name === "Créocéan") {
      company.icon = "C";
      company.className = "C";
    }
    if(company.name === "Créocéan Océan Indien") {
      company.icon = "COI";
      company.className = "CI";
    } 
    if(company.name === "Naomis") {
      company.icon = "N";
      company.className = "N";
    }
    if(company.name === "YS EMD") {
      company.icon = "YS";
      company.className = "YS";
    }
    if(company.name === "Groupe Huit") {
      company.icon = "GH";
      company.className = "GH";
    }
    console.log(company.name)
    if(company.name === "S3d"){
      company.icon = "S3D"
      company.className = "S3D"
    }
    if(company.name === "CIVITEO") {
      company.icon = "CI"
      company.className = "CI"
    }
    if(company.name === "Ateliers UP+") {
      company.icon = "UP+"
      company.className = "UP"
    }
  return company;
}

export async function getCompaniesAction(dispatch) {
  let url = "/company";
  var companies;

  await getData(ERROR_COMPANIES, url, dispatch , true ).then((response)=>{
    companies = response.companies.map((c) =>  (geCompanyClassName(c)))
  });



  dispatch({
    type : GET_COMPANIES,
    payload : companies.sort((a,b) => a.name -  b.name )
  });

};

export async function getCompanyAction(dispatch , id) {
  let url = "/company/" + id;
  var company;

  await getData(ERROR_COMPANIES, url, dispatch , true ).then((response)=>{
    company = geCompanyClassName(response.company);
  });

  dispatch({
    type : GET_COMPANY,
    payload : company
  });

};


export async function putCompanyAction(dispatch , id , param ) {
  let url = "/company/" + id
  var company;

  await putData(GET_COMPANIES, ERROR_COMPANIES ,  url, dispatch, param , true ).then((response)=>{
    company = response
  });

  console.log(company)
}

export async function getCompaniesChangesAction(dispatch) {
  let url = "/change";
  var changes;

  await getData(ERROR_COMPANIES, url, dispatch , true ).then((response)=>{
    changes = response.changes
  });

  dispatch({
    type : GET_CHANGES,
    payload : changes
  });

};