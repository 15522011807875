import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';

import Talent from './Talent';
import EditPage from '../../partials/EditPage';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 
import TextEditor from '../../partials/TextEditor';

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';
import { getTalentsAction } from '../../../actions/talentsActions';

import '../../../styles/pages-sites.scss';

export default function Career({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , requireRight , setActivePage , theme , lang }) {

  const param = useParams();

  const page = param.companyId === "5e9ec41b25ab1e65768e2ff0" ? 'career' : 'recruitment';

  const { handleSubmit } = useForm();

  const [ context, dispatch ] = useGlobalContext();
  const company = context?.companies?.company;

  const career = useRef({});
  const [ talents , setTalents ] = useState();
  const [ addTalent , setAddTalent ] = useState(false);
  const [ pages , setPages ] = useState([]);
  const [ video , setVideo ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();
  const [ isUpdated , setIsUpdated ] = useState(true);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ index , setIndex ] = useState();
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
    if (requireRight("talent")) getTalentsAction(dispatch , lang);
  },[dispatch , param.companyId , lang , requireRight ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("career");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setIsMounted(false);
      dispatch({
        type:'RESET_PAGE',
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireRight("talent")) getTalentsAction(dispatch , lang);
    // eslint-disable-next-line
  }, [context.auth.userAuth]);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    if (requireRight("talent")) getTalentsAction(dispatch , lang)
    career.current = null;
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.talents.talentsList) {
      setTalents(context.talents.talentsList.filter(t => t.company === param.companyId ));
    }
  }, [context.talents.talentsList , context.talents.message , param.companyId])

  useEffect(() => {
    if (context.theme.page) {
      career.current = {...context.theme.page};
      if (context.theme.page.video) setVideo(context.theme.page.video);
      if (context.theme.page.pages) {
        setPages(context.theme.page.pages)
      }
      setIsMounted(true);
      setIsSaved(true);
    } else {
      career.current = null;
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  // useEffect(() => {
  //   if (career.current) {
  //     if (career.current && context.theme.page && JSON.stringify(career.current) === JSON.stringify(context.theme.page)) {
  //       setIsSaved(true);
  //     } else {
  //       setIsSaved(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[career , context.theme.page])


  useEffect(() => {
    if (context.talents.message === 'Talent successfully updated!' || context.talents.message === "talent saved") {
      setIsSaved(true);
      setAddTalent(false);      
      dispatch({
        type:'MESSAGE_TALENTS',
        payload: null
      });

    };

    // eslint-disable-next-line
  },[context.talents.message]);

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  useEffect(() => {
    if (!displaySelectMedia) {
      setIndex();
    }
  }, [displaySelectMedia])


  function submitMedia(value) {
    var pageList = [...pages]
    switch (mediaKey) {

      case "video":
        career.current.video = value[0];
      break;

      case "picture":
        career.current.picture = value[0];
        break;

      case "file":
        pageList[index].file = value[0];
        setPages(pageList)
        submitCareer({ pages : pageList })        
      break;

      case "pageCover":
        pageList[index].cover = value[0];
        setPages(pageList)
        submitCareer({ pages : pageList })        
      break;

      case "talentVideo":
          var talentList = [...talents]
          talentList[index] = {...talentList[index] , video: value[0] }
          setTalents(talentList)
        break;

      case "talentPicture":
          var talentListPicture = [...talents]
          talentListPicture[index] = {...talentListPicture[index] , picture: value[0] }
          setTalents(talentListPicture)
        break;
  
      default:
        break;
    }
    setIsSaved(false);
    setIsUpdated(!isUpdated);
  };

  function handleChangeSection( value , obj ) {
    let data = {...career.current}
    data = Object.assign({} , data );
    if (data[obj]) {
      data[obj] = value
     }
    career.current = data;
    if (isSaved) {
      setIsSaved(false);
    }
    setIsUpdated(!isUpdated);

  };

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('picture');
    setMediaType('image');
    if (career && career.picture && career.picture._id) {
      var pictureArray = [];
      pictureArray.push(career.picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectTalentVideo(index) {
    setIndex(index)
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('talentVideo');
    setMediaType('video');
  }
  
  function openSelectVideo() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('video');
    setMediaType('video');
    handleChangeSection( [null] , "video");
    if (career.current.video) {
      var coverArray = []
      coverArray.push(career.current.video);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectPicture(index , key ) {
    console.log(key)
    setIndex(index)
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(key);
    setMediaType(key === 'file' ? 'file': 'image');
  }

  function renderVideo() {
    if(career.current?.video  && context.medias.mediasList) {
      var videoFound = context.medias.mediasList.find(item => item._id === career.current.video  );
      
      let url = null;
      if (videoFound) {       
        url = API_URL +'/'+ videoFound.path
      } else if (career.current.video.path) {
        url = API_URL +'/'+ career.current.video.path
      }

      return (
        <>
          {url &&
            <video id="video1" controls="controls">
              <source src={url} />
            </video>
          }
        </>
      )
    }
  }

  const submitCareer = (values)  => {
    var careerData = {...values, ...career.current};
    careerData.pages = pages ;
    var obj = {};
    for (const [key, value] of Object.entries(careerData)) {
        if( key !== 'articles' ) obj[`${page}.${key}`] = value;
    }

    setPublishIsLoading(true)

    if (param.companyId === "5e9ec41b25ab1e65768e2ff0") {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );
    } else {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );
    }

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(career.current && career.current.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === career.current.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="career" />
      } else if (career.current.picture.path) {
        return <img src={API_URL + '/' + career.current.picture.path} alt="career" />
      }
    }
  }

  function renderEdit() {

    return (
      <div className="list-item transition edit-row">
        {isMounted && career?.current &&
          <form onSubmit={handleSubmit(submitCareer)} autoComplete="nope">
        
              <div className="form-row">
                <div className="col-1">

                  <label htmlFor="title">Titre de la page</label>
                  <input
                    name="title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeSection(e.target.value , "title")}
                    defaultValue={career?.current?.title ? career.current.title  : "" }
                  />

                  <label>Paragraphe</label>
                  <TextEditor
                    value={career.current && career.current.text ? career.current.text : "" }
                    onChange={(value) => handleChangeSection( value , "text")}
                  />
                  {company?._id !== "661801566cf939d1592427a3" &&
                    <>
                      <label>Description</label>
                      <TextEditor
                        value={career.current && career.current.description ? career.current.description : "" }
                        onChange={newContent => handleChangeSection( newContent , "description")}
                      />
                    </>
                  }
                    <div className="section"></div> 
                  {param.companyId === "5e9ec41b25ab1e65768e2ff0" &&
                    <>
                      <label>2<sup>éme</sup> Paragraphe</label>
                      <TextEditor
                        value={career.current && career.current.text2 ? career.current.text2 : "" }
                        onChange={newContent => handleChangeSection( newContent , "text2")}
                      />
                    </>
                  }
                
                  <div className="title">
                    <h2>{company?._id !== "661801566cf939d1592427a3" ? "Pages recrutement" : "Offres"}</h2>
                  </div>

                  {pages && pages.sort((a , b ) => a.pos - b.pos ).map((page , i) => <EditPage
                    key={'pages' + i }
                    pageValue={page}
                    pages={pages}
                    setPages={(array) => {
                      setPages(array);
                      if (isSaved) {
                        setIsSaved(false);
                      }
                    }}
                    companyId={param.companyId}
                    context={context}
                    isFile={true}
                    setRemoveAction={setRemoveAction}
                    openSelectPicture={openSelectPicture}
                    dispatch={dispatch}
                    index={i}
                    />)
                  }

                  <div className="btn-container" >
                    <button
                      className={`btn primary ${theme}-bg`}
                      style={{ marginBottom: 20}}
                      onClick={() => setPages([...pages ,{ title: null , pos: pages.length + 1 , blocs: [], cover: null } ]) }
                      type="button"
                      >
                      Ajouter une page
                    </button>
                  </div>
           

                  {requireRight("talent") && company?._id !== "661801566cf939d1592427a3" &&
                  <>
                    <div className="section"></div>  
                    
                    <div className="title">
                      <h2>Les talents</h2>
                    </div>

                    {param.companyId !== "5e9ec41b25ab1e65768e2ff0" &&
                      <>
                        <label>Paragraphe</label>
                        <TextEditor
                          value={career.current && career.current.textTalent ? career.current.textTalent : "" }
                          onChange={newContent => handleChangeSection( newContent , "textTalent")}
                        />
                      </>
                    }

                    <div className="list-container">

                      {!addTalent && talents && talents.map((talent, i ) => (<Talent
                        key={'talent' + i }
                        talentValue={talent}
                        companyId={param.companyId}
                        lang={lang}
                        context={context}
                        setRemoveAction={setRemoveAction}
                        openSelectTalentVideo={openSelectTalentVideo}
                        openSelectPicture={openSelectPicture}
                        dispatch={dispatch}
                        index={i} />
                      ))}

                    </div>


                    <div className="btn-container" >
                      <button
                        className={`btn primary ${theme}-bg`}
                        style={{ marginBottom: 20}}
                        onClick={() => setTalents([...talents , {}]) }
                        type="button"
                        >
                        Ajouter un talent
                      </button>
                    </div>
                  </>
                }
                  
                </div>

                <div className="col-2">

                  <div className="btn-actions-container" >
                    {publishIsLoading ?
                      null
                      :
                      <button
                        className={`btn primary publish ${theme}-bg`}
                        type="submit"
                        >
                        Publier
                      </button>
                    }
                  </div>

                  <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1132 x 660 px</span></label>
                  {renderCoverImg()}
                  <div className="btn-container">
                    
                    <button
                      type="button"
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectCover()}
                      >
                      Parcourir
                    </button>

                    {career?.current?.picture &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction({ function:() =>  handleChangeSection( null , "picture")}) }
                        >
                        Supprimer
                      </button>
                    }
                  </div>

                  <label>Vidéo à la une</label>
                    {video && renderVideo()}
                    <div className="btn-container">
                      <button
                        type="button"
                        className={`btn primary ${theme}-bg`}
                        onClick={() => openSelectVideo(true)}
                        >
                        Parcourir
                      </button>

                      {career.current.video &&
                        <button
                          type="button"
                          className="btn primary"
                          onClick={() => setRemoveAction({ function:() =>  handleChangeSection( [null] , "video")}) }
                          >
                          Supprimer
                        </button>
                      }
                    </div>
                  </div>
              </div>
              
          </form>
        }
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />    
      {isMounted && career.current && <div id="innovation" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page nous rejoindre ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
