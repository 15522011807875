import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { clearMarkup , useForceUpdate } from '../../../utils/utils';
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
import TextEditor from "../../partials/TextEditor";

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import '../../../styles/pages-sites.scss';

export default function Jobs({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'jobs';

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const forceUpdate = useForceUpdate();

  const [ isUpdated , setIsUpdated ] = useState(false);

  const jobsRef = useRef();
  const jobs = jobsRef?.current;

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("jobs");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      jobsRef.current = null
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    jobsRef.current = null
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      jobsRef.current = {...context.theme.page };
      setIsMounted(true);
    } else {
      jobsRef.current = null
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  useEffect(() => {
    if (JSON.stringify(jobs) === JSON.stringify(context.theme.page)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[jobs , context.theme.page])

  

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function submitMedia(value) {

    let updatedJobs = Object.assign({} , jobsRef.current );

    switch (mediaKey) {

      case "international":
        updatedJobs.international.picture = value[0];
        break;

      case "missions":
        updatedJobs.missions.picture = value[0]; 
        break;

      case "approach":
        updatedJobs.approach.picture = value[0];
        break;

      case "cover":
        updatedJobs.cover = value[0];
        break;

      case "video":
        updatedJobs.video = value[0];
        forceUpdate();
        break;
    
      default:
        break;
    }
    jobsRef.current = {...updatedJobs};
    setIsUpdated(!isUpdated);
  };

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('cover');
    setMediaType('image');
    if (jobs && jobs.cover) {
      var pictureArray = [];
      pictureArray.push(jobs.cover);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectVideo() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('video');
    setMediaType('video');
    handleChange( null ,'video')
    if (jobs.video) {
      var coverArray = []
      coverArray.push(jobs.video);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (jobs && jobs[imgkey] && jobs[imgkey].picture && jobs[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(jobs[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function renderImgLarge(key) {
    var imgFound;
    if(jobs && jobs[key] && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === jobs[key] || item._id === jobs[key].picture || item._id === jobs[key].cover)
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt={jobs[key]} className="img-large" />
      } else if (jobs[key].picture && jobs[key].picture.path) {
        return <img src={API_URL + '/' + jobs[key].picture.path} alt={jobs[key].alt} className="img-large"/>
      }
    }
  }

  function handleChange( value , obj) {
    var data = Object.assign({} , jobsRef.current );
    if (data[obj]) {
      data[obj] = value
    }
    jobsRef.current = data
    setIsUpdated(!isUpdated);
  };

  function handleChangeKey( value , obj , key ) {
    setIsSaved(false);
    var data = Object.assign({} , jobsRef.current );
    data[obj][key] = value
    jobsRef.current = data
  };

  const submitjobs = (values)  => {
    var jobsData = {...values, ...jobsRef.current};
    var obj = {};
    for (const [key, value] of Object.entries(jobsData)) {
        obj[`${page}.${key}`] = value;
    }

    setPublishIsLoading(true);

    updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );

    
    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(jobs && jobs.cover && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === jobs.cover )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="jobs" />
      } else if (jobs.cover && jobs.cover.path) {
        return <img src={API_URL + '/' + jobs.cover.path} alt="jobs" />
      }
    }
  }

  function renderVideo() {
    var videoFound;
    if(jobs && jobs.video && context.medias.mediasList) {
      videoFound = context.medias.mediasList.find(item => item._id === jobs.video );
      if (videoFound) {
        return (
          <video controls="controls">
            <source id="video" src={API_URL +'/'+ videoFound.path}  />
          </video>
        )
      }
      
      if (jobs.video.path) {
        return (
          <video  controls="controls">
            <source id="video2" src={API_URL +'/'+ jobs.video.path } />
          </video>
        )
      }
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitjobs)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  <h1 dangerouslySetInnerHTML={{ __html: jobs && jobs.title ? clearMarkup(jobs.title) : "" }} ></h1>
                </div>

                <label htmlFor="title">Titre</label>
                <input
                  name="title"
                  onChange={(e) => handleChange(e.target.value , 'title')}
                  defaultValue={jobs && jobs && jobs.title ? jobs.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <div>
                  <input id="isWhite"
                    onChange={(e) => handleChange(!e.target.checked , 'titleIsWhite')}
                    type="checkbox" className="switch" defaultChecked={!jobs.titleIsWhite}
                    />
                  <label htmlFor="isWhite">{jobs.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Missions</h2>
                </div>
                
                <label htmlFor="missions.title">Titre</label>
                <input
                  name="missions.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'missions' , 'title')}
                  defaultValue={jobs && jobs.missions && jobs.missions.title ? jobs.missions.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={jobs && jobs.missions && jobs.missions.text  }
                  onChange={(value) => handleChangeKey(value , 'missions' , 'text')}
                />

                <label htmlFor="missions.button">Bouton</label>
                <input
                  name="missions.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'missions' , 'button')}
                  defaultValue={jobs && jobs.missions && jobs.missions.button ? jobs.missions.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                {renderImgLarge('missions')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("missions")}
                    >
                    Parcourir
                  </button>

                  {jobs && jobs.missions && jobs.missions.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'missions' , 'picture')  } )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Approche globale</h2>
                </div>

                <label htmlFor="approach.title">Titre</label>
                <input
                  name="approach.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'approach' , 'title')}
                  defaultValue={jobs && jobs.approach && jobs.approach.title ? jobs.approach.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={jobs && jobs.approach && jobs.approach.text }
                  onChange={(value) => handleChangeKey( value , 'approach' , 'text')}
                />

                <label htmlFor="approach.button">Bouton</label>
                <input
                  name="approach.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'approach' , 'button')}
                  defaultValue={jobs && jobs.approach && jobs.approach.button ? jobs.approach.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                {renderImgLarge('approach')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("approach")}
                    >
                    Parcourir
                  </button>

                  {jobs && jobs.approach && jobs.approach.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'approach' , 'picture') } )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>International</h2>
                </div>
 
                <label htmlFor="international.title">Titre</label>
                <input
                  name="international.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'international' , 'title')}
                  defaultValue={jobs && jobs.international && jobs.international.title ? jobs.international.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={jobs && jobs.international && jobs.international.text  }
                  onChange={(value) => handleChangeKey(value , 'international' , 'text')}
                />

                <label htmlFor="international.button">Bouton</label>
                <input
                  name="international.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'international' , 'button')}
                  defaultValue={jobs && jobs.international && jobs.international.button ? jobs.international.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                {renderImgLarge('international')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("international")}
                    >
                    Parcourir
                  </button>

                  {jobs && jobs.international && jobs.international.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'international' , 'picture') } )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

               
              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Vidéo à la une <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px</span></label>
                {renderVideo()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectVideo() }
                    >
                    Parcourir
                  </button>

                  {jobs && jobs.video &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange(null , 'video')}
                      >
                      Supprimer
                    </button>
                  }
                </div>


                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {jobs && jobs.cover &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange(null , 'cover')}
                      >
                      Supprimer
                    </button>
                  }
                </div>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />
      {isMounted && jobs && <div id="missions" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page nos métiers ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/>
    </>

  )
}
