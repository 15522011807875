import { getData , postData , putData ,  deleteData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_ARTICLES,
  CREATE_ARTICLE,
  POST_ARTICLE,
  GET_ARTICLE,
  DELETE_ARTICLE,
  UPDATE_ARTICLE,
  ERROR_ARTICLES,
} from "./types"

export async function getArticlesAction(dispatch , params ) {
  let url = "/article/lang/" + params.lang;
  var articles;

  await getData(ERROR_ARTICLES, url, dispatch , true ).then((response)=>{
    articles = response.articles.filter(article => article.companies.find(company => company === params.companyId))
  });

  dispatch({
    type : GET_ARTICLES,
    payload : sortByUpdateDate(articles)
  });

};

export async function createArticleAction(dispatch , params ) {
  let url = "/article"
  var article;

  await postData(CREATE_ARTICLE , ERROR_ARTICLES , url, dispatch , params, true ).then((response)=>{
    article = response
  });

  dispatch({
    type : POST_ARTICLE,
    payload : article
  });

}

export async function getArticleAction(dispatch , id  , companyId ) {
  let url = `/article/${id}/${companyId}`
  var article;

  await getData(ERROR_ARTICLES, url, dispatch , true ).then((response)=>{
    article = response.article
  });

  dispatch({
    type : GET_ARTICLE,
    payload : article
  });

}

export async function updateArticleAction(dispatch , param ) {
  let url = "/article/" + param._id
  var article;

  await putData(UPDATE_ARTICLE, ERROR_ARTICLES ,  url, dispatch, param , true ).then((response)=>{
    article = response
  });

  dispatch({
    type : UPDATE_ARTICLE,
    payload : article
  });

}

export async function deleteArticleAction(dispatch , id ) {
  let url = "/article/" + id

  await deleteData(ERROR_ARTICLES, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "L'article a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_ARTICLE,
    payload : id
  });

}