import React , { useState , useEffect } from 'react';

import { getMediasAction } from '../../../actions/mediasActions';
import Modale from '../../partials/Modale';
import MediaCard from '../medias/MediaCard';

import './medias.scss';

export default function SelectMedia(props) {
  const { displaySelectMedia , setDisplaySelectMedia , context , dispatch , isMulti, companyId , mediaType, selectedMediaList , handleChange } = props;

  const [ mediaList , setMediaList ] = useState();
  const [ search , setSearch ] = useState();

  const [ selectedList , setSelectedList ] = useState([]);

  useEffect(() => {
    getMediasAction(dispatch);
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (context.medias.mediasList && companyId) {
      setMediaList(context.medias.mediasList.filter(item => item.company === companyId ))
    }
  }, [context.medias.mediasList , companyId]);

  useEffect(() => {
    if (selectedMediaList) {
      setSelectedList(selectedMediaList);
    }
  }, [selectedMediaList])

  useEffect(() => {
    if (context.medias && context.medias.mediasList && companyId) {
      if (search) {
        var newList = context.medias.mediasList.filter(item => item.company === companyId ).filter(media => media.title.toLowerCase().includes(search.toLowerCase())  || media.description.toLowerCase().includes(search.toLowerCase()) );
        setMediaList(newList)
      } else {
        setMediaList(context.medias.mediasList.filter(item => item.company === companyId ))
      }
    }
    // eslint-disable-next-line
  }, [search , context.medias.mediasList , companyId ]);

  function selectMedias(media) {
    var list = [...selectedList]
    var found = selectedList.find(item => item === media._id);
    if (found) {
      list = list.filter(item => item !== media._id);
    } else {
      list.push(media._id);
    }
    if (!isMulti) {
      list = [media._id]
    }
    setSelectedList(list);
  };

  function validImg() {
    setDisplaySelectMedia(false);
    handleChange(selectedList)
  }

  function renderMediaList() {
    if(mediaList) {
      var allMedias = mediaList
      if (mediaType === 'video') {
        allMedias = mediaList.filter(media => media.extension === "mp4")
      }
      if (mediaType === 'image') {
        allMedias = mediaList.filter(media => media.extension === "jpeg" || media.extension === "png" || media.extension === "svg" || media.extension === "svg+xml" ).filter(item => !item.isThumbnail);
      }
      if (mediaType === 'file') {
        allMedias = mediaList.filter(media => media.extension === "pdf" || media.extension === "xls" || media.extension === "xlsx"  )
      }
      if (mediaType === 'school') {
        allMedias = mediaList.filter(media => media?.type === "school" )
      }
      return (
        <>
          {allMedias.filter( m => selectedList?.find(s => s === m._id ) ).map((media , i ) => (
            <div
              className="select-card"
              key={i}
              onClick={() => selectMedias(media)}
              >
              <MediaCard key={i} media={media} isOnModale={true} selectedList={selectedList} dispatch={dispatch}/>
            </div>
          ))}
          {allMedias.filter( m => !selectedList?.find(s => s === m._id ) ).map((media , i ) => (
            <div
              className="select-card"
              key={i}
              onClick={() => selectMedias(media)}
              >
              <MediaCard key={i} media={media} isOnModale={true} selectedList={selectedList} dispatch={dispatch}/>
            </div>
          ))}
        </>
      ) 
    }
  }

  return (
    <Modale
      modaleIsOpen={displaySelectMedia}
      modaleToggle={setDisplaySelectMedia}
      className={"select-media-modale"}
      >
      <>
        <input
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Recherche"
          />

        <div className="media-list select">
          {renderMediaList()}
        </div>
        
        <div className="btn-container">
          <button
            type={'button'}
            onClick={() => validImg()}
            className={`btn success`}
            >
            Valider
          </button>
        </div> 

      </>
    </Modale>
  )
}
