import React, { useState , useEffect } from 'react';

import edit from '../../../../assets/images/icons/edit.svg';
import remove from '../../../../assets/images/icons/remove.svg';
import close from '../../../../assets/images/icons/close.svg';
import save from '../../../../assets/images/icons/save.svg';

import styles from './Contact.module.scss';

export default function Contact({
  propContact,
  setRemoveAction ,
  index,
  addContact,
  handleChangeContact,
  deleteContact
}) {
  const [ contact , setContact] = useState(propContact|| {});
  const [ isEdit , setIsEdit ] = useState(addContact ? true : false);

  function handleChange(obj) {
    setContact({...contact, ...obj })
  }

  function saveContact(bool) {  
    if (index >= 0) {
      handleChangeContact(contact , index);
    }
    setIsEdit(bool);
  }

  useEffect(() => {
    setContact({...propContact});
    if (!propContact) {
      setIsEdit(true)
    }
    // eslint-disable-next-line
  }, [propContact]);

  function renderEdit() {
    return (
      <>
        <div className="btn-close-row">
          <button
            type="button"
            style={{ width: 30 }}
            onClick={() => saveContact(true)}
            >
            <img src={save} alt="edit" />
          </button>
          <button
            type="button"
            onClick={() => saveContact(false)}
            >
            <img src={close} alt="edit" />
          </button>
        </div>
        <label htmlFor={`name${index}`} >Nom du contact</label>
        <input
          name={`name${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ name: e.target.value })}
          defaultValue={contact.name ? contact.name : ""}
          />

        <label htmlFor={`function${index}`} >Fonction</label>
        <input
          name={`function${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ function: e.target.value })}
          defaultValue={contact.function ? contact.function : ""}
          />

        <label htmlFor={`email${index}`} >Email</label>
        <input
          name={`email${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ email: e.target.value })}
          defaultValue={contact.email ? contact.email : ""}
          />

        <label htmlFor={`address${index}`} >Adresse</label>
        <input
          name={`address${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ address: e.target.value })}
          defaultValue={contact.address ? contact.address : ""}
          />

        <label htmlFor={`phone${index}`} >Téléphone</label>
        <input
          name={`phone${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ phone: e.target.value })}
          defaultValue={contact.phone ? contact.phone : ""}
          />    

      </>
    )
  }

  function renderRow() {
    return (
      <>
      <div className={styles.name}>
        <p className="bold">{contact?.name}</p>
      </div>
      <div className="actions">

        <button
          type="button"
          onClick={() => setIsEdit(true)}
          >
          <img src={edit} alt="edit" />
        </button>
        {setRemoveAction &&
          <button
            type="button"
            onClick={() => setRemoveAction({ function: () => deleteContact(index)} )}>
            <img src={remove} alt="remove"/>
          </button>
        }

      </div>
      </>
    )
  }

  return (
    <div id={`Contact${index}`} className={`${styles.contact} ${!isEdit? styles.row : styles.edit }`}>
      {isEdit ? renderEdit() : renderRow()}
    </div>
  )
}
