import React, { useEffect , useRef , useState } from 'react';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { companiesColors } from '../../../utils/utils';
import markerIcon from '../../../assets/images/icons/marker.svg';


export default function MapEditor({ theme , handleChange , project , lang }) {

  const [ center , setCenter ] = useState([ 46.692514, 2.5817875]);
  const [ zoom , setZoom ] = useState(5);

  var map = useRef(null);
  var editableLayers = new L.FeatureGroup();

  useEffect(() => {
    let initCenter = center
    // Create the map
    if (project.location && project.location.geojson) {
      var shapeCenter = L.geoJSON(project.location.geojson).getBounds().getCenter();
      initCenter = [ shapeCenter.lat, shapeCenter.lng ]
      setCenter([ shapeCenter.lat, shapeCenter.lng ]);
    }

    if (project.location && project.location.zoom) setZoom(project.location.zoom)

    map.current = L.map('map').setView(initCenter , project.location && project.location.zoom ? project.location.zoom : zoom );

    let tiles = {
      "fr" : "https://osm.naomis.fr/fr/{z}/{x}/{y}.png",
      "en" : "https://osm.naomis.fr/en/{z}/{x}/{y}.png",
      "es" : "https://es.osm.naomis.fr/osm/{z}/{x}/{y}.png"
    }
    let urlTile = tiles[lang]
    // Set up the OSM layer
    console.log(urlTile)
    L.tileLayer(
      urlTile, {
        maxZoom: 18
      })
      .addTo(map.current);
    // add a marker in the given location
    // L.marker(center).addTo(map.current);

    // Initialise the FeatureGroup to store editable layers
    map.current.addLayer(editableLayers);

    // define custom marker

    var customMarker = L.Icon.extend({
      options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 12),
        iconSize: new L.Point(24, 24),
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon
      }
    });

    var drawPluginOptions = {
      position: 'topright',
      draw: {
        polyline: {
          shapeOptions: {
            color: companiesColors[theme],
            weight: 10
          }
        },
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: companiesColors[theme], // Color the shape will turn when intersects
            message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
          },
          shapeOptions: {
            color: companiesColors[theme]
          }
        },
        circlemarker: false,
        circle: {
          shapeOptions: {
            color: companiesColors[theme]
          }
        }, // Turns off this drawing tool
        rectangle: {
          shapeOptions: {
            color: companiesColors[theme]
          }
        },
        marker: {
          icon: new customMarker()
        }
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        edit: false,
        remove: true
      }
    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    var drawControl = new L.Control.Draw(drawPluginOptions);
    map.current.addControl(drawControl);

// eslint-disable-next-line
}, []);


  useEffect(() => {
      
    map.current.on("zoomend", function (e) {
      setZoom(map.current.getZoom());
    });

    map.current.on("draw:deleted", function (e) {
      resetMap();
    });

    map.current.on('draw:created', function(e) {

      var layer = e.layer;

      editableLayers.clearLayers();
      editableLayers.addLayer(layer);

      var geoData = layer.toGeoJSON();

      var geoDataCenter = L.geoJSON(geoData).getBounds().getCenter();
      setCenter([ geoDataCenter.lat, geoDataCenter.lng ]);


      if (layer instanceof L.Circle) {
        geoData.properties.radius = layer.getRadius();
      }
      
      if (geoData) {
        handleChange("geojson" , geoData);
        handleChange("zoom", map.current.getZoom());
      }


    });

  // eslint-disable-next-line
  },[map]);

  useEffect(() => {

    if (project.location && project.location.geojson) {
      editableLayers.clearLayers();
      var shapeCenter = L.geoJSON(project.location.geojson).getBounds().getCenter();
      setCenter([shapeCenter.lat , shapeCenter.lng])
      map.current.setView(new L.LatLng(shapeCenter.lat, shapeCenter.lng));
      if (project.location.zoom) {
        map.current.setZoom(project.location.zoom)
      }
      if (project.location.geojson.geometry && project.location.geojson.geometry.type === "Point") {
        var marker = L.Icon.extend({
          options: {
            shadowUrl: null,
            iconAnchor: new L.Point(12, 12),
            iconSize: new L.Point(24, 24),
            iconUrl: markerIcon,
            iconRetinaUrl: markerIcon
          }
        });
        if (project.location.geojson.properties && project.location.geojson.properties.radius) {
          L.circle([shapeCenter.lat , shapeCenter.lng], project.location.geojson.properties.radius , { color: companiesColors[theme] } ).addTo(editableLayers);
        } else {
          L.marker([shapeCenter.lat , shapeCenter.lng], {icon: new marker() } ).addTo(editableLayers);
        }
      } else {
        L.geoJSON(project.location.geojson , { color: companiesColors[theme] }).addTo(editableLayers);
      }
    } else {
      editableLayers.clearLayers();
    }
    // eslint-disable-next-line
  },[project]);

  function resetMap() {
    handleChange("geojson" , null );
    handleChange("zoom", null );
  }

  function subimtPostion() {
    var initCenter = center;
    setZoom(17);
    map.current.setView(initCenter).setZoom(11);
  }

  return (
    <>
      <div id="map">
        
      </div>
      <div className="inputs-map">

        <div className="input">
          <label>Latitude</label>
          <input type="number" value={center[0]} onChange={(e) => setCenter([e.target.value , center[1]])}  />
        </div>

        <div className="input">
          <label>Longitude</label>
          <input type="number" value={center[1]} onChange={(e) => setCenter([ center[0] , e.target.value ])} />
        </div>

        <div className="input">
          <button className="btn primary" style={{ marginTop : 31 , width: '100%' }} onClick={() => subimtPostion()}>rechercher la position</button>
        </div>

      </div>
      {/* <div className="inputs-map">
        <div className="input">
          <button className="btn primary" onClick={() => resetMap()}>Supprimer la localisation</button>
        </div>
      </div> */}
    </>
  )
}
