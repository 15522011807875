import {
  GET_SUB_DOMAINS,
  GET_SUB_DOMAIN,
  MESSAGE_SUB_DOMAINS,
  UPDATE_SUB_DOMAIN,
  POST_SUB_DOMAIN,
  ERROR_SUB_DOMAINS,
  DELETE_SUB_DOMAIN
} from "../actions/types";

import { sortByUpdateDate } from './../utils/utils';

const initialState = {
  subdomainsList: [],
  subdomain: null,
  error: null,
  message: null
};

export default function subDomainsReducer(state = initialState, action) {

  var subdomainsList = state.subdomainsList;

  switch (action.type) {

    case GET_SUB_DOMAINS:
      return {...state , subdomainsList: sortByUpdateDate(action.payload)}
    
    case GET_SUB_DOMAIN:
      return {...state , subdomain: action.payload}

    case POST_SUB_DOMAIN:

      var list = [...subdomainsList];
      list.push(action.payload.subdomain);

      return {...state ,
        subdomainsList: sortByUpdateDate(list),
        subdomain: action.payload.subdomain,
        message: action.payload.message
      };

    case UPDATE_SUB_DOMAIN:
    
      if (subdomainsList) {
        var foundIndex = subdomainsList.indexOf(subdomainsList.find( subdomain => subdomain._id === action.payload.subdomain._id));
        if (foundIndex) {
          subdomainsList[foundIndex] = action.payload.subdomain;
        }
      }


      return {
        ...state,
        subdomainsList: sortByUpdateDate(subdomainsList),
        subdomain: action.payload.subdomain,
        message: action.payload.message
      }
    
    case DELETE_SUB_DOMAIN:
      subdomainsList = subdomainsList.filter( subdomain => subdomain._id !== action.payload)
      return {...state , subdomainsList: subdomainsList }

    case MESSAGE_SUB_DOMAINS:
      return {...state , message: action.payload }

    case ERROR_SUB_DOMAINS:
      return {...state , error: action.payload }

    default:
      return {...state};
  }
}