import React, { useState , useEffect , useCallback , useRef } from 'react';
import { useParams , Link } from "react-router-dom";
import DatePicker , { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; 

import { useGlobalContext } from '../../context/GlobalContext';
import { companiesColors } from '../../../utils/utils';

import SelectMedia from '../medias/SelectMedia';
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 

import { API_URL } from '../../../actions/index';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getArticleAction , updateArticleAction } from '../../../actions/articlesActions';
import { getTagsAction , createTagAction } from '../../../actions/contactsActions';

import './editArticle.scss';
import TextEditor from '../../partials/TextEditor';
import Select from 'react-select';

export default function EditArticle(props) {

  const [ context, dispatch ] = useGlobalContext();

  const param = useParams();

  const { setDisplayNavBar, setActivePage , theme , lang } = props;

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 44,
      padding: "0px 5px"
    }),
    input: (provided, state) => ({
      ...provided,
      height: 36,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  // const [ article , setArticle ] = useState();
  const article = useRef(null);

  const [ isSaved , setIsSaved ] = useState(false);
  const [ isUpdated , setIsUpdated ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();
  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaKey , setMediaKey ] = useState('');
  const [ selectedTags , setSelectedTags ] = useState([]);
  const [ companies , setCompanies ] = useState();

  const tagsOptions = context.contacts.tags.map(item => ({ value: item._id , label: item.value }));

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getTagsAction(dispatch , lang , 'article' );
  },[dispatch , param.companyId , lang ]);

  useEffect(() => {
    registerLocale("fr", fr);
    setDisplayNavBar(true);
    setActivePage("articles");
    getCompany();
    if (!context.articles.article) {
      getArticleAction(dispatch , param.articleId , param.companyId );
    } else {
      article.current = context.articles.article
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (context.articles.article) {
      article.current = {...context.articles.article}
      if (context.articles.article.companies) setCompanies(context.articles.article.companies)
      if (context.articles.article.tags) {
        setSelectedTags(tagsOptions.filter(item => {
          if(context.articles.article.tags.find(id => id === item.value )) {
            return item; 
          }
          return false;
        }));
      }
  
    }
    // eslint-disable-next-line
  }, [context.articles.article]);

  useEffect(() => {
    if (article.current && JSON.stringify(article.current) === JSON.stringify(context.articles.article)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[article , context.articles.article])

  useEffect(() => {
    if (context.articles.message === "Article successfully updated!") {

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'article a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_ARTICLES',
        payload: null
      });

    }
  },[context.articles.message , dispatch ]);

  function handleChange(key , value ) {
    var updatedArticle = {...article.current};
    switch (key) {
      case 'publicationDate' :
        updatedArticle.publicationDate = value
        break;
      case 'url':
        updatedArticle.url = value;
        break;
      case 'title':
        updatedArticle.title = value;
        break;
      case 'text':
        updatedArticle.text = value;
        break;
      case 'companies':
        var companiesList = [...updatedArticle.companies] ;
        console.log(value)
        var foundCompany = companiesList.find(item => item._id === value._id );
        if (foundCompany) {
          companiesList = companiesList.filter(item => item._id !== value._id )
        } else {
          companiesList.push(value)
        }
        if (companiesList.length > 0) updatedArticle.companies = companiesList;
        setCompanies(companiesList);
        break;
      case 'cover':
        updatedArticle.cover = value[0]
        break;
      case 'pictures':
        updatedArticle.pictures = value
        break;
      default:
        updatedArticle = {...article.current}
    }
    setIsUpdated(!isUpdated);
    return article.current = updatedArticle;
  }

  function handleChangeTags(newValue , actionMeta) {
    if (actionMeta.action === "create-option") {
      createTagAction(dispatch, { 
        lang: lang,
        type: "article",
        value: newValue.find(item => item.__isNew__ ).value
      })
    }
    if (actionMeta.action === "select-option") {
      setSelectedTags(newValue)
    }
    if (actionMeta.action === "clear") {
      setSelectedTags([])
    }
    if (actionMeta.action === "remove-value") {
      setSelectedTags(newValue)
    }
    article.current.tags = newValue ? newValue.map(item => item.value) : [] ;
  };

  function saveArticle(status) {
    var savedArticle = {...article.current}
    savedArticle.status = status
    updateArticleAction(dispatch , savedArticle )
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('cover')
    if (article.current.cover) {
      var coverArray = []
      coverArray.push(article.current.cover);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectIllus() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(true);
    setMediaKey('pictures')
    if (article.current && article.current.pictures && article.current.pictures.length) {
      setSelectedMediaList(article.current.pictures)
    }
  }

  function handleChangeImg( key , value) {
    handleChange( key , value)
  };

  function renderTitle() {
    if (article.current && article.current.type === "linkedin") {
      return <h1>Edition d'un article linkedin ({lang})</h1>
    } else if (article.current && article.current.type === "youtube") {
      return <h1>Edition d'un article youtube ({lang})</h1>
    } else {
      return <h1>Edition d'un article ({lang})</h1>
    }
  }

  function renderSelectCompanies() {
    if(context.companies.companiesList) {
      return context.companies.companiesList.map((company, i ) => {
        var found = companies.find(item => item === company._id || item._id === company._id );
        return (
          <button
            key={i}
            onClick={() => handleChange('companies' , company )}
            className={`icon ${found ? company.className+"-bg" : "notActive"}`}>
            <span>{company.icon}</span>
          </button>
        )
      }) 
    }
  }

  function renderCoverImg() {
    if(article.current.cover && context.medias.mediasList) {
      var imgFound = context.medias.mediasList.find(item => item._id === article.current.cover._id || item._id === article.current.cover )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover"/>
    }
  }

  function renderIllusImg() {
    if(article.current.pictures && article.current.pictures.length && context.medias.mediasList) {
      return article.current.pictures.map((illu , i )=> {
        var imgFound = context.medias.mediasList.find(item => item._id === illu._id || item._id === illu )
        if (imgFound) return <img key={"illu" + i} src={API_URL + '/' +imgFound.path} alt="illu"/>
        return null;
      });
    }
  }


  return (
    <>
      <PromptRouter when={!isSaved} />
      <div className={`page-container ${theme}`}>
        {article.current ?
          <div className="page-content">

            <div className="title-row space-between">

              <div className="flex">
                <Link to={`/articles-list/${param.companyId}/${lang}`}>
                  <button
                    className="btn primary back">
                    Retour
                  </button>
                </Link>

                <div className="title">
                  {renderTitle()}
                  <div className="bar primary"></div>
                </div>
              </div>

              <div>
                <button
                  onClick={() => saveArticle("draft")}
                  style={{ marginRight: 20 }}
                  className="btn primary">
                    Enregistrer le brouillon
                </button>
                
                <button
                  onClick={() => saveArticle("published")}
                  className="btn success">
                    Publier
                </button>
              </div>

            </div>


            <div className="edit-container">

              <div className="col-content">

                <label className="m-10" htmlFor="title">Titre</label>
                <input
                  defaultValue={article.current.title ? article.current.title : ""}
                  onChange={(e) => handleChange('title' , e.target.value)}
                  name="title"
                />

                {article.current.type === "article"
                  ? <>
                    <label className="m-10" htmlFor="editor">Paragraphe</label>
                    <TextEditor
                      value={article.current.text}
                      onChange={newContent => handleChange('text' , newContent)} 
                    />
                  </>
                  : <>
                    <label className="m-10" htmlFor="title">Url</label>
                    <input
                      defaultValue={article.current.url ? article.current.url : ""}
                      onChange={(e) => handleChange('url' , e.target.value)}
                      name="url"
                    />
                  </>
                }

              </div>

              <div className="col-aside">

              <label htmlFor="calendar">Date de publication</label>
                <div className="select-dates">
                  <DatePicker
                    selected={article.current.publicationDate && new Date(article.current.publicationDate)}
                    onChange={date => handleChange('publicationDate' , date )}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <label htmlFor="companies">Entreprises concernées</label>
                <div className="select-companies" name="companies">
                  {renderSelectCompanies()}
                </div>

                <label>Photo de couverture <br/><span className="note">minimum 1000 x 583 px</span></label>
                <div className="select-media">
                  {renderCoverImg()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {article.current.cover &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('cover' , [null])})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                {article.current.type === "article" &&
                  <>
                    <label>Photos d'illustration <br/><span className="note">minimum 1000 x 583px </span></label>
                    <div className="select-media">
                      <div className="illus-list">
                        {article.current.pictures && renderIllusImg()}
                      </div>
                      <button className="btn primary"
                        style={{ marginRight: 20}}
                        onClick={() => openSelectIllus(true)}
                        >
                        Parcourir
                      </button>

                      {article.current.pictures.length > 0 &&
                        <button className="btn primary"
                          onClick={() => setRemoveAction({ function: () => handleChange('pictures' , [])})}
                          >
                          Supprimer
                        </button>
                      }
                    </div>
                  </>
                }  
                <label htmlFor="tags">Tags</label>
                <Select
                  styles={customStyles}
                  value={selectedTags}
                  options={tagsOptions}
                  placeholder={'Sélectionner'}
                  onChange={handleChangeTags}
                  isMulti
                />
              </div>

            </div>

          </div>
        :
          null
        }
      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
      <SelectMedia
        displaySelectMedia={displaySelectMedia}
        dispatch={dispatch}
        theme={theme}
        context={context}
        mediaType={'image'}
        selectedMediaList={selectedMediaList}
        companyId={param.companyId}
        isMulti={mediaIsMulti}
        handleChange={(value) => handleChangeImg( mediaKey , value)}
        setDisplaySelectMedia={setDisplaySelectMedia}
        />
    </>
  )
}
