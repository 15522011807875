import React from 'react';

import { GlobalProvider } from './components/context/GlobalContext';

import authReducer from './reducers/authReducer';
import companiesReducer from './reducers/companiesReducer';
import articlesReducer from './reducers/articlesReducer';
import domainsReducer from './reducers/domainsReducer';
import subDomainsReducer from './reducers/subDomainsReducer';
import mediasReducer from './reducers/mediasReducer';
import usersReducer from './reducers/usersReducer';
import missionsReducer from './reducers/missionsReducer';
import agenciesReducer from './reducers/agenciesReducer';
import contactsReducer from './reducers/contactsReducer';
import talentsReducer from './reducers/talentsReducer';

import Router from './components/Router';

import './styles/index.scss';
import projectsReducer from './reducers/projectsReducer';
import themeReducer from './reducers/themeReducer';

export default function App() {

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    companies: companiesReducer,
    articles: articlesReducer,
    domains: domainsReducer,
    subdomains: subDomainsReducer,
    medias: mediasReducer,
    users: usersReducer,
    projects: projectsReducer,
    missions: missionsReducer,
    agencies : agenciesReducer,
    contacts: contactsReducer,
    theme: themeReducer,
    talents: talentsReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
        reducers={reducers}
        initialState={initialState}
        >
      <Router/>
    </GlobalProvider>
  );
};
