import { useState, useCallback } from 'react';

export const companiesColors  = {
  K: '#8685BB',
  SCE: '#EB690B',
  C: '#3A6DB0',
  UP: '#EB690B',
  GH : '#0BB0AB',
  N: '#CB333B',
  S3D :'#52AA4E',
  CI :'#0F4D82',
}

export function sortByUpdateDate(array) {
  return array.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
}

export function sortBy(array , key ) {
  return array.sort((a,b) => new Date(b[key]) - new Date(a[key]));
}

export function clearMarkup(str) {
  return str.replace(/(<([^>]+)>)/gi, "")
}

export function formatPhone(num) {
  return num.toString().trim().replace(/(\d)(?=(\d{2})+(?!\d))/g, '$1.')
}

// export const joditConfig = {
//   minHeight: 100,
//   readonly: false, // all options from https://xdsoft.net/jodit/doc/
//   placeholder: '',
//   style: {
//     fontSize: '14px',
//   },
//   uploader: {
// 		insertImageAsBase64URI: true
// 	},
//   enter: "BR",
//   allowResizeY: true,
//   askBeforePasteHTML: false,
//   defaultActionOnPaste: "insert_only_text",
//   toolbarAdaptive: false,
//   showCharsCounter: false,
//   showWordsCounter: false,
//   showXPathInStatusbar: false,
//   buttons: ["bold","strikethrough","underline", "italic", "superscript", "subscript", "ul", "ol","image", "video", "link" ,"align","cut", "copy" , "source"]
// }
export const editorConfig = {
	readonly: false,
  placeholder: '',
	toolbar: true,
	spellcheck: true,
	language: 'fr',
  style: {
    fontSize: '14px',
  },
	toolbarButtonSize: 'medium',
	// toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
  buttons: ["bold","strikethrough","underline", "italic", "superscript", "subscript", "ul", "ol","image", "video", "link" ,"align","cut", "copy" , "source"],
	//defaultActionOnPaste: "insert_clear_html",
	// buttons: buttons,
	// uploader: {
	// 	insertImageAsBase64URI: true
	// },
	width: 800,
	minHeight: 200,
};

export const initialContent = `<p><p/>
`;


export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export const langsOptions = [
  {
    label: "Anglais",
    value: "en",
  },
  {
    label: "Espagnol",
    value: "es",
  },
  {
    label: "Français",
    value: "fr",
  },
]