import React , { useState , useEffect } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { API_URL } from '../../../actions';
import { putCompanyAction } from '../../../actions/companiesActions';
import SelectMedia from '../medias/SelectMedia';

export default function EditCompany({ company , theme , setRemoveAction , companyId , lang , isPublished }) {
  const [ context, dispatch ] = useGlobalContext();

  const [ editCompany , setEditCompany ] = useState(company);
  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);

  useEffect(() => {
    if (isPublished) {
      let data = {
        picture: editCompany.picture,
        description: {...editCompany.description}
      }
      putCompanyAction(dispatch , editCompany._id , data )
    }
    // eslint-disable-next-line
  }, [isPublished]);

  function HandleChangeText(value) {
    let description = editCompany.description
    description[lang] = value;
    setEditCompany({...editCompany, description: description })
  }

  function renderImgLarge() {
    var imgFound;
    if(editCompany && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === editCompany.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="illu-company" className="img-large" />
      } 
    }
  }

  return (
    <>
    <div style={{ marginBottom : 40 }}>
      <h2>{company.name}</h2>
        <label htmlFor="text">Texte d'introduction</label>
        <textarea
          style={{ marginBottom : 20 }}
          name="text"
          onChange={(e) => HandleChangeText(e.target.value)}
          value={editCompany && editCompany.description && editCompany.description[lang] ? editCompany.description[lang]   : undefined }
        />
        <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px , 72 dpi</span></label>
          {renderImgLarge()}
          <div
            style={{ marginBottom: 40 }} 
            className="btn-container">
            
            <button
              type="button"
              style={{ marginRight: 20 }}
              className={`btn primary ${theme}-bg`}
              onClick={() => setDisplaySelectMedia(true)}
              >
              Parcourir
            </button>

            {editCompany && editCompany.picture &&
              <button
                type="button" 
                className={`btn primary ${theme}-bg`}
                onClick={() => setRemoveAction({function: () => setEditCompany({...editCompany, picture: null })})}
                >
                Supprimer
              </button>
            }
          </div>

    </div>
      <SelectMedia
        displaySelectMedia={displaySelectMedia}
        dispatch={dispatch}
        theme={theme}
        context={context}
        mediaType={'image'}
        selectedMediaList={editCompany.picture ? [editCompany.picture] : []}
        companyId={companyId}
        isMulti={false}
        handleChange={(value) => setEditCompany({...editCompany, picture: value[0] })}
        setDisplaySelectMedia={setDisplaySelectMedia}
      />
   </>
  )
}
