import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

import { useGlobalContext } from '../../context/GlobalContext';

import { API_URL } from '../../../actions/';
import { deleteDomainAction } from '../../../actions/domainsActions';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';

export default function DomainRow({ domain , companyId , theme , setRemoveAction }) {

  const [ context, dispatch ] = useGlobalContext();

  function renderIconImg() {
    var imgFound;
    if(domain && domain.icon && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === domain.icon )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" className="icon"/>
    }
  }

  function renderUpdatedLabel() {
    var updatedDate = new Date(domain.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }
  
  function renderRow() {
      return (
        <>

          <div className="title bold">
            {domain && renderUpdatedLabel()}
            {renderIconImg()}
            {domain?.position && `${domain.position} - `}
            {domain && domain.title}
          </div>

          <div className="date">
            {domain && moment(domain.updatedAt).format('DD/MM/YYYY')}
          </div>

          <div className="position bold">
            
          </div>

          <div className="domains">
          </div>

          <div className="status ">
            {/* {renderStatus()} */}
          </div>

          <div className="actions">

            <Link to={`/edit-domain/${companyId}/${domain._id}`}>
              <button>
                <img src={edit} alt="edit"/>
              </button>
            </Link>
            
            {companyId !== "5e9ec41b25ab1e65768e2ff0" &&
              <button
                onClick={() => setRemoveAction({ function: () => deleteDomainAction(dispatch , domain._id )}) }
                >
                <img src={remove} alt="remove"/>
              </button>
            }

          </div>
      </>
    )
  }

  return (
    <div className="list-item transition domain-row" >
     {renderRow()}
    </div>
  )
}
