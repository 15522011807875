import {
GET_PAGE,
UPDATE_PAGE,
ERROR_PAGE,
MESSAGE_PAGE,
RESET_PAGE,
SAVE_PAGE
} from "../actions/types"

const initialState = {
  page: null,
  error: null,
  message: null,
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
  
  case SAVE_PAGE:
    return {...state , page: action.payload }

  case RESET_PAGE:
    return {...state , page: null }

  case GET_PAGE:
    return {...state , page: action.payload.content}
  
  case UPDATE_PAGE:
    return {...state , page: action.payload , message: 'Theme successfully updated' }

  case ERROR_PAGE:
      return {...state , error: action.payload}

  case MESSAGE_PAGE:
      return {...state , message: action.payload}
      
    default:
      return {...state};
  }
}