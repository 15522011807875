import React, { useEffect , useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction } from '../../../actions/authActions';

import logo from '../../../assets/images/logo.svg';
import './auth.scss';

export default function LoginPage(props) {
  const history = useHistory();
  
  const { handleSubmit, register, errors } = useForm();
  const [ context, dispatch ] = useGlobalContext();

  const [ displayLogin , setDisplayLogin ] = useState(true);

  const { setDisplayNavBar } = props ;


  useEffect(() => {
    setDisplayNavBar(false);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [setDisplayNavBar, dispatch ]);


  useEffect(() => {
    if(context.auth.userAuth && context.auth.userAuth.profile) {
      history.push('/home');
    }
    // eslint-disable-next-line 
  },[context.auth.userAuth])

  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  function renderLoginForm() {
    return (
      <form className="form-container" onSubmit={handleSubmit(submitLogin)} autoComplete="nope">

        <img src={logo} alt="logo" className="logo" />

        <label htmlFor="email">Email</label>
        <input
          name="email"
          ref={register({
            required: "Compléter votre email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Adresse mail invalide"
            }
          })}
        />

        <p className="error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Mot de passe</label>
        <input
          name="password"
          type="password"
          ref={register({
            required: "Compléter votre mot de passe",
          })}
        />
        <p className="error">{errors.password && errors.password.message}</p>
        
        <div className="btn-container">
          <button className="btn primary" type="submit">Connexion</button>
          <button onClick={() => setDisplayLogin(false)} className="btn-reset">Mot de passe oublié ?</button>
        </div>

        <p className="error error-connexion">{context.auth.error && context.auth.error.status === 401 && <span>Erreur de connexion : <br/> Adresse e-mail ou mot de passe invalide.</span>}</p>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className="form-container">
          <img src={logo} alt="logo" className="logo" />

          <p>Veuillez vérifier votre adresse e-mail pour connaître<br/>le lien permettant de réinitialiser votre mot de passe.</p>

          <button onClick={() =>  backFromReset()} className="btn primary">Retour au login</button>
        </div>
      )

    } else {
      return (
        <form className="form-container" onSubmit={handleSubmit(submitReset)}>

          <img src={logo} alt="logo" className="logo" />
        
          <label htmlFor="email">Email</label>
          <input
            name="email"
            ref={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
          />

          <p className="error">{errors.email && errors.email.message}</p>
          
          <div className="btn-container">
            <button className="btn primary" type="submit">Réinitialiser le mot de passe</button>
            <button onClick={() => setDisplayLogin(true)} className="btn-reset">Retour au login</button>
          </div>
      
        </form>
      )
    }
  }

  return (
    <div className="login-page bg-login main">

      <div className="col-content">
        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }
      </div>
    </div>
  );
};