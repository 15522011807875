import React, { useState , useEffect , useCallback } from 'react';
import { useParams , Link } from "react-router-dom";
import Select from "react-select";

import { API_URL } from '../../../actions/index';
import { companiesColors } from '../../../utils/utils';
import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getAgencyAction , getZonesAction , updateAgencyAction } from '../../../actions/agenciesActions';
import { getContactsAction  , getTagsAction } from '../../../actions/contactsActions';

import ContactRow from './ContactRow';
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
import SelectMedia from '../medias/SelectMedia';

import './editAgency.scss';

export default function EditAgency({ setDisplayNavBar, setActivePage , requireRight,  theme , lang }) {

  const [ context, dispatch ] = useGlobalContext();

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      marginTop: -22,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop: 0,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: 44,
      padding: "10px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const param = useParams();

  const [ agency , setAgency ] = useState();
  const [ contactList , setContactList ] = useState();
  const [ addContact , setAddContact ] = useState();

  const [ zonesOptions , setZonesOptions ] = useState([]);

  const [ isSaved , setIsSaved ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);


  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getTagsAction(dispatch , lang , 'contact' );
    getZonesAction(dispatch , lang , param.companyId )
  },[dispatch , param.companyId , lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setActivePage("agencies");
    getCompany();
    if (!context.agencies.agency) {
      getAgencyAction(dispatch , param.agencyId)
    } else {
      setAgency(context.agencies.agency)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (context.agencies.agency && context.agencies.zones ) {
      setAgency(context.agencies.agency);
      if (requireRight('contact')) {
        getContactsAction(dispatch , lang );
      }
      if (context.agencies.zones.length) {
        setZonesOptions(context.agencies.zones)
      }
    }
    // eslint-disable-next-line
  }, [context.agencies.agency , context.agencies.zones , context.auth.userAuth , lang ]);

  useEffect(() => {
    if (context.contacts.contactsList && context.contacts.contactsList.length && agency && agency._id ) {
      setContactList(context.contacts.contactsList.filter(contact =>  contact.agency === agency._id))
    }
  }, [context.contacts.contactsList , agency]);

  useEffect(() => {
    if (JSON.stringify(agency) === JSON.stringify(context.agencies.agency)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[agency , context.agencies.agency])

  useEffect(() => {
    if (context.agencies.message === "Agency successfully updated!" && context.agencies.message === "Agency successfully updated!"  ) {

      setPublishIsLoading(false);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'agence a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_AGENCIES',
        payload: null
      });

    }

    if (context.contacts.message) {
      getContactsAction(dispatch , lang );
    }

  },[context.agencies.message , context.contacts.message , dispatch , lang ]);


  function handleChangeAgency(key , value ) {
    var updatedAgency = {...agency};
    switch (key) {
      case 'manager.firstName' :
        if (!updatedAgency.manager) updatedAgency.manager = {}
        updatedAgency.manager.firstName = value;
        break;
      case 'manager.lastName' :
        if (!updatedAgency.manager) updatedAgency.manager = {}
        updatedAgency.manager.lastName = value;
        break;
      case 'name':
        updatedAgency.name = value;
        break;
      case 'city':
        updatedAgency.city = value;
        break;
      case 'adressLine1':
        updatedAgency.adressLine1 = value;
        break;
      case 'adressLine2':
        updatedAgency.adressLine2 = value;
        break;
      case 'postaleCode':
        updatedAgency.postaleCode = value;
        break;
      case 'cedex':
        updatedAgency.cedex = value;
        break;
      case 'country':
        updatedAgency.country = value;
        break;
      case 'zone':
        updatedAgency.zones = value.map(item => { return item.value });
        break;
      case 'phone':
        updatedAgency.phone = value;
        break;
      case 'isInternational' :
        updatedAgency.isInternational = value;
        break;
      case 'file':
        updatedAgency.file = value[0];
        break;
      case 'coordinates' :
        updatedAgency.lat = value.lat;
        updatedAgency.lon = value.lon;
        break;
      case 'email':
        updatedAgency.email = value;
        break;
      default:
        updatedAgency = {...agency}
    }
    return setAgency(updatedAgency)
  }

  function submitAgency() {
    setPublishIsLoading(true)
    updateAgencyAction(dispatch , agency )
  }

  function openSelectFile() {
    setDisplaySelectMedia(true);
    if (agency.file) {
      var fileArray = []
      fileArray.push(agency.file);
      setSelectedMediaList(fileArray)
    }
  }

  function renderFile() {
    if(agency.file  && context.medias.mediasList) {
      var agencyFileFound = context.medias.mediasList.find(item => item._id === agency.file._id || item._id === agency.file );
      if (agencyFileFound) return (
        <div
          className="file-row bold">
          <button
            className="bold"
            onClick={() =>  window.open(API_URL + '/' + agencyFileFound.path)}
            >
            {agencyFileFound.name}
          </button>
        </div>
      )
    }
  }

  function renderContactsList() {
    if (contactList) {
      return contactList.map((contact) => {
        return <ContactRow contact={contact} setAddContact={setAddContact} setRemoveAction={setRemoveAction} getContactsAction={getContactsAction} agencyId={param.agencyId} dispatch={dispatch} companyId={param.companyId} lang={lang} theme={theme} key={contact._id} />
      })
    } else {
      return <span>Aucune contact n'a été trouvée</span>
    }
  }

  return (
    <>
      <PromptRouter when={!isSaved} /> 
      <div className={`page-container ${theme}`}>
        {agency ?
          <div className="page-content">

            <div className="title-row space-between">

              <div className="flex">
                <Link to={`/agencies-list/${param.companyId}/${lang}`}>
                  <button
                    className="btn primary back">
                    Retour
                  </button>
                </Link>

                <div className="title">
                  <h1>{agency.name && `${agency.name}`} <span className="opacity">{agency.adressLine1 && `/ ${agency.adressLine1}`} {agency.city && `/ ${agency.city}`}  {agency.country && `/ ${agency.country}`} </span></h1>
                  <div className="bar primary"></div>
                  <p><span className={`${theme}-color required bold`}>*</span> champ requis</p>
                </div>
              </div>

              <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      onClick={() => submitAgency()}
                      >
                      Publier
                    </button>
                  }
                </div>

            </div>


            <div className="edit-container">

              <div className="col-content">

                {requireRight('contact') &&
                  <>
                    <div className="contact-header">
                      <h2 className={`${theme}-color`}>Liste des contacts</h2>
                      <button
                        onClick={() => setAddContact(true)}
                        className="btn primary">
                          Ajouter un contact
                      </button>
                    </div>

                    <div className="list-container">
                      {addContact &&  <ContactRow isEdit={true} lang={lang} setAddContact={setAddContact} agencyId={param.agencyId} companyId={param.companyId} dispatch={dispatch} theme={theme} /> }
                      {renderContactsList()}
                    </div>
                  </>
                }
              </div>

              <div className="col-aside">
                <h2 className={`${theme}-color`}>Informations sur l'agence</h2>
                
                <div className="check">
                  <input id="isInternational"
                    onChange={(e) => handleChangeAgency('isInternational' , e.target.checked )}
                    type="checkbox"
                    className="switch"
                    checked={agency.isInternational ? agency.isInternational : false }
                    style={{ marginRight: 20 }}
                    />
                  {agency.isInternational ? <span>Agence internationale</span> : <span>Agence nationale</span> }
                </div>

                <label htmlFor="zone">Zones <span className={`${theme}-color required`}>*</span></label>
                <Select
                  styles={customStyles}
                  defaultValue={zonesOptions.filter(item => agency.zones.find(z =>  item.value === z ? item : null ))}
                  options={zonesOptions}
                  isMulti={true}
                  placeholder={"Sélectionner une zone"}
                  isSearchable={false}
                  onChange={(val) => handleChangeAgency('zone' , val)}
                />

                <label htmlFor="name">Nom de l'agence <span className={`${theme}-color required`}>*</span></label>
                <input
                  value={agency.name ? agency.name : ""}
                  onChange={(e) => handleChangeAgency('name' , e.target.value)}
                  name="name"
                />


                <label>Responsable<span className={`${theme}-color required`}>*</span></label>
                <input
                  className="mb-0"
                  placeholder="Nom"
                  value={agency?.manager?.lastName ? agency?.manager?.lastName : ""}
                  onChange={(e) => handleChangeAgency('manager.lastName' , e.target.value)}
                  name="manager.lastName"
                />
                <input
                  placeholder="Prénom"
                  value={agency?.manager?.firstName ? agency?.manager?.firstName : ""}
                  onChange={(e) => handleChangeAgency('manager.firstName' , e.target.value)}
                  name="manager.firstName"
                />
                {/* <AgencyMap handleChange={(val) => handleChangeAgency('coordinates' , val)} lat={agency.lat} lon={agency.lon} theme={theme} /> */}

                <label htmlFor="adressLine1">Adresse <span className={`${theme}-color required`}>*</span></label>
                <input
                  className="mb-0"
                  value={agency.adressLine1 ? agency.adressLine1 : ""}
                  onChange={(e) => handleChangeAgency('adressLine1' , e.target.value)}
                  name="adressLine1"
                />

                <label htmlFor="adressLine2"><span className="note">2éme ligne</span></label>
                <input
                  value={agency.adressLine2 ? agency.adressLine2 : ""}
                  onChange={(e) => handleChangeAgency('adressLine2' , e.target.value)}
                  name="adressLine2"
                />

                <label htmlFor="postaleCode">Code Postal <span className={`${theme}-color required`}>*</span></label>
                <input
                  value={agency.postaleCode ? agency.postaleCode : ""}
                  onChange={(e) => handleChangeAgency('postaleCode' , e.target.value)}
                  name="postaleCode"
                />

                <label htmlFor="cedex">cedex</label>
                <input
                  value={agency.cedex ? agency.cedex : ""}
                  onChange={(e) => handleChangeAgency('cedex' , e.target.value)}
                  name="cedex"
                />

                <label htmlFor="city">Ville <span className={`${theme}-color required`}>*</span></label>
                <input
                  value={agency.city ? agency.city : ""}
                  onChange={(e) => handleChangeAgency('city' , e.target.value)}
                  name="city"
                />

                <label htmlFor="country">Pays <span className={`${theme}-color required`}>*</span></label>
                <input
                  value={agency.country ? agency.country : ""}
                  onChange={(e) => handleChangeAgency('country' , e.target.value)}
                  name="country"
                />

                <label htmlFor="phone">Téléphone <span className={`${theme}-color required`}>*</span></label>
                <input
                  value={agency.phone ? agency.phone : ""}
                  onChange={(e) => handleChangeAgency('phone' , e.target.value )}
                  name="phone"
                />

                <label htmlFor="email">Email</label>
                <input
                  value={agency.email ? agency.email : ""}
                  onChange={(e) => handleChangeAgency('email' , e.target.value)}
                  name="email"
                />

                <label>Fiche agence</label>
                <div className="select-media mb-20">
                  {renderFile()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectFile(true)}
                    >
                    Parcourir
                  </button>

                  {/* {agency.file &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChangeAgency('file' , [null])})}
                      >
                      Supprimer
                    </button>
                  } */}
                </div>

              </div>

            </div>

          </div>
        :
          null
        }
        <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={'file'}
          companyId={param.companyId}
          selectedMediaList={selectedMediaList}
          isMulti={false}
          handleChange={(value) => handleChangeAgency( 'file' , value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        /> 
      </div>
    </>
  )
}
