import {
  GET_COMPANIES,
  GET_COMPANY,
  GET_CHANGES,
  ERROR_COMPANIES,
} from "../actions/types"

const initialState = {
  companiesList: null,
  company: null,
  error: null,
  changes: null
};

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHANGES:
      return {...state , changes: action.payload}
    case GET_COMPANY:
      return {...state , company: action.payload}
    case GET_COMPANIES:
      return {...state , companiesList: action.payload}
    case ERROR_COMPANIES:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}