import React , { useRef } from 'react';
import { API_URL } from '../../../actions/index';

import remove from '../../../assets/images/icons/remove.svg';
import pdfIcon from '../../../assets/images/icons/pdf-icon.png';
import exelIcon from '../../../assets/images/icons/exel.png';
import edit from '../../../assets/images/icons/edit.svg';

import { deleteMedia } from '../../../actions/mediasActions';
 
export default function MediaCard({ media , theme, dispatch , isOnModale , selectedList , setEditMedia , setRemoveAction } ) {

  const mediaRef = useRef();
  const cardRef = useRef();

  function renderImg() {
    if (media.extension === "jpeg" || media.extension === "png" || media.extension === "svg" || media.extension === "svg+xml") {

      var i = new Image();
      i.src = API_URL +'/'+ media.path; 

      return (
        <div ref={mediaRef} id={"img" + media._id}>
          <img src={API_URL +'/'+ media.path} alt={media.alt} />
          <p className="size">ficher {media.extension} <br/>{i.naturalWidth > 0 && i.naturalHeight > 0 && <span>{i.naturalWidth} x {i.naturalHeight} px</span>}</p>
        </div>
      )
    } else if (media.extension === "mp4") {
      // getVideoHeight( API_URL +'/'+ media.path);
      return (
        <div ref={mediaRef} id={"video" + media._id}>
          <video controls="controls">
            <source src={API_URL +'/'+ media.path}  type={media.mimetype} />
          </video>
          <p className="size">ficher {media.extension}</p>
          {/* <p className="size">{videoSize}</p> */}
        </div>
      );
    } else if (media.extension === "pdf") {
      return (
        <div ref={mediaRef} id={"pdf" + media._id} >
          <img src={pdfIcon} className="file" alt={media.alt} />
          <p className="size">ficher pdf</p>
        </div>
      )
    } else if (media.extension === "csv") {
      return (
        <div ref={mediaRef} id={"file" + media._id} >
          <img src={exelIcon} className="file" alt={media.alt} />
          <p className="size">ficher csv</p>
        </div>
      )
    }
  };

  function copyUrl(textToCopy) {
    
    const textarea = document.createElement('textarea')
    
    textarea.value = textToCopy
    
    textarea.setAttribute('readonly', '');
    
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    
    document.body.appendChild(textarea);
  
    textarea.select()
  
    try {
      document.execCommand('copy');
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'url a bien été copié",
          type: "success"
        }
      });
    } catch(err) {
    }
  
    textarea.remove()
  }

  return (
    <div id={"card" + media._id} ref={cardRef} className={`media-card ${selectedList && selectedList.find(item => item === media._id ) ? "selected" :""}`}>
      <div>
        {renderImg()}
        <h3 className="bold" >{media.title}</h3>
        <p>{media.description}</p>
        <div className="langs">
          {media.langs.map((l) => <div key={l} className="lang">
            <p>{l}</p>
          </div>)}
        </div>
      </div>
      
      <div className="remove-container">
        {!isOnModale ?
          <>
            <button
              className="remove"
              onClick={() => setEditMedia(media)}
              >
              <img src={edit} alt="remove"/>
            </button>
            <button
              className="copy bold"
              onClick={() => copyUrl(API_URL +'/'+ media.path)}>
              copier l'url
            </button>
            <button
              className="remove"
              onClick={() => setRemoveAction({ function:() => deleteMedia(dispatch , media._id)})}>
              <img src={remove} alt="remove"/>
            </button>
          </>
          :
          <button
            className="copy bold"
            onClick={() => copyUrl(API_URL +'/'+ media.path)}>
            copier l'url
          </button>
        }
      </div>
    </div>
  )
}
