import React from 'react';
import Modale from '../Modale';

export default function RemoveModale({ removeAction , setRemoveAction  , theme , text }) {

  function remove() {
    removeAction.function();
    setRemoveAction()
  }

  return (
    <Modale
    modaleIsOpen={removeAction && removeAction.function ? true : false}
    modaleToggle={() => setRemoveAction()}
    className={"delete-modale"}
      >
      {removeAction && removeAction.function &&
        <div className="content">
          <h2>{text?.sentence ? <span dangerouslySetInnerHTML={{ __html:text?.sentence }}></span> : 'Confirmation de suppression'}</h2>
          <div className='confirm'>
            <button className={`btn primary ${theme}-bg`} onClick={() => setRemoveAction()}>{text?.cancel ? text?.cancel  : 'Annuler la suppression'}</button>
            <button className={`btn success`} onClick={() => remove()}>{text?.cancel ? text?.confirm  : 'Confirmer la suppression'}</button>
          </div>
        </div>
      }
    </Modale>
  )
}
