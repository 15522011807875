import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams  } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';

import { getMediasAction } from '../../../actions/mediasActions';
import { getMissionsAction } from '../../../actions/missionsActions';
import { getCompanyAction , getCompaniesAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import '../../../styles/pages-sites.scss';
import TextEditor from '../../partials/TextEditor';
// import EditMission from './EditMission';
import EditCompany from './EditCompany';

export default function HomeGroup({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'home';

  const { handleSubmit, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const [ homeGroup , setHomeGroup ] = useState();
  const homeRef = useRef({});
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);
  const [ companies , setCompanies ] = useState([]);
  // const [ missions , setMissions ] = useState();
  const [ isPublished , setIsPublished ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
    getCompaniesAction(dispatch);
    getMissionsAction(dispatch , lang );
  },[dispatch , param.companyId , lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("home-group");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setHomeGroup();
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    getMissionsAction(dispatch , lang );
    setHomeGroup();
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);


  useEffect(() => {
    if (context.theme.page) {
      homeRef.current = {
        title:  context.theme.page.title,
        text:  context.theme.page.text,
        picture1 : context.theme.page.picture1,
        picture2 : context.theme.page.picture2
      };
      setIsSaved(true)
      setIsMounted(true);
    } else {
      setHomeGroup();
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  // useEffect(() => {
  //   if (context.missions.missionsList) {
  //     setMissions(context.missions.missionsList)
  //   }
  // },[context.missions]);

  useEffect(() => {
    if (context.companies.companiesList) {
      setCompanies(context.companies.companiesList.filter(item => item.type !== 'group'));
    }
  },[context.companies])

  // useEffect(() => {
  //   if (JSON.stringify(homeRef.current) === JSON.stringify(context.theme.page)) {
  //     setIsSaved(true);
  //     setIsPublished(false);
  //   } else {
  //     setIsSaved(false);
  //   }
  //   // eslint-disable-next-line
  // },[homeRef.current , context.theme.page])

  useEffect(() => {
    if (context.theme && context?.theme?.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);
      setIsSaved(true);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function submitMedia(value) {
    handleChange( value[0] , mediaKey )
  };

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (homeRef.current && homeRef.current[imgkey] && homeRef.current[imgkey].picture && homeRef.current[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(homeRef.current[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function renderImgLarge(key) {
    var imgFound;
    if(homeRef.current && homeRef.current[key] && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === homeRef.current[key] )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt={homeRef.current[key]} className="img-large" />
      } else if (homeRef.current[key].path) {
        return <img src={API_URL + '/' + homeRef.current[key].path} alt={homeRef.current[key]} className="img-large"/>
      }
    }
  }


  const submitGroup = (values)  => {
    
    setPublishIsLoading(true);
    setIsPublished(true);

    var groupData = {...values, ...homeRef.current};
    var obj = {};

    for (const [key, value] of Object.entries(groupData)) {
        // if (context.theme.page[key] !== value ) {
          obj[`${page}.${key}`] = value;
        // }
    }

    updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });

  };

  function handleChange( value , obj) {
    if (isSaved) {
      setIsSaved(false);
    }
    var data = Object.assign({} , homeRef.current );
    if (data[obj]) {
      data[obj] = value
    }
    homeRef.current = data
  };

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">

        <form onSubmit={handleSubmit(submitGroup)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <label htmlFor="title">Titre</label>
                <textarea
                  name="title"
                  onChange={(e) => handleChange( e.target.value , "title" )}
                  defaultValue={homeRef && homeRef.current?.title ? homeRef.current?.title : '' }
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <label>Paragraphe</label>
                <TextEditor
                  value={homeRef && homeRef?.current?.text}
                  onChange={(val) => handleChange( val , "text" )}
                />

                <h1>Les Sociétés</h1>
                {companies && companies.map((company , i ) => <EditCompany
                  key={'company'  + i}
                  companyId={param.companyId}
                  isPublished={isPublished}
                  setRemoveAction={setRemoveAction}
                  theme={theme}
                  lang={lang}
                  company={company}/>)}

              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 535 x 1170 px , 72 dpi</span></label>
                {renderImgLarge('picture1')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("picture1")}
                    >
                    Parcourir
                  </button>

                  {homeRef && homeRef.picture1 &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChange( null , "picture1" )})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px , 72 dpi</span></label>
                {renderImgLarge('picture2')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("picture2")}
                    >
                    Parcourir
                  </button>

                  {homeGroup && homeGroup.picture2 &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChange( null , "picture1" )})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
    <PromptRouter when={!isSaved} />
    {isMounted && homeRef.current && <div className={`page-container site ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="flex">

            <div className="title">
              <h1>Edition de la page d'accueil ({lang})</h1>
              <div className="bar primary"></div>
            </div>

          </div>

        </div>

        <div className="list-container">
          {renderEdit()}
        </div>

      </div>
    </div>}
    <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
