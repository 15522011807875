import {
  GET_AGENCIES,
  POST_AGENCY,
  GET_AGENCY,
  DELETE_AGENCY,
  UPDATE_AGENCY,
  ERROR_AGENCIES,
  MESSAGE_AGENCIES,
  GET_ZONES
} from "../actions/types"

const initialState = {
  agenciesList: null,
  agency: null,
  error: null,
  message: null,
  zones: null
};


export default function agenciesReducer(state = initialState, action) {

  var agenciesList = state.agenciesList;

  switch (action.type) {
    case GET_ZONES:
      return {...state , zones: action.payload};

    case GET_AGENCIES:
      return {...state , agenciesList: action.payload};
      
    case POST_AGENCY:

      return {...state ,
        agenciesList: [...agenciesList, action.payload.agency ],
        agency: action.payload.agency,
        message: action.payload.message
      };

    case UPDATE_AGENCY:
      
      if (agenciesList) {
        var found = agenciesList.find( agency => agency._id === action.payload.agency._id);
        if (found) {
          agenciesList = agenciesList.filter( agency => agency._id !== action.payload.agency._id);
        }
      } else {
        agenciesList = [];
      }

      agenciesList.push(action.payload.agency);

      return {
        ...state,
        agenciesList: agenciesList,
        agency: action.payload.agency,
        message: action.payload.message
      }

    case GET_AGENCY:
      return {...state , agency: action.payload }

    case DELETE_AGENCY:
      agenciesList = state.agenciesList.filter( agency => agency._id !== action.payload)
      return {...state , agenciesList: agenciesList }

    case MESSAGE_AGENCIES:
        return {...state , message: action.payload}

    case ERROR_AGENCIES:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}