import React ,{ useState , useEffect } from 'react';

import { useForm } from "react-hook-form";
import Select from "react-select";

import { companiesColors } from '../../../utils/utils';
import { createUserAction , updateUserAction } from '../../../actions/usersActions';

export default function EditUser(props) {

  const { user , theme , dispatch , setDisplayUserModale , context} = props;

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }


  const rightsOptions = [
    { value : "page"  , label: "pages"},
    { value : "projet"  , label: "projets"},
    { value : "contact"  , label: "contacts"},
    { value : "talent"  , label: "talents"},
    { value : "article" , label : "articles"},
    { value : "utilisateur" , label : "utilisateurs"},
    { value : "agency" , label : "agences"},
    { value : "file" , label : "fichiers"},
  ]
       
  const roleOptions = [
    { value: 'Owner', label: 'Owner' },
    { value: 'Admin', label: 'Admin' },
  ];

  const { handleSubmit, register, errors , setValue } = useForm();

  const [ error , setError ] = useState();
  const [ rights , setRights ] = useState();
  const [ role , setRole ] = useState(roleOptions[0]);
  const [ companiesOptions , setCompaniesOptions ] = useState();
  const [ selectedCompanies , setSelectedCompanies ] = useState();

  const handleChangeRights = selectedOption => {
    setValue("rights", selectedOption ? selectedOption.map(right => { return right.value }) : []);
    setRights(selectedOption);
  };

  const handleChangeCompanies = selectedOption => {
    setValue("companies", selectedOption ? selectedOption.map(company => { return company.value }) : []);
    setSelectedCompanies(selectedOption);
  };

  const handleChangeRole = selectedOption => {
    setValue("role", selectedOption.value );
    setRole(selectedOption);
  };

  useEffect(() => {
    if(context.companies.companiesList ) {
      var opts = context.companies.companiesList.map(comp => ({
        value: comp._id , label: comp.name
      }))
      setCompaniesOptions(opts);
    }
  }, [context.companies])

  useEffect(() => {
    setError()
    return () => setError();
  }, [])

  useEffect(() => {
    register({ name: "rights" });
    register({ name: "role" });
    register({ name: "companies" });
  }, [register])

  useEffect(() => {
    if (user && user.rights.length) {
      setValue("rights" , user.rights );
      var userRights = [];
      rightsOptions.forEach(opt => {
        if(user.rights.find(right => right === opt.value)) {
          userRights.push(opt);
        }
      });
      setRights(userRights);
    } else {
      setValue("rights" , []);
      setRights();
    }

    if (user && user.role) {
      setValue("role" , user.role );
      setRole(roleOptions.find(opt => opt.value === user.role));
    } else {
      setValue("role" , roleOptions[0].value );
    }

    if (user && user.companies.length && companiesOptions) {
      setValue("companies" , user.companies );
      var companies = [];
      companiesOptions.forEach(opt => {
        if(user.companies.find(comp => comp === opt.value)) {
          companies.push(opt);
        }
      });
      setSelectedCompanies(companies)
    } 

    if (!user && companiesOptions && context.companies.company) {
      setSelectedCompanies(companiesOptions.filter(opt => opt.value === context.companies.company._id));
      setValue("companies", companiesOptions.find(opt => opt.value === context.companies.company._id).value)
    }
    // eslint-disable-next-line
  }, [user , companiesOptions]);

  useEffect(() => {
    if (context.users && context.users.message) {
      setDisplayUserModale(false);
      dispatch({
        type:'MESSAGE_USER',
        payload: null
      });
    };
    if (context.users.error && context.users.error.data && context.users.error.data.error === "That email address is already in use.") {
      setError("Cette adresse email est déjà utilisée.");
    }
    if (context.users.error && context.users.error.status === 500) {
      setError("une erreur de serveur interne s'est produite");
    }
    // eslint-disable-next-line
  },[context.users.message , context.users.error]);



  const submitUser = (values)  => {
    if (user && user.profile) {
      var userData = values
      userData._id = user._id
      updateUserAction(dispatch , userData );
    } else {
      createUserAction(dispatch , values );
    }
    setError()
    dispatch({
      type:'ERROR_USERS',
      payload: null
    });
    dispatch({
      type:'MESSAGE_USER',
      payload: null
    });
  };

  return (
    <div className="user-form">
      <div className="title">
        <h2>{user ? "Modifier un utilisateur" : "Ajout d'un utilisateur"}</h2>
        <div className={`bar ${theme}-bg`}></div>
      </div>
      <form onSubmit={handleSubmit(submitUser)} autoComplete="nope">

          <div className="form-row">
            <div className="col-2">

              <label htmlFor="profile.firstName">Prénom</label>
              <input
                name="profile.firstName"
                defaultValue={user && user.profile && user.profile.firstName ? user.profile.firstName  : undefined }
                ref={register({
                  required: "Compléter le prénom",
                })}
              />
              <p className="error">{errors.profile && errors.profile.firstName && errors.profile.firstName.message}</p>

              <label htmlFor="profile.lastName">Nom</label>
              <input
                name="profile.lastName"
                defaultValue={user && user.profile && user.profile.lastName ? user.profile.lastName  : undefined }
                ref={register({
                  required: "Compléter le nom",
                })}
              />
              <p className="error">{errors.profile && errors.profile.lastName && errors.profile.lastName.message}</p>

              <label htmlFor="email">Email</label>
              {user && user.email &&
                <input
                  name="email"
                  defaultValue={user.email}
                  ref={register({
                    required: "Compléter l'email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Adresse mail invalide"
                    }
                  })}
                />
              }
              {!user &&
                <input
                  name="email"
                  ref={register({
                    required: "Compléter votre email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Adresse mail invalide"
                    }
                  })}
                />
              }
              <p className="error">{errors.email && errors.email.message}</p>
            </div>

            <div className="col-2">

              <label htmlFor="acces">Accés</label>
              <Select
                styles={customStyles}
                value={rights}
                placeholder={'Sélectionner'}
                options={rightsOptions}
                onChange={handleChangeRights}
                isMulti
              />

              <label htmlFor="role">Rôle</label>
              <Select
                styles={customStyles}
                value={role}
                options={roleOptions}
                onChange={handleChangeRole}
              />

              <label htmlFor="companies">Entreprises</label>
              <Select
                styles={customStyles}
                value={selectedCompanies}
                options={companiesOptions}
                placeholder={'Sélectionner'}
                onChange={handleChangeCompanies}
                isMulti
              />

            </div>
          </div>
          
          <div className="btn-container">
            {error && <p className="error">{error}</p>}
            <button className={`btn success`} type="submit">Valider</button>
          </div>
      
      </form>
    </div>
  )
}
