import { getData , putData } from './index';

import {
  GET_PAGE,
  UPDATE_PAGE,
  ERROR_PAGE,
  SAVE_PAGE
} from "./types"


export async function getPageAction(dispatch , themeId , page , lang ) {
  let url = `/theme/company/${themeId}/${page}/${lang}`
  var theme;

  await getData(ERROR_PAGE, url, dispatch , true ).then((response)=>{
    theme = response
  });

  dispatch({
    type : GET_PAGE,
    payload : theme
  });

}

export async function updatePageAction(dispatch , themeId , site , page , param ) {
  let url = `/theme/${site}/${themeId}/`
  var theme;

  await putData(UPDATE_PAGE, ERROR_PAGE ,  url, dispatch, param , true ).then((response)=>{
    theme = response.theme[page]
  });

  dispatch({
    type : UPDATE_PAGE,
    payload : theme
  });
}

export async function savePageAction(dispatch , themeId , site , page , param ) {
  let url = `/theme/${site}/${themeId}/`
  var theme;

  await putData(UPDATE_PAGE, ERROR_PAGE ,  url, dispatch, param , true ).then((response)=>{
    theme = response.theme[page]
  });

  dispatch({
    type : SAVE_PAGE,
    payload : theme
  });

}