import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useForm } from "react-hook-form";
import { API_URL } from "../../../actions/index";

import { putMediaAction } from '../../../actions/mediasActions';
import { useGlobalContext } from '../../context/GlobalContext';
import pdfIcon from '../../../assets/images/icons/pdf-icon.png';
import exelIcon from '../../../assets/images/icons/exel.png';
import { companiesColors, langsOptions } from '../../../utils/utils';
import './medias.scss';

export default function EditMedia({ media , setEditMedia , theme }) {
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }
  const mediaRef = useRef();
  const [ selectedLangs, setSelectedLangs] = useState(langsOptions.filter((l) => media.langs.find((m) => m === l.value)));
  const [ isSchool , setIsSchool ] = useState(false);

  const { handleSubmit, register, setValue, watch, errors } = useForm();
  const dispatch = useGlobalContext()[1];

  useEffect(() => {
    if (media.type === "school") {
      setIsSchool(true);
    }
  },[media])

  const submitMedia = (values)  => {
    const langs = selectedLangs?.map((l) => l.value);
    const data = {
      ...values,
      langs,
    }
    data.type = isSchool ? "school" : "";
    putMediaAction(dispatch , media._id , data )
    setEditMedia();
  };

  function renderImg() {
    if (media.extension === "jpeg" || media.extension === "png" || media.extension === "svg" || media.extension === "svg+xml") {

      var i = new Image();
      i.src = API_URL +'/'+ media.path; 

      return (
        <div ref={mediaRef} id={"img" + media._id}>
          <img src={API_URL +'/'+ media.path} alt={media.alt} />
          <p className="size">fichier {media.extension} <br/>{i.naturalWidth > 0 && i.naturalHeight > 0 && <span>{i.naturalWidth} x {i.naturalHeight} px</span>}</p>
        </div>
      )
    } else if (media.extension === "mp4") {
      // getVideoHeight( API_URL +'/'+ media.path);
      return (
        <div ref={mediaRef} id={"video" + media._id}>
          <video controls="controls">
            <source src={API_URL +'/'+ media.path}  type={media.mimetype} />
          </video>
          <p className="size">fichier {media.extension}</p>
          {/* <p className="size">{videoSize}</p> */}
        </div>
      );
    } else if (media.extension === "pdf") {
      return (
        <div ref={mediaRef} id={"pdf" + media._id} >
          <img src={pdfIcon} className="file" alt={media.alt} />
          <p className="size">fichier pdf</p>
        </div>
      )
    } else if (media.extension === "csv") {
      return (
        <div ref={mediaRef} id={"file" + media._id} >
          <img src={exelIcon} className="file" alt={media.alt} />
          <p className="size">fichier csv</p>
        </div>
      )
    }
  };

  return (
    <form className="add-media-container" onSubmit={handleSubmit(submitMedia)}>
      
      <div className="image">
        {renderImg()}
      </div>

      <label htmlFor="title">Titre</label>
      <textarea
        name="title"
        defaultValue={media.title}
        ref={register({
          required: "Compléter un titre",
        })}
      />
      <p className="error">{errors.title && errors.title.message}</p>

      <label htmlFor="description">Description</label>
      <textarea
        name="description"
        defaultValue={media.description}
        ref={register({
          required: "Compléter une description",
        })}
      />

      <label htmlFor="langs">Langues</label>
      <Select
        styles={customStyles}
        value={selectedLangs}
        options={langsOptions}
        placeholder={'Sélectionner des langues'}
        onChange={setSelectedLangs}
        isMulti
      />

      {media.extension === "pdf" &&
        <div>
          <input id="setIsSchool"
            onChange={(e) => setIsSchool(e.target.checked)}
            type="checkbox" className="switch" checked={isSchool}
            />
          <label htmlFor="setIsSchool">Ne pas afficher dans la médiathèque</label>
        </div>
      }

      <div>
        <input
          name="isPriority"
          defaultChecked={media.isPriority}
          onChange={(e) => setValue('isPriority', e.target.checked)}
          ref={register()}
          type="checkbox"
          className="switch"
          />
        <label htmlFor="isPriority">{watch('isPriority') ? <span>Média prioritaire</span> : <span>Média non prioritaire</span> }</label>
      </div>

      <div className="btn-container">
        <button className={`btn success`} type="submit">Valider</button>
      </div>
    </form>
  )
}
