import history from "../utils/history";
import React, { useEffect , useState , useCallback } from "react";
import { Router, Switch, Route } from "react-router-dom";

import { useGlobalContext } from './context/GlobalContext';
import { getUserAuthAction } from '../actions/authActions';
import { getCompaniesAction } from '../actions/companiesActions';

import Nav from './partials/nav/Nav';
import SiteNav from './partials/nav/SiteNav';
import Toaster from './partials/Toaster/Toaster';

import LoginPage from './pages/auth/LoginPage';
import ResetPasswordPage from  './pages/auth/ResetPasswordPage';

import HomePage from './pages/home/HomePage';

import ProjectsList from "./pages/projects/ProjectsList";
import EditProject from "./pages/projects/EditProject";

import ArticlesList from "./pages/articles/ArticlesList";
import EditArticle from './pages/articles/EditArticle';

import AgenciesList from './pages/agencies/AgenciesList';
import EditAgency from './pages/agencies/EditAgency';

import MediasPage from './pages/medias/MediasPage';

import UsersPage from './pages/users/UsersPage';

import DomainsList from "./pages/domains/DomainsList";
import EditDomain from "./pages/domains/EditDomain";
import EditSubDomain from "./pages/domains/EditSubDomain";

import Missions from "./pages/missions/Missions";

import Innovations from "./pages/innovations/Innovations";

import Quality from './pages/Quality/Quality';

import Approach from "./pages/approach/Approach";

import International from "./pages/international/International";

import Career from "./pages/career/Carrer";

import Company from "./pages/Company/Company";
import HomeCompany from "./pages/homeSite/HomeCompany";

import Group from "./pages/Group/Group";
import School from "./pages/School/School";
import HomeGroup from "./pages/homeSite/HomeGroup";

import CodePage from "./pages/Code/CodePage";
import Jobs from "./pages/Jobs/Jobs";
import Rse from "./pages/Rse/Rse";
import Team from "./pages/Team/Team";


export default function GlobalRouter() {

  const [ context, dispatch ] = useGlobalContext();

  const [ displayNavBar , setDisplayNavBar ] = useState(false);
  const [ displaySiteNav , setDisplaySiteNav ] = useState(false);
  const [ displaySelectLang , setDisplaySelectLang ] = useState(false);
  const [ lang , setLang ] = useState("fr");
  const [ theme , setTheme ] = useState("main");
  const [ activePage , setActivePage ] = useState();
  const [ windowWidth , setWindowWidth ] = useState(window.innerWidth);

  var token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    getCompaniesAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
    }
  // eslint-disable-next-line 
  }, [context.auth.isAuth]);

  useEffect(() => {
    if(token) {
      getUser();
    }
  // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (context.companies.company) {
      setTheme(context.companies.company.className)
    }
  },[context.companies.company]);
  
  window.addEventListener('resize', function(event){
    setWindowWidth(window.innerWidth);
  });

  function logout() {
    history.push("/");
    setDisplaySiteNav(false);
    setTheme("main")
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  function requireRight(value) {
      if (context.auth.userAuth && context.auth.userAuth.rights.length && context.auth.userAuth.rights.find(item => item === value) ) {
        return true
      } else {
        return false;
      }
  }

  return (
    <>
    {windowWidth > 1000 ?
    <Router history={history}
      >

      {displayNavBar && <Nav
        setLang={setLang}
        displaySelectLang={displaySelectLang}
        lang={lang}
        activePage={activePage}
        theme={theme}
        requireRight={requireRight}
        logout={logout}
        />}

      {displaySiteNav && <SiteNav
        setLang={setLang}
        displaySelectLang={displaySelectLang}
        lang={lang}
        dispatch={dispatch}
        requireRight={requireRight}
        companyId={context.companies.company && context.companies.company._id ? context.companies.company._id : null }
        context={context}
        activePage={activePage}
        theme={theme}
        />
      }

      <Switch>

        <Route exact path="/home" render={
          (props) => <HomePage
            setTheme={setTheme}
            requireRight={requireRight}
            lang={lang}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
        }/>

        <Route exact path="/" render={
          (props) => <LoginPage
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
        }/>

        <Route exact path="/reset-password/:token" render={
          (props) => <ResetPasswordPage
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
          }/>

        <Route exact path="/career/:companyId/:lang" render={
          (props) => <Career
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            requireRight={requireRight}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/team/:companyId/:lang" render={
          (props) => <Team
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            requireRight={requireRight}
            theme={theme}
            {...props}
            />
        }/>
        
        <Route exact path="/group/:companyId/:lang" render={
          (props) => <Group
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>
        <Route exact path="/school/:companyId/:lang" render={
          (props) => <School
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route path="/home-group/:companyId/:lang" render={
          (props) => <HomeGroup
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/company/:companyId/:lang" render={
          (props) => <Company
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/home-company/:companyId/:lang" render={
          (props) => <HomeCompany
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/jobs/:companyId/:lang" render={
          (props) => <Jobs
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/quality/:companyId/:lang" render={
          (props) => <Quality
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/innovations/:companyId/:lang" render={
          (props) => <Innovations
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/international/:companyId/:lang" render={
          (props) => <International
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/approach/:companyId/:lang" render={
          (props) => <Approach
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/rse/:companyId/:lang" render={
          (props) => <Rse
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/domains-list/:companyId/:lang" render={
          (props) => <DomainsList
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/edit-domain/:companyId/:domainId" render={
          (props) => <EditDomain
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySelectLang={setDisplaySelectLang}
            setDisplaySiteNav={setDisplaySiteNav}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/edit-sub-domain/:companyId/:domainId/:subDomainId" render={
          (props) => <EditSubDomain
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySelectLang={setDisplaySelectLang}
            setDisplaySiteNav={setDisplaySiteNav}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>


        <Route exact path="/missions/:companyId/:lang" render={
          (props) => <Missions
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySelectLang={setDisplaySelectLang}
            setDisplaySiteNav={setDisplaySiteNav}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/projects-list/:companyId/:lang" render={
          (props) => <ProjectsList
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/edit-project/:companyId/:projectId" render={
          (props) => <EditProject
            setDisplayNavBar={setDisplayNavBar}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/agencies-list/:companyId/:lang" render={
          (props) => <AgenciesList
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            requireRight={requireRight}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/edit-agency/:companyId/:agencyId" render={
          (props) => <EditAgency
            setDisplayNavBar={setDisplayNavBar}
            setActivePage={setActivePage}
            requireRight={requireRight}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/articles-list/:companyId/:lang" render={
          (props) => <ArticlesList
            setDisplayNavBar={setDisplayNavBar}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplaySelectLang={setDisplaySelectLang}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/edit-article/:companyId/:articleId" render={
          (props) => <EditArticle
            setDisplayNavBar={setDisplayNavBar}
            setActivePage={setActivePage}
            lang={lang}
            theme={theme}
            {...props}
            />
        }/>

        <Route exact path="/users/:companyId" render={
          (props) => <UsersPage
            theme={theme}
            lang={lang}
            setActivePage={setActivePage}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
        }/>

        <Route exact path="/code" render={
          (props) => <CodePage
            theme={theme}
            lang={lang}
            setActivePage={setActivePage}
            setDisplaySiteNav={setDisplaySiteNav}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
        }/>

        <Route exact path="/medias/:companyId" render={
          (props) => <MediasPage
            theme={theme}
            lang={lang}
            setDisplaySiteNav={setDisplaySiteNav}
            setActivePage={setActivePage}
            setDisplayNavBar={setDisplayNavBar}
            {...props}
            />
        }/>

      </Switch>
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
    </Router>
    :
      <div className="mobile">
        <h2>
          Le backoffice n'est pas disponible sur mobile
        </h2>
      </div>
    }
    </>
  );
}
