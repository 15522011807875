import { getData , putData , deleteData , postData } from './index';

import {
  GET_SUB_DOMAINS,
  POST_SUB_DOMAIN,
  GET_SUB_DOMAIN,
  DELETE_SUB_DOMAIN,
  UPDATE_SUB_DOMAIN,
  ERROR_SUB_DOMAINS,
} from "./types"

export async function getSubDomainsAction(dispatch , lang) {
  let url = "/domain/sub/lang/" + lang;
  var domains;

  await getData(ERROR_SUB_DOMAINS , url, dispatch , true ).then((response)=>{
    domains = response.subdomains
  });

  dispatch({
    type : GET_SUB_DOMAINS,
    payload : domains
  });

};

export async function createSubDomainAction(dispatch , params ) {
  let url = "/domain/sub"
  var domain;

  await postData(POST_SUB_DOMAIN , ERROR_SUB_DOMAINS , url, dispatch , params, true ).then((response)=>{
    domain = response
  });


  dispatch({
    type : POST_SUB_DOMAIN,
    payload : domain
  });

}

export async function getSubDomainAction(dispatch , id ) {
  let url = "/domain/sub/" + id
  var subdomain;

  await getData(ERROR_SUB_DOMAINS, url, dispatch , true ).then((response)=>{
    subdomain = response.subdomain
  });

  dispatch({
    type : GET_SUB_DOMAIN,
    payload : subdomain
  });

}

export async function updateSubDomainAction(dispatch , param ) {
  let url = "/domain/sub/" + param._id
  var domain;

  await putData(UPDATE_SUB_DOMAIN, ERROR_SUB_DOMAINS ,  url, dispatch, param , true ).then((response)=>{
    domain = response
  });

  dispatch({
    type : UPDATE_SUB_DOMAIN,
    payload : domain
  });

}

export async function deleteSubDomainAction(dispatch , id ) {
  let url = "/domain/sub/" + id

  await deleteData(ERROR_SUB_DOMAINS, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le sous-domaine a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_SUB_DOMAIN,
    payload : id
  });

}