import React , { useEffect , useState , useMemo } from 'react';

import { useGlobalContext } from '../../context/GlobalContext';

import { API_URL } from '../../../actions';

export default function Picture( { picture , size } ) {

  const [ path , setPath ] = useState(picture && picture._id ? `${API_URL}/file/picture/${picture._id}/${size ? size : 600}/0` : null )
  
  const [ context ] = useGlobalContext();

  useEffect(() => {
    if(picture && context.medias.mediasList) {
      let approachFound = context.medias.mediasList.find(item => item._id === picture );
      if (approachFound) {
        setPath(API_URL + '/' + approachFound.path );
      } 
    }
    if (!picture) {
      setPath();
    }
    // eslint-disable-next-line
  }, [picture])

  return useMemo(() => (
    <>
      {path && <img src={path} alt="approach" className="img-large"/>}
    </>
  ), [path]);
}
