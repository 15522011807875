import { saveAs } from 'file-saver';
import axios from 'axios';
import { getData , postData , putData ,  deleteData, API_URL } from './index';

import { sortBy } from '../utils/utils';

import {
  GET_PROJECTS,
  CREATE_PROJECT,
  MESSAGE_PROJECTS,
  GET_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  ERROR_PROJECT,
  GET_PROJECTS_LIST,
  GET_COMPANIES_PUBLIC
} from "./types"


export async function getCompaniesPublicAction(dispatch) {
  let url = `/company/public`;
  var companies;

  await getData(ERROR_PROJECT, url, dispatch , true ).then((response)=>{
    companies = response.companies
  });

  companies.forEach(company => {
    if(company.value === "Keran") {
      company.icon = "K";
      company.className = "K";
    }
    if(company.value === "Sce") {
      company.icon = "SCE";
      company.className = "SCE";
    } 
    if(company.value === "Créocéan") {
      company.icon = "C";
      company.className = "C";
    } 
    if(company.value === "Naomis") {
      company.icon = "N";
      company.className = "N";
    }
    if(company.value === "Groupe Huit") {
      company.icon = "GH";
      company.className = "GH";
    }
    if(company.value === "S3d"){
      company.icon = "S3D"
      company.className = "S3D"
    }
    if(company.value === "Ateliers UP+") {
      company.icon = "UP+"
      company.className = "UP"
    }
  })

  dispatch({
    type : GET_COMPANIES_PUBLIC,
    payload : companies
  });

};

export async function getProjectsListsAction(dispatch , lang , id ) {
  let url = `/project/list/lang/${lang}/${id}`;
  var lists;

  await getData(ERROR_PROJECT, url, dispatch , true ).then((response)=>{
    lists = response
  });

  lists.contributors = lists.contributors?.map((company) => {
    if(company.value === "Keran") {
      company.icon = "K";
      company.className = "K";
    }
    if(company.value === "Sce") {
      company.icon = "SCE";
      company.className = "SCE";
    } 
    if(company.value === "Créocéan") {
      company.icon = "C";
      company.className = "C";
    }
    if(company.value === "Créocéan Océan Indien") {
      company.icon = "COI";
      company.className = "CI";
    } 
    if(company.value === "Naomis") {
      company.icon = "N";
      company.className = "N";
    }
    if(company.value === "Groupe Huit") {
      company.icon = "GH";
      company.className = "GH";
    }
    if(company.value === "S3d"){
      company.icon = "S3D"
      company.className = "S3D"
    }
    if(company.value === "Ateliers UP+") {
      company.icon = "UP+"
      company.className = "UP"
    }
    company.name = company.value;
    company._id = company.id;
    return company;
  })

  dispatch({
    type : GET_PROJECTS_LIST,
    payload : lists
  });

};

export async function getProjectsAction(dispatch , params ) {
  let url = "/project/lang/" + params.lang;
  var projects;

  await getData(ERROR_PROJECT, url, dispatch , true ).then((response)=>{
    projects = response.projects
  });

  dispatch({
    type : GET_PROJECTS,
    payload : sortBy(projects , "updatedAt")
  });

};

export async function createProjectAction(dispatch , params ) {
  let url = "/project"
  var project;

  await postData(CREATE_PROJECT , ERROR_PROJECT , url, dispatch , params, true ).then((response)=>{
    project = response
  });

  dispatch({
    type : GET_PROJECT,
    payload : project.project
  });

  dispatch({
    type : MESSAGE_PROJECTS,
    payload : "created"
  });

}

export async function getProjectAction(dispatch , id ) {
  let url = "/project/" + id
  var project;

  await getData(ERROR_PROJECT, url, dispatch , true ).then((response)=>{
    project = response.project
  });

  dispatch({
    type : GET_PROJECT,
    payload : project
  });

}

export async function updateProjectAction(dispatch , param ) {
  let url = "/project/" + param._id
  var project;

  await putData(UPDATE_PROJECT, ERROR_PROJECT ,  url, dispatch, param , true ).then((response)=>{
    project = response.project
  });

  dispatch({
    type : UPDATE_PROJECT,
    payload : project
  });

  dispatch({
    type: 'SET_TOAST',
    payload: {
      message: "Le projet a bien été enregistré",
      type: "success"
    }
  });

}

export async function deleteProjectAction(dispatch , id ) {
  let url = "/project/" + id

  await deleteData(ERROR_PROJECT, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le projet a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_PROJECT,
    payload : id
  });

}

export async function exportProjectsAction(lang ) {
  let fileUrl = API_URL + `/project/export/${lang}`;
  let token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json', // Adjust content type if necessary
    Authorization: token // Add authorization token if required
    // Add any other necessary headers
  };

  try {
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
      headers: headers,
    });

    const disposition = response.headers['content-disposition'];
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'projects.xlsx';

    const blob = new Blob([response.data]);

    // Trigger the file download using FileSaver.js
    saveAs(blob, filename);
  } catch (error) {
    console.error('Error fetching the file:', error);
    // Handle errors, e.g., display an error message
  }
}
