import { getData , postData , putData ,  deleteData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_TALENTS,
  POST_TALENT,
  DELETE_TALENT,
  UPDATE_TALENT,
  ERROR_TALENTS,
} from "./types"

export async function getTalentsAction(dispatch , lang ) {
  let url = "/talent/lang/" + lang;
  var talents = [];

  await getData(ERROR_TALENTS, url, dispatch , true ).then((response)=>{
    talents = response.talents
  });

  dispatch({
    type : GET_TALENTS,
    payload : sortByUpdateDate(talents)
  });

};

export async function createTalentAction(dispatch , params ) {
  let url = "/talent"
  var talent;

  await postData(POST_TALENT , ERROR_TALENTS , url, dispatch , params, true ).then((response)=>{
    talent = response
  });

  dispatch({
    type : POST_TALENT,
    payload : talent
  });

}


export async function updateTalentAction(dispatch , param ) {
  let url = "/talent/" + param._id
  var talent;

  await putData(UPDATE_TALENT, ERROR_TALENTS ,  url, dispatch, param , true ).then((response)=>{
    talent = response
  });

  dispatch({
    type : UPDATE_TALENT,
    payload : talent
  });

}

export async function deleteTalentAction(dispatch , id ) {
  let url = "/talent/" + id

  await deleteData(ERROR_TALENTS, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le talent a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_TALENT,
    payload : id
  });

}