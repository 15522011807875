import React, { useEffect , useCallback , useState } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import Value from './Value';
import EditKeysFigures from '../../partials/EditKeysFigures';
import Loader from '../../partials/Loader';
import TextEditor from '../../partials/TextEditor';

import '../../../styles/pages-sites.scss';
import Picture from '../medias/Picture';
import EditMapItems from '../../partials/EditMapItems';

export default function Group({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'group';

  const { register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const [ home , setHome ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);
  const [ keyFigures , setKeyFigures ] = useState()

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("group");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setHome();
      setIsMounted(false);
      dispatch({
        type:'RESET_PAGE',
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    setHome();
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      setHome({...context.theme.page});
      if (context.theme.page.keyFigures) setKeyFigures(context.theme.page.keyFigures);
      setIsMounted(true);
      setIsSaved(true);
    } else {
      setHome();
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  // useEffect(() => {
  //   if (home && context.theme.page) {
  //    if (context.theme.page && JSON.stringify(home) === JSON.stringify(context.theme.page)) {
  //       setIsSaved(true);
  //     } else {
  //       setIsSaved(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[home]);

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);
      setIsSaved(true);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function handleChangeBloc(val , i) {
    var values = [...home.values];
    values[i] = val;
    setIsSaved(false);
    setHome({...home , values: values })
    submitGroup({...home , values })
  }

  function deleteBloc(index) {
    var result = home.values
    setIsSaved(false);
    result = result.filter((item, i) => i !== index )
    setHome({...home , values: result});
  }

  function submitMedia(value) {
    let homeData = {...home }
    homeData = Object.assign({} , homeData );
    switch (mediaKey) { 
  
      case "international":
          homeData.international.picture = value[0]
        break;

      case "innovation":
        homeData.innovation.picture = value[0]
        break;

      case "approach":
        homeData.approach.picture = value[0]
        break;
      
      case "president":
        homeData.testimonial.picture = value[0]
        break;
      
      case "rse":
          homeData.rse.picture = value[0]
        break;
    
      default:
        break;
    }
    setHome(homeData);
    setIsSaved(false);
    setMediaKey();
  };

  function handleChange( value , obj) {
    var data = Object.assign({} , {...home}  );
    if (data[obj]) {
      data[obj] = value;
      setIsSaved(false);
    }
    setHome(data);
  };

  function handleChangeKey( value , obj , key ) {
    let data = {...home}
    data = Object.assign({} , data );
    if (data[obj] && data[obj][key]) {
      data[obj][key] = value;
      setIsSaved(false);
    }
    setHome(data);
  };

  function handleChangeKeyFigures( value ) {
    setKeyFigures(value);
    setIsSaved(false);
  };

  function openSelectPresident() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('president');
    setMediaType('image');
    if (home && home.testimonial && home.testimonial.picture && home.testimonial.picture._id) {
      var pictureArray = [];
      pictureArray.push(home.testimonial.picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectRse() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('rse');
    setMediaType('image');
    if (home && home.rse && home.rse.picture && home.rse.picture._id) {
      var pictureArray = [];
      pictureArray.push(home.rse.picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (home && home[imgkey] && home[imgkey].picture && home[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(home[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  const submitGroup = (values)  => {
  
    setPublishIsLoading(true);
    var groupData = {...home, ...values};
    var obj = {};
    for (const [key, value] of Object.entries(groupData)) {
        // if (JSON.stringify(context.theme.page[key]) !== JSON.stringify(value)) {
          obj[`${page}.${key}`] = value;
        // } if (key === "values") {
        //   obj[`${page}.${key}`] = home.values;
        // }
        if (key === "keyFigures") {
          obj[`${page}.${key}`] = keyFigures;
        }
    }

    updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });

  };

  function renderPresidentImg() {
    var imgFound;
    if(home && home.testimonial && home.testimonial.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === home.testimonial.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="home" />
      } else if (home.testimonial.picture.path) {
        return <img src={`${API_URL}/file/picture/${home.testimonial.picture._id}/350/0}`} alt="home" />
      }
    }
  }

  function renderImgLarge(key) {
    var approachFound;
    if(home && home[key] && home[key].picture && context.medias.mediasList) {
      approachFound = context.medias.mediasList.find(item => item._id === home[key].picture );
      if (approachFound) {
        return <img src={API_URL + '/' + approachFound.path} alt="approach" className="img-large" />
      } else if (home[key].picture.path) {
        return <img src={`${API_URL}/file/picture/${home[key].picture._id}/600/0}`} alt="approach" className="img-large"/>
      }
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  <h2>1<sup>er</sup> Paragraphe</h2>
                </div>

                <label htmlFor="text1.title">Titre</label>
                <textarea
                  name="text1.title"
                  onChange={(e) => handleChangeKey( e.target.value , 'text1' , 'title' )}
                  defaultValue={home && home.text1 && home.text1.title ? home.text1.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.text1 && errors.text1.title && errors.text1.title.message}</p>

                <label>Paragraphe</label>
                <TextEditor
                  value={home && home.text1 && home.text1.text}
                  onChange={(newContent) => handleChangeKey( newContent , 'text1' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <div className="section"></div>

                <div className="title">
                  <h2>Chiffres clés</h2>
                </div>
                <EditKeysFigures keyFigures={keyFigures} setRemoveAction={setRemoveAction} handleChange={(val) => handleChangeKeyFigures(val)} theme={theme}/>

                <div className="section"></div>

                <div className="title">
                  <h2>Notre vision</h2>
                </div>

                <label htmlFor="text2.title">Titre</label>
                <input
                  name="text2.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'text2' , 'title' )}
                  defaultValue={home && home.text2 && home.text2.title ? home.text2.title  : undefined }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={home && home.text2 && home.text2.text}
                    onChange={newContent => handleChangeKey( newContent , 'text2' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <div className="section"></div>

                <div className="title">
                  <h2>Notre organisation</h2>
                </div>

                <label htmlFor="organisation.title">Titre</label>
                <input
                  name="organisation.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'organisation' , 'title' )}
                  defaultValue={home && home.organisation && home.organisation.title ? home.organisation.title  : undefined }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={home && home?.organisation?.text}
                    onChange={newContent => handleChangeKey( newContent , 'organisation' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <input id="withMap"
                  onChange={(e) => handleChangeKey(e.target.checked, 'organisation' , 'withMap' )}
                  type="checkbox" className="switch" checked={home?.organisation?.withMap}
                  style={{ height: 20 }}
                  />
         
                <label htmlFor="withMap">{home?.organisation?.withMap ? <span>Carte affiché</span> : <span>Ne pas afficher la carte</span> }</label>

                {home?.organisation?.withMap &&
                  <>
                    <div className="title">
                      <label>Métiers</label>
                    </div>
                    <EditMapItems
                      items={home?.organisation?.mapContent?.jobs || []}
                      setRemoveAction={setRemoveAction}
                      aad='Ajouter un métier'
                      handleChange={ (itemsList) => handleChange({
                        ...home?.organisation,
                        mapContent: home?.organisation?.mapContent ? {
                          ...home?.organisation.mapContent,
                          jobs: itemsList,
                        }: {
                          jobs: itemsList,
                        }
                      }, "organisation" )}
                      theme={theme}
                    />
                    <div className="title">
                      <label>Régions</label>
                    </div>
                    <EditMapItems
                      items={home?.organisation?.mapContent?.regions || []}
                      setRemoveAction={setRemoveAction}
                      aad='Ajouter une région'
                      handleChange={ (itemsList) => handleChange({
                        ...home?.organisation,
                        mapContent: home?.organisation?.mapContent ? {
                          ...home?.organisation.mapContent,
                          regions: itemsList,
                        }: {
                          regions: itemsList,
                        }
                      }, "organisation" )}
                      theme={theme}
                    />
                  </>

                }

                <div className="section"></div>

                <div className="title">
                  <h2>Entreprise à mission</h2>
                </div>

                <label htmlFor="rse.title">Titre</label>
                <input
                  name="rse.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'rse' , 'title' )}
                  defaultValue={home && home.rse && home.rse.title ? home.rse.title  : undefined }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={home && home.rse && home.rse.text}
                  onChange={newContent => handleChangeKey( newContent , 'rse' , 'text' )}                />

                <label htmlFor="rse.button">Bouton</label>
                <input
                  name="rse.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'rse' , 'button' )}
                  defaultValue={home && home.rse && home.rse.button ? home.rse.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                {renderImgLarge('rse')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectRse()}
                    >
                    Parcourir
                  </button>

                  {home && home.rse && home.rse.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChangeKey( null , 'rse' , 'picture' ) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>
                
                <div className="title">
                  <h2>Approche globale</h2>
                </div>

                <label htmlFor="approach.title">Titre</label>
                <input
                  name="approach.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'approach' , 'title' )}
                  defaultValue={home && home.approach && home.approach.title ? home.approach.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.approach && home.approach.text ? home.approach.text  : "" }
                    onChange={newContent => handleChangeKey( newContent , 'approach' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <label htmlFor="approach.button">Bouton</label>
                <input
                  name="approach.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'approach' , 'button' )}
                  defaultValue={home && home.approach && home.approach.button ? home.approach.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                <Picture picture={home.approach && home.approach.picture}/>
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("approach")}
                    >
                    Parcourir
                  </button>

                  {home && home.approach && home.approach.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChangeKey( null , 'approach' , 'picture' )})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Innovations</h2>
                </div>

                <label htmlFor="innovation.title">Titre</label>
                <input
                  name="innovation.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'innovation' , 'title' )}
                  defaultValue={home && home.innovation && home.innovation.title ? home.innovation.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.innovation && home.innovation.text}
                  onChange={newContent => handleChangeKey( newContent , 'innovation' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <label htmlFor="innovation.button">Bouton</label>
                <input
                  name="innovation.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'innovation' , 'button' )}
                  defaultValue={home && home.innovation && home.innovation.button ? home.innovation.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                <Picture picture={home.innovation && home.innovation.picture}/>
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("innovation")}
                    >
                    Parcourir
                  </button>

                  {home && home.innovation && home.innovation.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => setHome({...home, innovation: { ...home.innovation , picture: null }}) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>International</h2>
                </div>

                <label htmlFor="international.title">Titre</label>
                <input
                  name="international.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'international' , 'title' )}
                  defaultValue={home && home.international && home.international.title ? home.international.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.international && home.international.text}
                  onChange={newContent => handleChangeKey( newContent , 'international' , 'text' )}
                />

                <label htmlFor="international.button">Bouton</label>
                <input
                  name="international.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'international' , 'button' )}
                  defaultValue={home && home.international && home.international.button ? home.international.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                {renderImgLarge('international')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("international")}
                    >
                    Parcourir
                  </button>

                  {home && home.international && home.international.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChangeKey( null , 'international' , 'picture' ) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Valeurs</h2>
                </div>

                <label htmlFor="value.title">Titre</label>
                <input
                  name="value.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'value' , 'title' )}
                  defaultValue={home && home.value && home.value.title ? home.value.title  : undefined }
                />

                <label>paragraphe</label>
                <TextEditor
                  value={home && home.value && home.value.text}
                  onChange={newContent => handleChangeKey( newContent , 'value' , 'text' )}
                />

                {home.values && home.values.map((value, i ) => (<Value
                    key={'value' + i }
                    propValue={value}
                    companyId={param.companyId}
                    lang={lang}
                    handleChangeValue={(val) => handleChangeBloc(val , i)}
                    context={context}
                    setRemoveAction={setRemoveAction}
                    dispatch={dispatch}
                    deleteBloc={() => deleteBloc(i)}
                    index={i} />
                  ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 20}}
                    onClick={() => handleChange([...home.values, { title: '', text: ''}], 'values') }
                    type="button"
                    >
                    Ajouter une valeur
                  </button>
                </div>

              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    <div className="loader">
                      <Loader />
                    </div>
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      onClick={() => submitGroup()}
                      >
                      Publier
                    </button>
                  }
                  
                </div>
{/* 
                <label htmlFor="testimonial.title">Titre mot du président</label>
                <input
                  name="testimonial.title"
                  onChange={(e) => handleChangeKey( e.target.value , 'testimonial' , 'title' )}
                  defaultValue={home && home.testimonial && home.testimonial.title ? home.testimonial.title : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.testimonial && errors.testimonial.title && errors.testimonial.title.message}</p> */}

                <label htmlFor="testimonial.text">Raison d’être</label>
                <textarea
                  style={{ minHeight: 120 }}
                  name="testimonial.text"
                  onChange={(e) => handleChangeKey( e.target.value , 'testimonial' , 'text' )}
                  defaultValue={home && home.testimonial && home.testimonial.text ? home.testimonial.text : '' }
                />
                <p className="error">{errors && errors.testimonial && errors.testimonial.text && errors.text.message}</p>
              
                <label htmlFor="testimonial.text">Introduction</label>
                <textarea
                  style={{ minHeight: 120 }}
                  name="testimonial.introduction"
                  onChange={(e) => handleChangeKey( e.target.value , 'testimonial' , 'introduction' )}
                  defaultValue={home && home.testimonial && home.testimonial.introduction ? home.testimonial.introduction : '' }
                />
                <p className="error">{errors && errors.testimonial && errors.testimonial.introduction }</p>

                <label>Photo du mot du président <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px , 72 dpi</span></label>
                {renderPresidentImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPresident()}
                    >
                    Parcourir
                  </button>

                  {home && home.testimonial && home.testimonial.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => setHome({...home, testimonial: { ...home.testimonial , picture: null }})})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label htmlFor="testimonial.legend">Légende</label>
                <input
                  name="testimonial.legend"
                  onChange={(e) => handleChangeKey( e.target.value , 'testimonial' , 'legend' )}
                  defaultValue={home && home.testimonial && home.testimonial.legend ? home.testimonial.legend : undefined }
                />
                <p className="error">{errors && errors.testimonial && errors.testimonial.legend && errors.legend.message}</p>

                <label htmlFor="testimonial.signature">Signature</label>
                <input
                  name="testimonial.signature"
                  onChange={(e) => handleChangeKey( e.target.value , 'testimonial' , 'signature' )}
                  defaultValue={home && home.testimonial && home.testimonial.signature ? home.testimonial.signature : undefined }
                />
                <p className="error">{errors && errors.testimonial && errors.testimonial.signature && errors.signature.message}</p>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
    <PromptRouter when={!isSaved} />
    {isMounted && home && <div id="innovation" className={`page-container site ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="flex">

            <div className="title">
              <h1>Edition de la page groupe ({lang})</h1>
              <div className="bar primary"></div>
            </div>

          </div>

        </div>

        <div className="list-container">
          {home && renderEdit()}
        </div>

      </div>
    </div>}
    <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
