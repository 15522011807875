import React, { useRef , useState , useEffect } from 'react';
import { API_URL } from '../../actions';

import edit from '../../assets/images/icons/edit.svg';
import remove from '../../assets/images/icons/remove.svg';
import close from '../../assets/images/icons/close.svg';
import save from '../../assets/images/icons/save.svg';
import BlocText from './BlocText';
import TextEditor from './TextEditor';
import { useGlobalContext } from '../context/GlobalContext';


export default function Page({
  pageValue,
  pages, setPages , isUpdated , index , isFile = false, isSubTitle = false, theme , openSelectPicture , setRemoveAction , isCompanyPage , withThumbnail, companyId }) {

  const inputTitle = useRef(null);

  const context = useGlobalContext()[0]
  const [ page , setPage ] = useState(pageValue);
  const [ isEdit , setIsEdit ] = useState(false);
  const [ text , setText ] = useState(pageValue.text ? pageValue.text : "");
  const file = context?.medias?.mediasList?.find(item => item._id === page?.file || item._id === page?.file?._id);

  useEffect(() => {
    if (pageValue && !pageValue._id) {
      setIsEdit(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPage({...pageValue });
  }, [pageValue, isUpdated]);

  function savePage() {
    var editPages = pages ? [...pages] : [] ;
    editPages[index] = page;
    if (text) editPages[index].text = text;
    setPages([...editPages]);
  }

  function closePage() {
    savePage();
    setIsEdit(false);
  }

  function handleChangeBloc(val , i) {
    var blocs = [...page.blocs];
    blocs[i] = val;
    setPage({...page , blocs: blocs })
  }

  function addBloc() {
    var addBlocs = page.blocs ? [...page.blocs] : [];
    addBlocs.push({ title: "" , text: "" });
    setPage({...page , blocs: addBlocs })
  }

  function deleteBloc(blocIndex) {
    var deleteBloc = [...page.blocs];
    const updatedPage = {...page , blocs: deleteBloc.filter((item , i) => i !== blocIndex)}
    setPage(updatedPage)
  }


  function renderPageCover() {
    var imgFound;
    if(context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === pageValue.cover || item._id === page.picture?._id || item._id === page.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="page" className={isEdit ? "img-large" : "circle" }/>
      } else if (page.cover && page.cover.path) {
        return <img src={API_URL + '/' + page.cover.path} alt="page" className={isEdit ? "img-large" : "circle" } />
      }
    }
  }

  function renderPagePicture() {
    var imgFound;
    if(page && page.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === page.picture?._id || item._id === page.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="page" className={isEdit ? "img-small" : "circle" }/>
      } else if (page.cover && page.cover.path) {
        return <img src={API_URL + '/' + page.cover.path} alt="page" className={isEdit ? "img-small" : "circle" } />
      }
    }
  }

  function renderEdit() {
    return (
      <>
        <div className="btn-close-row">
          <button
            type="button"
            style={{ width: 30 }}
            onClick={() => closePage()}
            >
            <img src={save} alt="edit" />
          </button>
          <button
            type="button"
            onClick={() => closePage()}
            >
            <img src={close} alt="edit" />
          </button>
        </div>
        <label htmlFor={`page${index}`} >{isSubTitle ? 'Nom prénom' :'Titre de la page'}</label>
        <input
          name={`page${index}`}
          style={{ marginBottom: 10 }}
          ref={inputTitle}
          onChange={(e) => setPage({...page, title: e.target.value })}
          defaultValue={page && page.title ? page.title : ""}
          />

        {!isSubTitle &&
          <div>
            <input id="titleIsWhite"
              onChange={() => setPage({...page , titleIsWhite: !page.titleIsWhite })}
              type="checkbox" className="switch" checked={!page.titleIsWhite}
              />
            <label htmlFor="titleIsWhite">{page.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>
          </div>
        } 

        {isSubTitle &&
          <>
            <label htmlFor={`page${index}.subTitle`} >Fonction</label>
            <input
              name={`page${index}.subTitle`}
              style={{ marginBottom: 10 }}
              ref={inputTitle}
              onChange={(e) => setPage({...page, subtitle: e.target.value })}
              defaultValue={page && page.subtitle ? page.subtitle : ""}
              />
          </>
        }
        
        <label htmlFor="pos">Position</label>
        <input
          style={{ marginBottom : 10 }}
          defaultValue={page.pos ? page.pos : null }
          onChange={(e) => setPage({...page , pos: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null})}
          name="pos"
        />

       {!isCompanyPage &&
        <>
          <label>{isSubTitle ? 'Paragraphe' : 'Introduction'}</label>
          <TextEditor
            value={text}
            onChange={newContent => setText(newContent)} 
          />
        </>
        }

        {withThumbnail && 
          <>
            <label>Photo de miniature de la page <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px</span></label>

            <div className="btn-container">
              
              <button
                type="button"
                className={`btn grey`}
                style={{ marginRight : 20 }}
                onClick={() => openSelectPicture(index , "pagePicture")}
                >
                Parcourir
              </button>

              {page && page.picture &&
                <button
                  type="button" 
                  className={`btn grey bold`}
                  onClick={() => setRemoveAction({ function: () => setPage({...page, picture: null }) } )}
                  >
                  Supprimer la photo
                </button>
              }
            </div>

            <div style={{ marginBottom: 30 }}>{renderPagePicture()}</div>
          </>
        }
        {!isFile ?
          <>
            <label>{isSubTitle ? 'Photo': 'Photo de couverture de la page' }<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 350 px</span></label>

            <div className="btn-container">

              <button
                type="button"
                className={`btn grey`}
                style={{ marginRight : 20 }}
                onClick={() => openSelectPicture(index , "pageCover")}
                >
                Parcourir
              </button>
              {page && (page?.cover || page?.picture) &&
                <button
                  type="button" 
                  className={`btn grey bold`}
                  onClick={() => setRemoveAction({ function: () => setPage({...page, cover: null , picture: null }) } )}
                  >
                  Supprimer la photo
                </button>
              }
            </div>
            <div style={{ marginBottom: 30 }} >{renderPageCover()}</div>
          </>
          :
          <>
            <label>Fichier <span className={`${theme}-color required`}>*</span><br/></label>

            <div className="btn-container" style={{ marginTop : 10 }}>
              <button
                type="button"
                className={`btn grey`}
                style={{ marginRight : 20 }}
                onClick={() => openSelectPicture(index , "file")}
                >
                Parcourir
              </button>
    
              {page && page.file &&
                <button
                  type="button" 
                  className={`btn grey bold`}
                  onClick={() => setRemoveAction({ function: () => setPage({...page, file: null }) } )}
                  >
                  Supprimer le fichier
                </button>
              }
            </div>
            {file?.path &&
              <div
                style={{ 
                  display: 'flex',
                }}
              >
                <a
                  className={`btn primary`}
                  href={API_URL + '/' + file.path}
                  target='_blank'
                  rel="noopener noreferrer"
                  style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}
                >
                  {file.title}
                </a>
              </div>
            }
          </>
        }
        
        {page.blocs && page.blocs.map((bloc , i ) => (<BlocText
          key={'blocktext' + i }
          setBlockText={(val) => handleChangeBloc(val , i)}
          deleteBloc={(val) => setRemoveAction({ function: () => deleteBloc(i)})}
          blockText={bloc}
          index={i} />
        ))}

        {!isSubTitle &&
          <div className="btn-container" >
            <button
              style={{ marginTop: 20 }}
              className={`btn primary ${theme}-bg`}
              onClick={() => addBloc() }
              type="button"
              >
              Ajouter un paragraphe
            </button>
          </div>
        }

      </>
    )
  }

  function renderRow() {
    return (
      <>
        <div className="name">
          {!isFile ?
            <>
            {page && (page?.cover || page?.picture) ? renderPageCover() :  <div className="circle"></div>}
            </>
            :
            <div style={{ marginLeft: 20 }}></div>
          }
          {page.pos !== 0 && <span style={{ marginRight: 10 }}>{page.pos} {" "}</span>}
          <p className="bold">{" "}{page && page.title}</p>
        </div>
        <div className="actions">

        <button
          onClick={() => setIsEdit(true)}
          >
          <img src={edit} alt="edit" />
        </button>

        {pages && !isCompanyPage &&
          <button
            type="button" 
            onClick={() => setRemoveAction({ function: () => setPages(pages.filter((item ,i ) => i !== index ))} )}>
            <img src={remove} alt="remove"/>
          </button>
        } 

      </div>
      </>
    )
  }

  return (
    <div id={`page${index}`} className={`page ${!isEdit? "list-item row" : "edit" }`}>
      {page && isEdit ? renderEdit() : renderRow()}
    </div>
  )
}
