/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect , useCallback , useState } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import { clearMarkup } from '../../../utils/utils';
import SelectMedia from '../medias/SelectMedia'; 

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import BlocText from '../../partials/BlocText';
import EditKeysFigures from '../../partials/EditKeysFigures';
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
// import TextEditor from '../../partials/TextEditor';

import '../../../styles/pages-sites.scss';

export default function Rse({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'rse';

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const [ rse, setRse ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("rse");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setRse();
      setIsMounted(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    setRse();
    setIsMounted(false)
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      setRse({...context.theme.page });
      setIsMounted(true)
    } else {
      setRse();
      setIsMounted(false)
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

    useEffect(() => {
      if (rse && context.theme.page) {
        if (JSON.stringify(rse) === JSON.stringify(context.theme.page)) {
          setIsSaved(true);
        } else {
          setIsSaved(false);
        }
      }
    // eslint-disable-next-line
  },[rse])

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {
      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);



  function submitMedia(value) {
    switch (mediaKey) {
      
      case "picture":
        setRse({...rse, picture: value[0]})
        break;
    
      default:
        break;
    }
  };

  function handleChangeBloc(val , i) {
    var blocs = [...rse.blocs];
    blocs[i] = val;
    setRse({...rse, blocs: blocs })
  }

  function addBloc() {
    var addBlocs = [...rse.blocs];
    addBlocs.push({ title: "" , text: "" });
    setRse({...rse, blocs: addBlocs })
  }

  function deleteBloc(bloc) {
    var deleteBloc = [...rse.blocs];
    setRse({...rse, blocs: deleteBloc.filter(item => item !== bloc)})
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('picture');
    setMediaType('image');
    if (rse&& rse.picture) {
      var pictureArray = [];
      pictureArray.push(rse.picture);
      setSelectedMediaList(pictureArray)
    }
  }

  const submitrse= (values)  => {
    var rseData = {...values, ...rse};
    var obj = {};
    setPublishIsLoading(true);
    
    for (const [key, value] of Object.entries(rseData)) {
        obj[`${page}.${key}`] = value;
    }

    if (param.companyId === "5e9ec41b25ab1e65768e2ff0") {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );
    } else {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );
    }

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(rse&& rse.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === rse.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="rse" />
      } else if (rse.picture && rse.picture.path) {
        return <img src={API_URL + '/' + rse.picture.path} alt="rse" />
      }
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitrse)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  <h1 dangerouslySetInnerHTML={{ __html: rse && rse.title ? clearMarkup(rse.title) : "" }} ></h1>
                </div>

                <label htmlFor="title">Titre de la page</label>
                <input
                  name="title"
                  onChange={(e) => setRse({...rse, title: e.target.value})}
                  defaultValue={rse&& rse && rse.title ? rse.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <div>
                  <input id="isWhite"
                    onChange={() => setRse({...rse, titleIsWhite: !rse.titleIsWhite })}
                    type="checkbox" className="switch" checked={!rse.titleIsWhite}
                    />
                  <label htmlFor="isWhite">{rse.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Les paragraphes</h2>
                </div>

                {rse && rse.blocs && rse.blocs.map((bloc , i ) => (<BlocText
                  key={'blocktext' + i }
                  setBlockText={(val) => handleChangeBloc(val , i)}
                  deleteBloc={(val) => setRemoveAction({ function: () => deleteBloc(val)})}
                  nbBlocs={rse.blocs.length}
                  blockText={bloc}
                  index={i} />
                ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 40}}
                    onClick={() => addBloc() }
                    type="button"
                    >
                    Ajouter un paragraphe
                  </button>
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Chiffres clés</h2>
                </div>

                <EditKeysFigures keyFigures={rse.keyFigures} setRemoveAction={setRemoveAction} handleChange={ keyList => setRse({...rse, keyFigures: keyList })} theme={theme}/>

              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo de couverture<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 350 px</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {rse && rse.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({ function:() => setRse({...rse, picture: null })})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />     
      {isMounted && rse && <div id="rse" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page entreprise à mission ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
