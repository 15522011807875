import {
  GET_PROJECTS,
  MESSAGE_PROJECTS,
  GET_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  ERROR_PROJECT,
  GET_PROJECTS_LIST,
  GET_COMPANIES_PUBLIC
} from "../actions/types"

const initialState = {
  projectsList: null,
  project: null,
  error: null,
  message: null,
  lists: null,
  companies: null,
};


export default function projectsReducer(state = initialState, action) {

  var projectsList = state.projectsList;

  switch (action.type) {
    case GET_COMPANIES_PUBLIC:
      return {...state , companies: action.payload};

    case GET_PROJECTS_LIST:
      return {...state , lists: action.payload};

    case GET_PROJECTS:
      return {...state , projectsList: action.payload , project: null};

    case UPDATE_PROJECT:
        if (projectsList) {
          var index = projectsList.findIndex( project => project._id === action.payload._id);
          projectsList[index] = action.payload
        } else {
          projectsList = [];
          projectsList.push(action.payload);
        }

        return {
          ...state,
          projectsList: [...projectsList],
          project: action.payload
        };

    case GET_PROJECT:
      return {...state , project: action.payload };

    case DELETE_PROJECT:
      projectsList = state.projectsList.filter( project => project._id !== action.payload);
      return {...state , projectsList: projectsList };

    case MESSAGE_PROJECTS:
        return {...state , message: action.payload}

    case ERROR_PROJECT:
      return {...state , error: action.payload}

    default:
      return {...state};
  }
}