import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';
import { getDomainsAction } from '../../../actions/domainsActions';

import logo from '../../../assets/images/logo.svg';
import logoutIcon from '../../../assets/images/icons/logout.svg';
import code from '../../../assets/images/icons/code.svg'

import './nav.scss';

export default function Nav(props) {

  const [ context , dispatch ] = useGlobalContext();

  const [ userIsHover , setUserIsHover ] = useState(false);

  const { logout , displaySelectLang , activePage , theme , lang , setLang , requireRight } = props;

  const userAuth = context.auth.userAuth;

  var token = localStorage.getItem('token');

  useEffect(() => {
    if(context.auth.error === 401 ) {
      logout();
    }
    // eslint-disable-next-line 
  },[context.auth.error]);

  useEffect(() => {
    if(!token) {
      logout();
    }
  // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    getDomainsAction(dispatch , lang);
    // eslint-disable-next-line 
  }, [lang])

  function renderUserIcon() {
    if (userAuth && userAuth.profile) {
      return <span>{userAuth.profile.firstName.charAt(0).toUpperCase()}.{userAuth.profile.lastName.charAt(0).toUpperCase()}</span>
    }
  }

  function renderCompanyIcon() {
    if (context.companies.company) {
      var company = context.companies.company;
      return (
        <Link to={`/home-company/${company._id}/${lang}`}>
          <div className={`logo-company bold ${company.className}-bg`}>
            <span>{company.icon}</span>
          </div>
        </Link>
      )
    }
  }

  function renderNavLinks() {
    if (context.companies.company) {
      var company = context.companies.company;
      
      return (
        <>
          {requireRight("page") &&
            <Link
              to={company._id === "5e9ec41b25ab1e65768e2ff0" ? `/home-group/${company._id}/${lang}` : `/home-company/${company._id}/${lang}`}
              className={activePage === "jobs" ||  activePage === "qualityAndSecurity" || activePage === "career" ||  activePage === "missions" ||  activePage === "group" || activePage === "company" || activePage === "home-company" ||  activePage === "home-group" || activePage === "domains" || activePage === "innovation" || activePage === "approach" || activePage === "international" ? `${theme +"-bg light"}` : ""}>
              Pages site
            </Link>
          }
  
          {requireRight("projet") &&
            <Link
              to={`/projects-list/${company._id}/${lang}`}
              className={activePage === "projects" ? `${theme +"-bg light"}` : ""}>
              Projets
            </Link>
          }
          {requireRight("article") && company._id !== "661801566cf939d1592427a3"  &&
            <Link
              to={`/articles-list/${company._id}/${lang}`}
              className={activePage === "articles" ? `${theme +"-bg light"}` : ""}>
              Actualités
            </Link>
          }
          {requireRight("file") &&
            <Link
              to={`/medias/${company._id}`}
              className={activePage === "medias" ? `${theme +"-bg light"}` : ""}>
              Médiathèque
            </Link>
          }
          {requireRight("agency") && company._id !== "661801566cf939d1592427a3" &&
            <Link
              to={`/agencies-list/${company._id}/${lang}`}
              className={activePage === "agencies" ? `${theme +"-bg light"}` : ""}>
              Agences
            </Link>
          }
          {requireRight("utilisateur") &&
            <Link
              to={`/users/${company._id}`}
              className={activePage === "users" ? `${theme +"-bg light"}` : ""}>
              Utilisateurs
            </Link>
          }
        </>
      )
    }
  }

  return (
    <div className={`nav-container ${context.companies.company ? "shadow" : "" } ${theme}`}>
      <div className="nav">

        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          {renderCompanyIcon()}
        </div>

        <div className="nav-links">
          {renderNavLinks()}
        </div>

        <div className="nav-right">
          {context.companies.company && displaySelectLang  &&
            <>
              <div className="language-list">

                <button
                  onClick={() => setLang("fr")}
                  className={lang === "fr" ? "primary" : ""} >
                  <span>FR</span>
                </button>

                <button
                  onClick={() => setLang("en")}
                  className={lang === "en" ? "primary" : ""} >
                  EN
                </button>

                <button
                  onClick={() => setLang("es")}
                  className={lang === "es" ? "primary" : ""} >
                  ES
                </button>

              </div>

              <Link to="/code" className="btn code">
                <img src={code} alt="code" />
              </Link>

            </>
          }
          
          <button
            onMouseOver={() => setUserIsHover(true)}
            onMouseOut={() => setUserIsHover(false)}
            className="user-icon"
            onClick={() => logout()}
            >
            {userIsHover ? <><img src={logoutIcon} alt="logout" /></> : renderUserIcon() }
          </button>

        </div>

      </div>
    </div>
  )
}
