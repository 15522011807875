import React, { useState , useEffect , useCallback } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getUsersAction } from '../../../actions/usersActions';

import EditUser from './EditUser';
import UsersRow from './UserRow';
import Modale from '../../partials/Modale';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 


import './users.scss';

export default function UsersPage(props) {

  const [ context, dispatch ] = useGlobalContext();

  const param = useParams();

  const { setDisplayNavBar, setActivePage , theme , setDisplaySiteNav } = props;

  const [ usersList , setUsersList] = useState();
  const [ displayUserModale , setDisplayUserModale ] = useState(false);
  const [ user , setUser ] = useState();
  const [ search , setSearch ] = useState("");
  const [ removeAction , setRemoveAction ] = useState();

  const getUsers = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getUsersAction(dispatch)
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setActivePage("users");
    setDisplaySiteNav(false)
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (context.users.usersList && context.auth.userAuth) {
      var list = context.users.usersList.filter( user => user._id !== context.auth.userAuth._id ).filter(user => user.profile.lastName.toLowerCase().includes(search.toLowerCase()) || user.profile.firstName.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase()));
      setUsersList(list)
    } 
  }, [context.users.usersList , context.auth.userAuth , search ]);

  function addUser() {
    setUser();
    setDisplayUserModale(true)
  }

  function removeUserModale() {
    setUser();
    setDisplayUserModale(false)
  }

  function editUser(userSelected) {
    setUser(userSelected);
    setDisplayUserModale(true)
  }

  function renderUsersList() {
    if (usersList && usersList.length > 0) {
      return usersList.map((user, i ) => {
        return <UsersRow
                  user={user}
                  companyId={param.companyId}
                  theme={theme}
                  key={i}
                  setRemoveAction={setRemoveAction}
                  editUser={editUser}
                  context={context}
                  dispatch={dispatch} />
      })
    } else {
      return <span>Aucun utilistateur n'a été trouvé</span>
    }
  }

  return (
    <>    
      <div className={`page-container ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="title">
              <h1>Utilitsateurs</h1>
              <div className="bar primary"></div>
            </div>

            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Recherche"
            />

            <button
              onClick={() => addUser() }
              className="btn primary">
              Ajouter un utilisateur
            </button>
          </div>

          <div className="list-container">
            {renderUsersList()}
          </div>

        </div>
      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
      <Modale
        modaleIsOpen={displayUserModale}
        modaleToggle={removeUserModale}
        className={"add-user-modale"}
        >
        {displayUserModale && <EditUser
          user={user}
          dispatch={dispatch}
          setDisplayUserModale={setDisplayUserModale}
          context={context}
          theme={theme}/>
        }
      </Modale>
    </>
  )
}
