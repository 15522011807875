import React, { useEffect , useCallback , useRef , useState } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import { clearMarkup } from '../../../utils/utils';

import BlocText from '../../partials/BlocText';
import EditKeysFigures from '../../partials/EditKeysFigures';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';



import '../../../styles/pages-sites.scss';
import TextEditor from '../../partials/TextEditor';

export default function International({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'international';

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  // const [ international , setInternational ] = useState();
  const international = useRef({});

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();
  const [ titleIsWhite , setTitleIsWhite ] = useState(false);
  const [ keyFigures , setKeyFigures ] = useState([]);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);
  const [ upadetedPage , setUpdatedPage ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("international");
    getCompany();
    getPageAction(dispatch , param.companyId , page , lang );
    international.current = {}
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    return () => {
      setDisplaySelectLang(false);
      international.current = {}
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    international.current = {}
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      international.current = {...context.theme.page};
      setTitleIsWhite(context.theme.page.titleIsWhite);
      setKeyFigures(context.theme.page.keyFigures)
      setIsMounted(true);
    } else {
      international.current = {}
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  useEffect(() => {
    if (international.current && context.theme.page) {
      if (JSON.stringify(international.current) === JSON.stringify(context.theme.page)) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    }
    // eslint-disable-next-line
  },[international?.current]);

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });
    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function handleChangeKey( value , obj , key ) {
    var data = Object.assign({} , international.current );
    data[obj][key] = value
    international.current = data
  };

  function handleChange( value , obj) {
    var data = Object.assign({} , international.current );
    if (data[obj]) {
      data[obj] = value
    }
    international.current = data ;
    // if (JSON.stringify(international.current) !== JSON.stringify(context.theme.page)) {
    //   setIsSaved(false);
    // }
    setUpdatedPage(!upadetedPage);
    if (obj === "titleIsWhite") setTitleIsWhite(value);
    if (obj === "keyFigures") setKeyFigures(value)
  };

  function submitMedia(value) {
    switch (mediaKey) {
      
      case "picture":
        handleChange(value[0] , 'picture');
        break;

      case "implantation":
        handleChangeKey(value[0] , 'implantation' , 'picture');
        break;
    
      default:
        break;
    }
  };

  function handleChangeBloc(val , i) {
    var blocs = [...international.current.blocs];
    blocs[i] = val;
    handleChange(blocs , 'blocs');
  }

  function addBloc() {
    var addBlocs = [...international.current.blocs, { title: "" , text: "" } ];
    handleChange(addBlocs , 'blocs');
  }

  function deleteBloc(val) {
    let removeBlocs = international.current.blocs.filter(item => item !== val );
    handleChange(removeBlocs , 'blocs');
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('picture');
    setMediaType('image');
    if (international.current && international.current.picture) {
      var pictureArray = [];
      pictureArray.push(international.current.picture);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectImp() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('implantation');
    setMediaType('image');
    if (international.current && international.current.implantation && international.current.implantation.picture) {
      var implantationArray = [];
      implantationArray.push(international.current.implantation.picture._id);
      setSelectedMediaList(implantationArray)
    }
  }


  const submitInternational = (values)  => {
    var internationalData = {...values, ...international.current};
    var obj = {};
    for (const [key, value] of Object.entries(internationalData)) {
        obj[`${page}.${key}`] = value;
    }

    setPublishIsLoading(true);
    
    if (param.companyId === "5e9ec41b25ab1e65768e2ff0") {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );
    } else {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );
    }

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(international.current && international.current.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === international.current.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="cover" className="img-col-1" />
      } else if (international.current.picture && international.current.picture.path) {
        return <img src={API_URL + '/' + international.current.picture.path} alt="cover" className="img-col-1" />
      }
    }
  }

  function renderImpImg() {
    var imgFound;
    if(international.current && international.current.implantation && international.current.implantation.picture && international.current.implantation.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === international.current.implantation.picture)
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="implantation" className="img-large" />
      } else if (international.current.implantation.picture.path) {
        return <img src={API_URL + '/' + international.current.implantation.picture.path} alt="implantation" className="img-large" />
      }
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitInternational)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  <h1 dangerouslySetInnerHTML={{ __html: international.current && international.current.title ? clearMarkup(international.current.title) : "" }} ></h1>
                </div>

                <label htmlFor="title">Titre de la page</label>
                <input
                  name="title"
                  onChange={(e) => handleChange(e.target.value , 'title')}
                  defaultValue={international.current.title ? international.current.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <input id="isWhite"
                  onChange={(e) => handleChange(!e.target.checked, 'titleIsWhite')}
                  type="checkbox" className="switch" checked={!titleIsWhite}
                  style={{ height: 20 }}
                  />
                <label htmlFor="isWhite">{titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>

                <div className="section"></div>

                <div className="title">
                  <h2>Les paragraphes</h2>
                </div>     

                {international.current && international.current.blocs && international.current.blocs.map((bloc , i ) => (<BlocText
                  key={'blocktext' + i }
                  setBlockText={(val) => handleChangeBloc(val , i)}
                  deleteBloc={(val) => setRemoveAction({ function: () => deleteBloc(val)})}
                  blockText={bloc}
                  index={i} />
                ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 20}}
                    onClick={() => addBloc()}
                    type="button"
                    >
                    Ajouter un paragraphe
                  </button>
                </div>    

                <div className="section"></div>

                <div className="title">
                  <h2>Chiffres clés</h2>
                </div>

                <EditKeysFigures keyFigures={keyFigures} setRemoveAction={setRemoveAction} handleChange={(keyList) => handleChange( keyList , 'keyFigures')} theme={theme}/>
                  
                {param.companyId === "5e9ec41b25ab1e65768e2ff0" &&
                <>
                  <div className="section"></div>

                  <div className="title">
                    <h2>Implantations</h2>
                  </div>

                  <label htmlFor="implantation.title">Titre</label>
                  <input
                    name="implantation.title"
                    onChange={(e) => handleChangeKey(e.target.value , 'implantation' , 'title')}
                    defaultValue={international.current.implantation?.title ? international.current.implantation.title  : undefined }
                  />
                  <p className="error">{errors && errors.implantation && errors.implantation.title  && errors.implantation.title.message}</p>

                  <label>paragraphe</label>
                  <TextEditor
                    value={international.current && international.current.implantation && international.current.implantation.text}
                    onChange={(text) => handleChangeKey(text , 'implantation' , 'text')}
                    />

                  <label>Photo implantations <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1000 x 564 px , 72 dpi</span></label>
                  {renderImpImg()}
                  <div className="btn-container">
                    
                    <button
                      type="button"
                      className={`btn primary ${theme}-bg`}
                      style={{ marginRight : 20 }} 
                      onClick={() => openSelectImp(true)}
                      >
                      Parcourir
                    </button>

                    {international.current && international.current.implantation && international.current.implantation.picture &&
                      <button
                        type="button"
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction({ function: () => handleChangeKey( null , 'implantation' , 'picture') }) }
                        >
                        Supprimer
                      </button>
                    }
                  </div>
                  </>}
                        
              </div>

              <div className="col-2">

                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 350 px , 72 dpi</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {international.current && international.current.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({ function: () => handleChange( null , 'picture') })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />     
      {isMounted && international && <div id="innovation" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page international ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
