import React , { useRef } from 'react';
import close from '../../assets/images/icons/close.svg';

export default function Modale({ modaleIsOpen , modaleToggle , className , ...props }) {

  const modaleRef = useRef();

  return (
    <div ref={modaleRef} className={`modale-container ${modaleIsOpen? "isActive" : ""}`}>
      <div className={`modale ${className} ${modaleIsOpen? "isActive" : ""}`}>
        <button
          onClick={() => modaleToggle(false)}
          className="close">
          <img src={close} alt="close" />
        </button>
        {props.children}
      </div>
    </div>
  )
}
