import React from 'react';
import { Link } from  'react-router-dom';
import * as moment from 'moment';

import { useGlobalContext } from '../../context/GlobalContext';

import { API_URL } from '../../../actions';
import {  deleteSubDomainAction } from '../../../actions/subDomainsActions';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';

export default function SubDomainRow({ subDomain , companyId, domainId , theme }) {

  const [ context, dispatch ] = useGlobalContext();

  function renderIconImg() {
    var imgFound;
    if(subDomain && subDomain.icon && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === subDomain.icon )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" className="icon"/>
    }
  }

  function renderUpdatedLabel() {
    var updatedDate = new Date(subDomain.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }
  
  function renderRow() {
      return (
        <>
          <div className="title bold">
            {subDomain && renderUpdatedLabel()}
            {renderIconImg()}
            {subDomain?.position && `${subDomain.position} - `}
            {subDomain && subDomain.title}
          </div>

          <div className="date">
            {subDomain && moment(subDomain.updatedAt).format('DD/MM/YYYY')}
          </div>

          <div className="position">

          </div>

          <div className="domains">
          </div>

          <div className="status ">
            {/* {renderStatus()} */}
          </div>

          <div className="actions">

            <Link to={`/edit-sub-domain/${companyId}/${domainId}/${subDomain._id}`}>
              <button>
                <img src={edit} alt="edit"/>
              </button>
            </Link>


            <button
              onClick={() => deleteSubDomainAction(dispatch , subDomain._id ) }
              >
              <img src={remove} alt="remove"/>
            </button>

          </div>
      </>
    )
  }

  return (
    <div className={`list-item transition domain-row`} >
     {subDomain && renderRow()}
    </div>
  )
}
