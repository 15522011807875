import {
  GET_ARTICLES,
  ERROR_ARTICLES,
  DELETE_ARTICLE,
  MESSAGE_ARTICLES,
  POST_ARTICLE,
  GET_ARTICLE,
  UPDATE_ARTICLE,
} from "../actions/types"

const initialState = {
  articlesList: null,
  article: null,
  error: null,
  message: null
};


export default function articlesReducer(state = initialState, action) {

  var articlesList = state.articlesList;

  switch (action.type) {
    case GET_ARTICLES:
      return {...state , articlesList: action.payload};
      
    case POST_ARTICLE:
        if (!articlesList) {
          articlesList = [];
        }
        articlesList.push(action.payload.article);

      return {...state ,
        articlesList: articlesList,
        article: action.payload.article,
        message: action.payload.message
      };

    case UPDATE_ARTICLE:
      
        if (articlesList) {
          var index = articlesList.findIndex( a => a._id === action.payload.article._id);
          articlesList[index] = action.payload.article
        } else {
          articlesList = [];
          articlesList.push(action.payload.article);
        }

        return {
          ...state,
          articlesList: [...articlesList],
          article: action.payload.article,
          message: action.payload.message
        }

    case GET_ARTICLE:
      return {...state , article: action.payload }
    case DELETE_ARTICLE:
      articlesList = state.articlesList.filter( article => article._id !== action.payload)
      return {...state , articlesList: articlesList }
    case MESSAGE_ARTICLES:
        return {...state , message: action.payload}
    case ERROR_ARTICLES:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}