import { getData , putData } from './index';

import {
  GET_MISSIONS,
  UPDATE_MISSION,
  ERROR_MISSIONS,
} from "./types"

export async function getMissionsAction(dispatch , lang) {
  let url = "/mission/lang/" + lang;
  var missions;

  await getData(ERROR_MISSIONS , url, dispatch , true ).then((response)=>{
    missions = response.missions
  });

  dispatch({
    type : GET_MISSIONS,
    payload : missions
  });

};

export async function putMissionAction(dispatch , id , param ) {
  let url = "/mission/" + id;
  var mission;

  await putData(UPDATE_MISSION , ERROR_MISSIONS ,  url, dispatch, param , true ).then((response)=>{
    mission = response.mission
  })

  console.log(mission)

};