import React, { useState , useEffect } from 'react';
import TextEditor from '../../partials/TextEditor';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';
import close from '../../../assets/images/icons/close.svg';
import save from '../../../assets/images/icons/save.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import { API_URL } from '../../../actions';

export default function Value({ propValue , setRemoveAction , theme, companyId , index , openSelectPicture , addValue , handleChangeValue , deleteBloc }) {

  const [ value , setValue ] = useState(propValue);
  const [ isEdit , setIsEdit ] = useState(addValue ? true : false);
  const [ context ] = useGlobalContext();

  useEffect(() => {
    setValue({...propValue})
    if (!propValue.title) {
      setIsEdit(true)
    }
    // eslint-disable-next-line
  }, [propValue]);


  function saveValue() {
    if (value._id) {
      const data = {...value} 
      if(propValue?.picture) {
        data.picture = propValue?.picture
      }
      handleChangeValue(data , index);
    }
    setIsEdit(false);
  }

  function handleRemovePicture() {
    const data = {...value, picture: null } 
    handleChangeValue(data , index);
    setValue({...value, picture: null })
  }

  function renderImgLarge(media) {
    var imgFound;
    if(media && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === media );
      if (media && media._id) imgFound = context.medias.mediasList.find(item => item._id === media._id );
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt={imgFound.alt} className="img-small" />
      }
    }
  }


  function renderEdit() {
    return (
      <>
        <div className="btn-close-row">
          <button
            type="button"
            style={{ width: 30 }}
            onClick={() => saveValue()}
            >
            <img src={save} alt="edit" />
          </button>
          <button
            type="button"
            onClick={() => setIsEdit(false)}
            >
            <img src={close} alt="edit" />
          </button>
        </div>
        <label htmlFor={`value${index}`} >Titre</label>
        <input
          name={`value${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => setValue({...value, title: e.target.value })}
          defaultValue={value && value.title ? value.title : ""}
          />

        <label>Texte</label>
        <TextEditor
          value={value && value.text}
          onChange={newContent => setValue({...value, text: newContent})} 
        />
        {companyId === "661801566cf939d1592427a3" &&
          <>
            <label>Photo d'illustration</label>
            {propValue?.picture && renderImgLarge(propValue?.picture)}
            <div
              style={{ marginBottom: 20, marginTop: propValue?.picture ? 0 : 10 }} 
              className="btn-container">
              
              <button
                type="button"
                style={{ marginRight: 20 }}
                className={`btn primary ${theme}-bg`}
                onClick={() => openSelectPicture(`values.${index}.picture`)}
                >
                Parcourir
              </button>

              {propValue?.picture &&
                <button
                  type="button" 
                  className={`btn primary ${theme}-bg`}
                  onClick={() => setRemoveAction( {function: () => handleRemovePicture()} )}
                  >
                  Supprimer
                </button>
              }
            </div>
          </>
        }
      </>
    )
  }

  function renderRow() {
    return (
      <>
      <div className="name">
        <p className="bold" style={{ marginLeft: 15}}>{value && value.title}</p>
      </div>
      <div className="actions">

        <button
          type="button"
          onClick={() => setIsEdit(true)}
          >
          <img src={edit} alt="edit" />
        </button>
        {setRemoveAction &&
          <button
            type="button"
            onClick={() => setRemoveAction({ function: () => deleteBloc(value)} )}>
            <img src={remove} alt="remove"/>
          </button>
        }

      </div>
      </>
    )
  }

  return (
    <div id={`value${index}`} className={`value ${!isEdit? "list-item row" : "edit" }`}>
      {isEdit ? renderEdit() : renderRow()}
    </div>
  )
}
