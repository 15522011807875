import React, { useState } from 'react';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';

export default function EditPartners({ partners , handleChange  , setRemoveAction , theme }) {
  
  const [ displayAddPartner , setDisplayAddPartner ] = useState(false);
  const [ displayEditPartner , setDisplayEditPartner ] = useState(false);
  const [ partner , setPartner ] = useState();
  const [ partnerIndex , setPartnerIndex ] = useState();

  function addPartner() {
    var partnersList = [...partners];
    if (partner && partner.name) {
      partnersList.push(partner)
      handleChange( "partners" , partnersList);
      setDisplayAddPartner(false);
      setPartner();
    }
  };

  function submitPartner( ) {
    var partnersList = [...partners];
    partnersList[partnerIndex] = partner;
    handleChange( "partners" , partnersList);
    setDisplayEditPartner(false);
    setPartner();
    setPartnerIndex();
  }

  function removePartner(index) {
    var partnersList = [...partners];
    handleChange( "partners" , partnersList.filter(item => partnersList.indexOf(item) !== index ));
  }

  function editPartner(partner , index ) {
    setPartnerIndex(index)
    setPartner(partner);
    setDisplayEditPartner(true);
  } 

  function renderPartnersList() {
    if (partners.length) {
      return (
        <>
          {partners.map(( partner , i ) => {
            return (
              <div key={"partner" + i } className="partner-row bold ">
                {partner.url ?
                  <a href={partner.url} target="_blank" rel="noopener noreferrer" >{partner.name}</a>
                  :
                  <p>{partner.name}</p>
                }
                <div className="icons">
                  <button onClick={() => editPartner(partner , i)}>
                    <img src={edit} alt="edit"/>
                  </button>
                  <button
                    onClick={() => setRemoveAction({ function: () => removePartner(i)})}>
                    <img src={remove} alt="remove"/>
                  </button>
                </div>
              </div>
            ) 
          })}
        </>
      );
    }
  }

  return (
    <div className="partners-list">
      {displayEditPartner && partner && partner.name &&
        <div className="edit-partner">
          <label htmlFor="partnerName">Nom du partenaire</label>
          <input
            defaultValue={partner.name}
            onChange={(e) => setPartner({...partner , name: e.target.value })}
            name="partnerName"
          />

          <label htmlFor="partnerUrl">Lien vers le partenaire</label>
          <input
            defaultValue={partner.url ? partner.url : ""}
            onChange={(e) => setPartner({...partner , url: e.target.value })}
            name="partnerUrl"
          />
          <button className="btn primary" onClick={() => submitPartner()}>Valider</button>
          <button className="btn primary" style={{ marginLeft: 10 }} onClick={() => setDisplayEditPartner(false)}>Annuler</button>
        </div>
      }
      {displayAddPartner &&
        <div className="edit-partner">
          <label htmlFor="partnerName">Nom du partenaire</label>
          <input
            onChange={(e) => setPartner({...partner , name: e.target.value })}
            name="partnerName"
          />

          <label htmlFor="partnerUrl">Lien vers le partenaire</label>
          <input
            onChange={(e) => setPartner({...partner , url: e.target.value })}
            name="partnerUrl"
          />
          <button className="btn primary" onClick={() => addPartner()}>Valider</button>
          <button className="btn primary" style={{ marginLeft: 10 }} onClick={() => setDisplayAddPartner(false)}>Annuler</button>
        </div>
      }
      {!displayAddPartner && !displayEditPartner &&
        <>
        {renderPartnersList()}
        <button className="btn primary" onClick={() => setDisplayAddPartner(true)}>Ajouter un partenaire</button>
        </>
      }
    </div>
  )
}
