import React, { useState , useEffect } from 'react';
import TextEditor from './TextEditor';

export default function BlocText({ setBlockText , blockText , deleteBloc, index }) {
  const [ title , setTitle ] = useState();
  const [ text , setText] = useState()

  useEffect(() => {
    let updatedBloc = {...blockText};
    if(title) {
      updatedBloc.title = title;
    }
    if(text) {
      updatedBloc.text = text;
    }
    setBlockText(updatedBloc);
    // eslint-disable-next-line
  }, [title , text])

  return (
    <div id={`blockText${index}`}>
      
      <label htmlFor={`blockText${index}`} >Titre {index + 1}</label>
      <input
        name={`blockText${index}`}
        style={{ marginBottom: 10 }}
        onChange={(e) => setTitle(e.target.value)}
        defaultValue={blockText && blockText.title ? blockText.title : ""}
        />

      <label>Paragraphe</label>
      <TextEditor
        value={blockText && blockText.text}
        onChange={newContent => setText(newContent)}
      />
      <button
        onClick={() => deleteBloc(blockText)}
        type="button"
        className="btn-delete-bloc bold">
        Supprimer
      </button>

    </div>
  )
}
