import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia';
import EditPage from '../../partials/EditPage'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
import TextEditor from '../../partials/TextEditor';
import Value from '../../pages/Group/Value';

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction , getCompaniesAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import EditKeysFigures from '../../partials/EditKeysFigures';
import EditMapItems from '../../partials/EditMapItems';

import '../../../styles/pages-sites.scss';

export default function Company({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'entreprise';

  const { handleSubmit, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();
  const company = context.companies.company;

  // const [ home , setHome ] = useState();

  const companyPageRef = useRef();

  const home = companyPageRef.current;

  const [ isUpdated , setIsUpdated ] = useState(false);
  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
    getCompaniesAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("company");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      companyPageRef.current = null
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    companyPageRef.current = null
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      companyPageRef.current = {...context.theme.page};
      setIsMounted(true);
    } else {
      companyPageRef.current = null
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  useEffect(() => {
    if (home && JSON.stringify(home) === JSON.stringify(context.theme.page)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[home , context.theme.page])

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function handleChangeValue(val , i) {
    var values = [...home.values];
    values[i] = val;
    setIsSaved(false);
    companyPageRef.current.values =  values ;
    submitGroup(companyPageRef.current)
  }

  function deleteValue(index) {
    setIsSaved(false);
    let result = home.values;
    result = result.filter((item, i) => i !== index )
    companyPageRef.current.values = result
  }

  function submitMedia(value) {
    let updated = {...companyPageRef.current};
    const splitedKey = mediaKey.split('.');
    if (mediaKey.includes('values')) {
      updated.values[parseInt(splitedKey[1])].picture = value[0];
    }
    switch (mediaKey) {
      case "cover":
        updated.cover = value[0];
        break;
      case "clients":
        updated.clients.picture = value[0];
        break;

      case "picture":
        updated.picture = value[0];
        break;

      case "keyFiguresPicture":
        updated.keyFiguresPicture = value[0];
        break;

      case "innovation":
        updated.innovation.picture = value[0];
        break;

      case "international":
        updated.international.picture = value[0];
        break;

      case "commitment":
        updated.commitment.page.cover = value[0];
        break;

      case "organisation":
        updated.organisation.page.cover = value[0];
        break;
      
      case "qualityAndSecurity":
        updated.qualityAndSecurity.picture = value[0];
        break;
    
      default:
        break;
    }
    setMediaKey();
    companyPageRef.current = {...updated};
    setIsUpdated(!isUpdated);
  };

  function handleChange( value , obj) {
    setIsSaved(false);
    var data = Object.assign({} , companyPageRef.current );
    if (data[obj]) {
     data[obj] = value
    }
    companyPageRef.current = data
    setIsUpdated(!isUpdated)
  };

  function handleChangeKey( value , obj , key ) {
    setIsSaved(false);
    var data = Object.assign({} , companyPageRef.current );
    data[obj][key] = value
    companyPageRef.current = data;
    setIsUpdated(!isUpdated)
  };

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (!imgkey.includes('values') && home && home[imgkey] && home[imgkey].picture && home[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(home[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
    const splitedKey = imgkey.split('.');
    if (imgkey.includes('values')) {
      const picture = home.values[parseInt(splitedKey[1])].picture;
      const pictureArray = picture?._id ? [picture._id] : [picture];
      setSelectedMediaList(pictureArray)
    }
  }

  function renderImgLarge(media) {
    var imgFound;
    if(home && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === media );
      if (media && media._id) imgFound = context.medias.mediasList.find(item => item._id === media._id );
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt={imgFound.alt} className="img-large" />
      }
    }
  }


  const submitGroup = (values)  => {
    
    setPublishIsLoading(true);

    var groupData = {...companyPageRef.current, ...values};
    var obj = {};
    for (const [key, value] of Object.entries(groupData)) {
        obj[`${page}.${key}`] = value;
    }

    updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });

  };

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitGroup)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <label htmlFor="catchPhrase">Titre</label>
                <textarea
                  name="catchPhrase"
                  onChange={(e) => handleChange(e.target.value , "catchPhrase" )}
                  defaultValue={home && home.catchPhrase? home.catchPhrase : undefined }
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <label>Introduction</label>
                <TextEditor
                  value={home?.text ? home.text  : "" }
                  onChange={ value => handleChange(value , "text" )}
                />

                {company?._id !== "661801566cf939d1592427a3" && <>

                <div className="section"></div>

                <div className="title">
                  <h2>Chiffres clés</h2>
                </div>

                <EditKeysFigures
                  isCompany={true} 
                  keyFigures={home && home.keyFigures}
                  setRemoveAction={setRemoveAction}
                  handleChange={ (keyList) => handleChange(keyList , "keyFigures" )}
                  theme={theme}
                />

                <label>Photo d'illustration des chiffres clés<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px , 72 dpi</span></label>
                {home && home.keyFiguresPicture && renderImgLarge(home.keyFiguresPicture)}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("keyFiguresPicture")}
                    >
                    Parcourir
                  </button>

                  {home &&  home.keyFiguresPicture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onChange={() => handleChange(null, "keyFiguresPicture" )}
                      >
                      Supprimer
                    </button>
                  }
                </div>
                </>}

                <div className="section"></div>

                <div className="title">
                  <h2>Organisation</h2>
                </div>

                <label htmlFor="organisation.title">Titre</label>
                <input
                  name="organisation.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value, "organisation" , "title" )}
                  defaultValue={home && home.organisation && home.organisation.title ? home.organisation.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.organisation && home.organisation.text }
                  onChange={(value) => handleChangeKey( value, "organisation" , "text" )}
                />
                {company?._id !== "661801566cf939d1592427a3" && <>

                <input id="withMap"
                  onChange={(e) => handleChangeKey(e.target.checked, 'organisation' , 'withMap' )}
                  type="checkbox" className="switch" checked={home.organisation?.withMap}
                  style={{ height: 20 }}
                  />
         
                <label htmlFor="withMap">{home?.organisation?.withMap ? <span>Carte affiché</span> : <span>Ne pas afficher la carte</span> }</label>

                {home?.organisation?.withMap &&
                  <>
                    <div className="title">
                      <label>Métiers</label>
                    </div>
                    <EditMapItems
                      items={home?.organisation?.mapContent?.jobs || []}
                      setRemoveAction={setRemoveAction}
                      aad='Ajouter un métier'
                      handleChange={ (itemsList) => handleChange({
                        ...home?.organisation,
                        mapContent: home?.organisation?.mapContent ? {
                          ...home?.organisation.mapContent,
                          jobs: itemsList,
                        }: {
                          jobs: itemsList,
                        }
                      }, "organisation" )}
                      theme={theme}
                    />
                    <div className="title">
                      <label>Régions</label>
                    </div>
                    <EditMapItems
                      items={home?.organisation?.mapContent?.regions || []}
                      setRemoveAction={setRemoveAction}
                      aad='Ajouter une région'
                      handleChange={ (itemsList) => handleChange({
                        ...home?.organisation,
                        mapContent: home?.organisation?.mapContent ? {
                          ...home?.organisation.mapContent,
                          regions: itemsList,
                        }: {
                          regions: itemsList,
                        }
                      }, "organisation" )}
                      theme={theme}
                    />
                  </>
                }
                
                  <label>Description</label>
                  <TextEditor
                    value={home?.organisation?.text2 || ""}
                    onChange={(value) => handleChangeKey( value, "organisation" , "text2" )}
                  />
                </>}
                
                <div>
                  <input id="organisationIsActive"
                    onChange={(e) => handleChangeKey(e.target.checked ? " " : "", "organisation" , "button" )} 
                    type="checkbox" className="switch" checked={home.organisation && home.organisation.button === "" ? false : true }
                    />
                  <label htmlFor="organisationIsActive">{home.organisation && home.organisation.button !== "" ? <span>Page active</span> : <span>Page désactivé</span> }</label>
                </div>

                {home.organisation && home.organisation.button !== "" &&
                  <>
                  <label htmlFor="organisation.button">Bouton</label>
                  <input
                    name="organisation.button"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value , "organisation" , "button" )} 
                    defaultValue={home && home.organisation && home.organisation.button ? home.organisation.button  : undefined }
                  />
                  <EditPage
                    pageValue={home && home.organisation && home.organisation.page ? home.organisation.page :  { title: " " , blocs:[] , cover: " " } }
                    pages={[]}
                    setPages={(val) => handleChangeKey(val[0], "organisation" , "page" )}
                    companyId={param.companyId}
                    context={context}
                    setRemoveAction={setRemoveAction}
                    openSelectPicture={() => openSelectPicture("organisation")}
                    dispatch={dispatch}
                    isUpdated={isUpdated}
                    isCompanyPage={true}
                    index={0}
                    />
                  </>

                }
                {company?._id === "661801566cf939d1592427a3" && <>
                  <div className="section"></div> 
                  <div className="title">
                    <h2>Ils nous font confiance</h2>
                  </div>

                  <label htmlFor="clients.title">Titre</label>
                  <input
                    name="clients.title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value, "clients" , "title" )}
                    defaultValue={home?.clients?.title ? home.clients.title  : undefined }
                  />

                  <label>Introduction</label>
                  <TextEditor
                    value={home?.clients?.text || ''}
                    onChange={(value) => handleChangeKey(value, "clients" , "text" )}
                    />
                  
                  <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                  {home && home.clients && home.clients.picture && renderImgLarge(home.clients.picture)}
                  <div
                    style={{ marginBottom: 20 }} 
                    className="btn-container">
                    
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectPicture("clients")}
                      >
                      Parcourir
                    </button>

                    {home && home.clients && home.clients.picture &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChangeKey( null, "clients" , "picture" )} )}
                        >
                        Supprimer
                      </button>
                    }
                  </div>
                  
                  <div className="section"></div> 
                </>}
                {company?._id !== "661801566cf939d1592427a3" && <>
                  <div className="section"></div>

                  <div className="title">
                    <h2>Engagements</h2>
                  </div>

                  <label htmlFor="commitment.title">Titre</label>
                  <input
                    name="commitment.title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value, "commitment" , "title" )}
                    defaultValue={home && home.commitment && home.commitment.title ? home.commitment.title  : undefined }
                  />

                  <label>Introduction</label>
                  <TextEditor
                    value={home && home.commitment && home.commitment.text }
                    onChange={(val) => handleChangeKey(val, "commitment" , "text" )}
                  />

                  <div>
                    <input id="commimentIsActive"
                      onChange={(e) => handleChangeKey(e.target.checked ? " " : "", "commitment" , "button" )} 
                      type="checkbox" className="switch" checked={home.commitment && home.commitment.button === "" ? false : true }
                      />
                    <label htmlFor="commimentIsActive">{home.commitment && home.commitment.button !== "" ? <span>Page active</span> : <span>page désactivé</span> }</label>
                  </div>

                  {home.commitment && home.commitment.button !== "" &&
                    <>
                    <label htmlFor="commitment.button">Bouton</label>
                    <input
                      name="commitment.button"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => handleChangeKey(e.target.value , "commitment" , "button" )} 
                      defaultValue={home && home.commitment && home.commitment.button ? home.commitment.button  : undefined }
                    />
                    
                    <EditPage
                      pageValue={home && home.commitment && home.commitment.page ? home.commitment.page :  { title: " " , blocs:[] , cover: " " } }
                      pages={[]}
                      setPages={(val) =>handleChangeKey(val[0], "commitment" , "page" )}
                      companyId={param.companyId}
                      setRemoveAction={setRemoveAction}
                      openSelectPicture={() => openSelectPicture("commitment")}
                      dispatch={dispatch}
                      isUpdated={isUpdated}
                      isCompanyPage={true}
                      index={0}
                      />
                    </>
    
                  }

                  <div className="section"></div>
                </>}

                <div className="title">
                  <h2>Valeurs</h2>
                </div>

                <label htmlFor="value.title">Titre paragraphe valeur</label>
                <input
                  name="value.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChange(e.target.value , "valueTitle" )}
                  defaultValue={home && home.valueTitle ? home.valueTitle  : undefined }
                />

                <label>Paragraphe description</label>
                <TextEditor
                  value={home && home.valueText }
                  onChange={(value) => handleChange(value , "valueText" )}
                />

                {home.values && home.values.map((value, i ) => (<Value
                    key={'value' + i }
                    propValue={value}
                    companyId={param.companyId}
                    lang={lang}
                    handleChangeValue={(val) => handleChangeValue(val , i)}
                    context={context}
                    setRemoveAction={setRemoveAction}
                    dispatch={dispatch}
                    openSelectPicture={openSelectPicture}
                    deleteBloc={() => deleteValue(i)}
                    index={i} />
                  ))}


                  <div className="btn-container" >
                    <button
                      className={`btn primary ${theme}-bg`}
                      style={{ marginBottom: 20}}
                      onClick={() => handleChange([...home.values, { title: '', text: ''}], 'values') }
                      type="button"
                      >
                      Ajouter une valeur
                    </button>
                  </div>

              {company?._id !== "661801566cf939d1592427a3" && <>
                <label>Photo d'illustration des valeurs<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1030 x 516 px , 72 dpi</span></label>
                {home && home.picture && renderImgLarge(home.picture)}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("picture")}
                    >
                    Parcourir
                  </button>

                  {home && home.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , "picture" )}
                      >
                      Supprimer
                    </button>
                  }
                </div>
              </>}

                <div className="section"></div>
                {company?._id !== "661801566cf939d1592427a3" && <>

                <div className="title">
                  <h2>Innovations</h2>
                </div>

                <label htmlFor="innovation.title">Titre</label>
                <input
                  name="innovation.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value, "innovation" , "title" )}
                  defaultValue={home && home.innovation && home.innovation.title ? home.innovation.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.innovation && home.innovation.text }
                  onChange={(value) => handleChangeKey(value, "innovation" , "text" )}
                  />

                <div>
                  <input id="innovationIsActive"
                    onChange={(e) => handleChangeKey(e.target.checked ? " " : "", "innovation" , "button" )} 
                    type="checkbox" className="switch" checked={home.innovation && home.innovation.button === "" ? false : true }
                    />
                  <label htmlFor="innovationIsActive">{home.innovation && home.innovation.button !== "" ? <span>Page active</span> : <span>page désactivé</span> }</label>
                </div>

                {home.innovation && home.innovation.button !== "" &&
                  <>
                    <label htmlFor="innovation.button">Bouton</label>
                    <input
                      name="innovation.button"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => handleChangeKey(e.target.value, "innovation" , "button" )}
                      defaultValue={home && home.innovation && home.innovation.button ? home.innovation.button  : undefined }
                    />

                    <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                    {home && home.innovation && home.innovation.picture && renderImgLarge(home.innovation.picture)}
                    <div
                      style={{ marginBottom: 20 }} 
                      className="btn-container">
                      
                      <button
                        type="button"
                        style={{ marginRight: 20 }}
                        className={`btn primary ${theme}-bg`}
                        onClick={() => openSelectPicture("innovation")}
                        >
                        Parcourir
                      </button>

                      {home && home.innovation && home.innovation.picture &&
                        <button
                          type="button" 
                          className={`btn primary ${theme}-bg`}
                          onClick={() => setRemoveAction( {function: () => handleChangeKey( null, "innovation" , "picture" )} )}
                          >
                          Supprimer
                        </button>
                      }
                    </div>
                  </>
                }

                <div className="section"></div>

                <div className="title">
                  <h2>Qualité et sécurité</h2>
                </div>

                <label htmlFor="qualityAndSecurity.title">Titre</label>
                <input
                  name="qualityAndSecurity.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value, "qualityAndSecurity" , "title" )}
                  defaultValue={home && home.qualityAndSecurity && home.qualityAndSecurity.title ? home.qualityAndSecurity.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={home && home.qualityAndSecurity && home.qualityAndSecurity.text }
                  onChange={(value) => handleChangeKey(value, "qualityAndSecurity" , "text" )}
                />

                <div>
                  <input id="qualityAndSecurityIsActive"
                    onChange={(e) => handleChangeKey(e.target.checked ? " " : "", "qualityAndSecurity" , "button" )} 
                    type="checkbox" className="switch" checked={home.qualityAndSecurity && home.qualityAndSecurity.button === "" ? false : true }
                    />
                  <label htmlFor="qualityAndSecurityIsActive">{home.qualityAndSecurity && home.qualityAndSecurity.button !== "" ? <span>Page active</span> : <span>page désactivé</span> }</label>
                </div>

                {home.qualityAndSecurity && home.qualityAndSecurity.button !== "" &&
                  <>

                    <label htmlFor="qualityAndSecurity.button">Bouton</label>
                    <input
                      name="qualityAndSecurity.button"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => handleChangeKey(e.target.value, "qualityAndSecurity" , "button" )}
                      defaultValue={home && home.qualityAndSecurity && home.qualityAndSecurity.button ? home.qualityAndSecurity.button  : undefined }
                    />

                    <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                    {home && home.qualityAndSecurity && home.qualityAndSecurity.picture && renderImgLarge(home.qualityAndSecurity.picture)}
                    <div
                      style={{ marginBottom: 20 }} 
                      className="btn-container">
                      
                      <button
                        type="button"
                        style={{ marginRight: 20 }}
                        className={`btn primary ${theme}-bg`}
                        onClick={() => openSelectPicture("qualityAndSecurity")}
                        >
                        Parcourir
                      </button>

                      {home && home.qualityAndSecurity && home.qualityAndSecurity.picture &&
                        <button
                          type="button" 
                          className={`btn primary ${theme}-bg`}
                          onClick={() => setRemoveAction( {function: () => handleChangeKey( null, "qualityAndSecurity" , "picture" )} )}
                          >
                          Supprimer
                        </button>
                      }
                    </div>
                    </>
                }
                </>}
                {company?._id === "661801566cf939d1592427a3" && <>
                  <div className="title">
                    <h2>International</h2>
                  </div>

                  <label htmlFor="international.title">Titre</label>
                  <input
                    name="international.title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value, "international" , "title" )}
                    defaultValue={home?.international?.title ? home.international.title  : '' }
                  />

                  <label>Introduction</label>
                  <TextEditor
                    value={home?.international?.text || ''}
                    onChange={(value) => handleChangeKey(value, "international" , "text" )}
                    />
                  
                  <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                  {home && home.international && home.international?.picture && renderImgLarge(home.international.picture)}
                  <div
                    style={{ marginBottom: 20 }} 
                    className="btn-container">
                    
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectPicture("international")}
                      >
                      Parcourir
                    </button>

                    {home && home.international && home.international.picture &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChangeKey( null, "international" , "picture" )} )}
                        >
                        Supprimer
                      </button>
                    }
                  </div>
                  
                <div className="section"></div>
                </>}
              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo de couverture<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 564 px , 72 dpi</span></label>
                {home && home.cover && renderImgLarge(home.cover)}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("cover")}
                    >
                    Parcourir
                  </button>

                  {home && home.cover &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function: () => handleChange( null , "cover" )} )}
                      >
                      Supprimer
                    </button>
                  }
                </div>


              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
    <PromptRouter when={!isSaved} />  
    {isMounted && home && <div className={`page-container site ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="flex">

            <div className="title">
              <h1>Edition de la page entreprise ({lang})</h1>
              <div className="bar primary"></div>
            </div>

          </div>

        </div>

        <div className="list-container">
          {renderEdit()}
        </div>

      </div>
    </div>}
    <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
