import React, { useEffect , useCallback , useState } from 'react';
import { useParams , useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction , getCompaniesAction } from '../../../actions/companiesActions';
import { getProjectsAction , createProjectAction, exportProjectsAction } from '../../../actions/projectsActions';

import ProjectRow from '../projects/ProjectRow';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 

import './projectsList.scss';

export default function ProjectsList(props) {

  const [ context, dispatch ] = useGlobalContext();

  const [ removeAction , setRemoveAction ] = useState();
  const [ search , setSearch ] = useState();

  const param = useParams();
  const history = useHistory();

  const { setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , setActivePage , theme , lang } = props;

  const getProjects = useCallback(() => {
    getProjectsAction(dispatch ,  param );
  }, [dispatch , param])

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getCompaniesAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(false);
    setActivePage("projects");
    getProjects();
    getCompany();
    return () => {
      setDisplaySelectLang(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    history.push(`/projects-list/${param.companyId}/${lang}`)
    // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    if(context.projects.message === "created") {
      history.push(`/edit-project/${param.companyId}/${context.projects.project._id}`);
      
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le projet a bien été crée",
          type: "success"
        }
      });

      dispatch({
        type : 'MESSAGE_PROJECTS',
        payload : null
      });
    }
    // eslint-disable-next-line
  }, [context.projects.message])


  function createProject() {
    let reqBody = {
      lang: lang,
      company: param.companyId
    }
    createProjectAction(dispatch , reqBody);
  }

  function renderProjectsList() {
    let projectsList = context?.projects?.projectsList?.filter(p => {
      if (!search) return p;
      if (p?.name?.toLowerCase().includes(search?.toLowerCase())) return p
      return null;
    });
    if (projectsList?.length) {
      if (param.companyId !== "5e9ec41b25ab1e65768e2ff0") {
        projectsList = projectsList.filter(project => project.company === param.companyId);
      } else {
        projectsList = projectsList.filter(project => project.isGroup === "published" || project.isGroup === "pending" || project.company === "5e9ec41b25ab1e65768e2ff0");
      }
      return projectsList.map((project, i ) => {
        return <ProjectRow project={project} companyId={param.companyId} setRemoveAction={setRemoveAction}  theme={theme} key={i} />
      })
    } else {
      return <span>Aucun projet n'a été trouvé</span>
    }
  }


  return (
    <>    
      <div className={`page-container ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="title">
              <h1>Projets ({lang})</h1>
              <div className="bar primary"></div>
            </div>

            <input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Recherche"
              />
      
            <div>
              <button
                onClick={() => exportProjectsAction(lang)}
                style={{ marginRight: 8 }}
                className="btn primary">
                Exporter les projets
              </button>
              <button
                onClick={() => createProject()}
                className="btn primary">
                Créer un nouveau projet
              </button>
            </div>
          </div>

          <div className="list-container">
            {renderProjectsList()}
          </div>

        </div>
      </div>
      <RemoveModale
        removeAction={removeAction}
        text={{
          sentence: "Voulez vous vraiment supprimer ce projet ?<br/> Il sera définitivement supprimé de la base. ",
          confirm: "Confirmer",
          cancel: "Annuler",
        }}
        setRemoveAction={setRemoveAction}
        theme={theme}/> 
    </>
  )
}
