import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
import TextEditor from "../../partials/TextEditor";

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import '../../../styles/pages-sites.scss';


export default function CompanyHome({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'home';

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();
  const company = context.companies?.company;

  const companyRef = useRef();

  const [ isSaved , setIsSaved ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();
  const [ isUpdated , setIsUpdaded ] = useState(true);

  const [ isMounted , setIsMounted ] = useState(false);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("home-company");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      companyRef.current = null
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    companyRef.current = null
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      companyRef.current = {...context.theme.page};
      setIsMounted(true);
    } else {
      companyRef.current = null
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  useEffect(() => {
    if (companyRef.current && JSON.stringify(companyRef.current) === JSON.stringify(context.theme.page)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[companyRef , context.theme.page])

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  function submitMedia(value) {
    let updatedHome = {...companyRef.current}
    switch (mediaKey) {

      case "mission":
        updatedHome.mission.picture = value[0] ;
        break;

      case "innovation":
        updatedHome.innovation.picture = value[0] ;
        break;

      case "international":
        updatedHome.international.picture = value[0];
        break;

      case "approach":
          updatedHome.approach.picture = value[0];
        break;
      
      case "cover":
          updatedHome.cover = value[0];
        break;
      
      case "picture1":
          updatedHome.picture1 = value[0];
        break;
      
      case "video":
        updatedHome.video = value[0];
        break;
    
      default:
        break;
    }
    companyRef.current = updatedHome;
    setIsUpdaded(!isUpdated)
    setMediaKey();
  };

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (companyRef.current && companyRef.current[imgkey] && companyRef.current[imgkey].picture && companyRef.current[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(companyRef.current[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function renderImgLarge(key) {
    var imgFound;
    if(companyRef.current && companyRef.current[key] && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === companyRef.current[key] || item._id === companyRef.current[key].picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt={companyRef.current[key]} className="img-large" />
      } else if (companyRef.current[key].picture && companyRef.current[key].picture.path) {
        return <img src={API_URL + '/' + companyRef.current[key].picture.path} alt={companyRef.current[key].alt} className="img-large"/>
      }
    }
  }

  function openSelectVideo() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('video');
    setMediaType('video')
    handleChange( null , 'video' );
    if (companyRef.current.video) {
      var coverArray = []
      coverArray.push(companyRef.current.video);
      setSelectedMediaList(coverArray)
    }
  }

  function renderVideo() {
    var videoFound;
    if(companyRef.current && companyRef.current.video && context.medias.mediasList) {
      videoFound = context.medias.mediasList.find(item => item._id === companyRef.current.video )
      if (videoFound?.path) {        
        return (
          <video controls="controls" className="img-large">
            <source src={API_URL +'/'+ videoFound.path}  />
          </video>
        )
      } else if (companyRef.current.video.path) {
        return (
          <video controls="controls">
            <source src={API_URL +'/'+ companyRef.current.video.path } />
          </video>
        )
      }
    }
  }

  const submitGroup = (values)  => {
    
    setPublishIsLoading(true);

    var groupData = {...values, ...companyRef.current};

    var obj = {};
    for (const [key, value] of Object.entries(groupData)) {
        if (key !== "articles") {
          obj[`${page}.${key}`] = value;
        }
    }

    updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });

  };

  function handleChange( value , obj) {
    setIsSaved(false);
    var data = Object.assign({} , companyRef.current );
    if (data[obj]) {
      data[obj] = value
    }
    companyRef.current = data
    setIsUpdaded(!isUpdated)
  };

  function handleChangeKey( value , obj , key ) {
    setIsSaved(false);
    var data = Object.assign({} , companyRef.current );
    data[obj][key] = value
    companyRef.current = data
    setIsUpdaded(!isUpdated)
  };


  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitGroup)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <label htmlFor="title">Titre</label>
                <textarea
                  name="title"
                  onChange={(e) => handleChange( e.target.value , 'title')}
                  defaultValue={companyRef.current && companyRef.current.title ? companyRef.current.title  : '' }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <label>Paragraphe</label>
                <TextEditor
                  value={companyRef?.current && companyRef?.current?.text }
                  onChange={(newContent) => handleChange( newContent , 'text')} 
                />

                <div className="section"></div>

                <div className="title">
                  <h2>Missions</h2>
                </div>

                <label htmlFor="mission.title">Titre</label>
                <input
                  name="mission.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'mission' , 'title')}
                  defaultValue={companyRef.current && companyRef.current.mission && companyRef.current.mission.title ? companyRef.current.mission.title  : undefined }
                />

                <label>Introduction</label>
                <TextEditor
                  value={companyRef.current && companyRef.current.mission && companyRef.current.mission.text }
                  onChange={ newContent => handleChangeKey( newContent , 'mission' , 'text')}
                />

                <label htmlFor="mission.button">Bouton</label>
                <input
                  name="mission.button"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey(e.target.value , 'mission' , 'button')}
                  defaultValue={companyRef.current && companyRef.current.mission && companyRef.current.mission.button ? companyRef.current.mission.button  : undefined }
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                {renderImgLarge('mission')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("mission")}
                    >
                    Parcourir
                  </button>

                  {companyRef.current && companyRef.current.mission && companyRef.current.mission.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'mission' , 'picture')  } )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                {company._id !== "661801566cf939d1592427a3" && <>

                  <div className="section"></div>

                  <div className="title">
                    <h2>Approche globale</h2>
                  </div>

                  <label htmlFor="approach.title">Titre</label>
                  <input
                    name="approach.title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value , 'approach' , 'title')}
                    defaultValue={companyRef.current && companyRef.current.approach && companyRef.current.approach.title ? companyRef.current.approach.title  : undefined }
                  />

                  <label>Introduction</label>
                  <TextEditor
                    value={companyRef.current && companyRef.current.approach && companyRef.current.approach.text }
                    onChange={ newContent => handleChangeKey( newContent , 'approach' , 'text')}
                  />

                  <label htmlFor="approach.button">Bouton</label>
                  <input
                    name="approach.button"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value , 'approach' , 'button')}
                    defaultValue={companyRef.current && companyRef.current.approach && companyRef.current.approach.button ? companyRef.current.approach.button  : undefined }
                  />

                  <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                  {renderImgLarge('approach')}
                  <div
                    style={{ marginBottom: 20 }} 
                    className="btn-container">
                    
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectPicture("approach")}
                      >
                      Parcourir
                    </button>

                    {companyRef.current && companyRef.current.approach && companyRef.current.approach.picture &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'approach' , 'picture')  } )}
                        >
                        Supprimer
                      </button>
                    }
                  </div>

                  <div className="section"></div>

                  <div className="title">
                    <h2>Innovations</h2>
                  </div>

                  <label htmlFor="innovation.title">Titre</label>
                  <input
                    name="innovation.title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value , 'innovation' , 'title')}
                    defaultValue={companyRef.current && companyRef.current.innovation && companyRef.current.innovation.title ? companyRef.current.innovation.title  : undefined }
                  />

                  <label>Introduction</label>
                  <TextEditor
                    value={companyRef.current && companyRef.current.innovation && companyRef.current.innovation.text }
                    onChange={ newContent => handleChangeKey( newContent , 'innovation' , 'text')}
                  />

                  <label htmlFor="innovation.button">Bouton</label>
                  <input
                    name="innovation.button"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeKey(e.target.value , 'innovation' , 'button')}
                    defaultValue={companyRef.current && companyRef.current.innovation && companyRef.current.innovation.button ? companyRef.current.innovation.button  : undefined }
                  />

                  <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 600 x 636 px , 72 dpi</span></label>
                  {renderImgLarge('innovation')}
                  <div
                    style={{ marginBottom: 20 }} 
                    className="btn-container">
                    
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectPicture("innovation")}
                      >
                      Parcourir
                    </button>

                    {companyRef.current && companyRef.current.innovation && companyRef.current.innovation.picture &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'innovation' , 'picture')  } )}
                        >
                        Supprimer
                      </button>
                    }
                    </div>

                    <div className="section"></div>

                    <div className="title">
                      <h2>International</h2>
                    </div>

                    <label htmlFor="international.title">Titre</label>
                    <input
                      name="international.title"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => handleChangeKey(e.target.value , 'international' , 'title')}
                      defaultValue={companyRef.current?.international?.title}
                    />

                    <label>Introduction</label>
                    <TextEditor
                      value={companyRef.current?.international?.text}
                      onChange={ newContent => handleChangeKey( newContent , 'international' , 'text')}
                    />

                    <label htmlFor="international.button">Bouton</label>
                    <input
                      name="international.button"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => handleChangeKey(e.target.value , 'international' , 'button')}
                      defaultValue={companyRef.current?.international?.button}
                    />

                    <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                    {renderImgLarge('international')}
                    <div
                      style={{ marginBottom: 20 }} 
                      className="btn-container">
                      
                      <button
                        type="button"
                        style={{ marginRight: 20 }}
                        className={`btn primary ${theme}-bg`}
                        onClick={() => openSelectPicture("international")}
                        >
                        Parcourir
                      </button>

                      {companyRef.current && companyRef.current.international && companyRef.current.international.picture &&
                        <button
                          type="button" 
                          className={`btn primary ${theme}-bg`}
                          onClick={() => setRemoveAction( {function: () => handleChangeKey( null , 'international' , 'picture')  } )}
                          >
                          Supprimer
                        </button>
                      }
                    </div>
                    </>}
  
                </div>


                <div className="col-2">
                  <div className="btn-actions-container" >
                    {publishIsLoading ?
                      null
                      :
                      <button
                        className={`btn primary publish ${theme}-bg`}
                        type="submit"
                        >
                        Publier
                      </button>
                    }
                  </div>

                  <label>Vidéo de couverture<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px , 72 dpi</span></label>
                  {renderVideo()}
                  <div
                    style={{ marginBottom: 20 }} 
                    className="btn-container">
                    
                    <button
                      type="button"
                      style={{ marginRight: 20 }}
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectVideo()}
                      >
                      Parcourir
                    </button>

                    {companyRef.current && companyRef.current.video &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChange( null , 'video' )} )}
                        >
                        Supprimer
                      </button>
                    }
                  </div>

                  <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px</span></label>
                  {renderImgLarge('cover')}
                  <div className="btn-container">
                    
                    <button
                      type="button"
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectPicture('cover')}
                      >
                      Parcourir
                    </button>

                    {companyRef?.current?.cover &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction( {function: () => handleChange( null , 'cover' )} )}
                        >
                        Supprimer
                      </button>
                    }
                  </div>
              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />
      {isMounted && companyRef?.current && <div className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page d'accueil ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/>
    </>

  )
}
