import {
  GET_MISSIONS,
  ERROR_MISSIONS,
  MESSAGE_MISSIONS,
} from "../actions/types"

const initialState = {
  missionsList: null,
  mission: null,
  error: null,
  message: null
};

export default function missionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MISSIONS:
      return {...state , missionsList: action.payload}
    case MESSAGE_MISSIONS:
      return {...state , message: action.payload }
    case ERROR_MISSIONS:
      return {...state , error: action.payload }
    default:
      return {...state};
  }
}