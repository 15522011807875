import React, { useState , useEffect , useCallback , useRef } from 'react';
import { useParams , Link } from "react-router-dom";
import DatePicker , { registerLocale } from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; 

import { useGlobalContext } from '../../context/GlobalContext';

import SelectMedia from '../medias/SelectMedia';
import EditPartners from './EditPartners';
import EditKeysFigures from '../../partials/EditKeysFigures';
import MapEditor from './MapEditor';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 
import PromptRouter from '../../partials/PromptRouter';
import TextEditor from '../../partials/TextEditor';

import { API_URL } from '../../../actions/index';
import { companiesColors } from '../../../utils/utils';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getDomainsAction } from '../../../actions/domainsActions';
import { getSubDomainsAction } from '../../../actions/subDomainsActions';
import { getMissionsAction } from '../../../actions/missionsActions';
import { getProjectAction , updateProjectAction , getCompaniesPublicAction , getProjectsListsAction } from '../../../actions/projectsActions';

import './editProject.scss';
import remove from '../../../assets/images/icons/remove.svg';

export default function Editproject(props) {
  registerLocale("fr", fr);

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: 44,
      padding: "10px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const [ context, dispatch ] = useGlobalContext();

  const param = useParams();

  const { setDisplayNavBar, setActivePage , theme , lang } = props;


  const localisationsOptions = [
    { label : 'National' , value: false },
    { label : 'International' , value: true },
    { label : 'National & International' , value: null },
  ]

  const project = useRef();

  const [ isUpdated , setIsUpdated ] = useState();
  const [ removeAction , setRemoveAction ] = useState();
  const [ isSaved , setIsSaved ] = useState(false);
  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaKey , setMediaKey ] = useState('');
  const [ mediaType , setMediaType ] = useState();
  const [ selectedZone, setSelectedZone ] = useState();
  const [ zonesOptions , setZonesOptions] = useState();
  const [ text , setText ] = useState("")

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getCompaniesPublicAction(dispatch);
    getMissionsAction(dispatch , lang );
    getDomainsAction(dispatch , lang );
    getSubDomainsAction(dispatch , lang )
  },[dispatch , param.companyId , lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setActivePage("projects");
    getCompany();
    getProjectAction(dispatch , param.projectId)
    getProjectsListsAction(dispatch , lang , param.companyId );

    return () =>  {
      dispatch({
        type: 'GET_PROJECT',
        payload: null
      });
      project.current = null
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (context.projects.lists && context.projects.lists.zones !== null) {
      var zones = context.projects.lists?.zones?.terrestre.map(item => ({ value: item , label : item }));
      setZonesOptions(zones)
    }
  },[context.projects.lists]);

  useEffect(() => {
    if (context.projects.project) {
      var initProject = {...context.projects.project };
      if (!initProject.publicationDate) {
        initProject.publicationDate = new Date()
      }

      if (initProject.subdomains.length > 0 && initProject.subdomains[0]?._id && param.companyId === "5eb2745576b8fc776002c3c6" ) {
        initProject.subdomains = initProject.subdomains.map(d => d?._id);
      }
    
      if (initProject.contributors && initProject.contributors.length) {
        let initContributors = initProject.contributors.map(item => item._id  ? item._id : item);
        if (!initContributors.find(item => item === param.companyId)) initContributors.push(param.companyId)
        initProject.contributors = initContributors
      } else {
        initProject.contributors = [param.companyId]
      }


      if (initProject.location && initProject.location.zone) setSelectedZone({ value: initProject.location.zone , label: initProject.location.zone });
      if (initProject.text) setText(initProject.text);
      project.current = initProject;
      setIsSaved(true);
    }
  }, [context.projects.project , param.companyId])

  useEffect(() => {

    var initProject = {...context.projects.project };
    if (!initProject.publicationDate) {
      initProject.publicationDate = new Date()
    }
    // eslint-disable-next-line
  },[project?.current])

  useEffect(() => {
    setIsUpdated(!isUpdated);
    // eslint-disable-next-line
  },[project.current?.video])

  function handleChange(key , value ) {
    var updatedProject = {...project.current};
    switch (key) {
      case 'isGroup':
        updatedProject.isGroup = value;
        break;
      case 'publicationDate':
        updatedProject.publicationDate = value;
        break;
      case 'startDate':
        if (!updatedProject.dates) updatedProject.dates = {}
        updatedProject.dates.start = value;
        break;
      case 'endDate':
        if (!updatedProject.dates) updatedProject.dates = {}
        updatedProject.dates.end = value;
        break;
      case 'keyFigures':
        updatedProject.keyFigures = value;
        break;
      case 'mission':
        updatedProject.mission = value;
        break;
      case 'skills':
        updatedProject.skills = value;
        break;
      case 'client':
        updatedProject.client = value;
        break;
      case 'beneficiary':
        updatedProject.beneficiary = value;
        break;
      case 'type':
        updatedProject.type = value;
        break;
      case 'name':
        updatedProject.name = value;
        break;
      case 'textTitle':
        updatedProject.textTitle = value;
        break;
      case 'partners':
        updatedProject.partners = value;
        break;
      case 'missions':
          var missionsList = project.current.missions ;

          var foundMission = project.current.missions.find(item => item._id === value._id );
          if (foundMission) {
            missionsList = project.current.missions.filter(item => item._id !== value._id )
          } else {
            missionsList.push(value)
          }
          updatedProject.missions = [...new Set(missionsList)];
        break;
      case 'subdomains':
        var subdomainsList = updatedProject.subdomains ;
        var foundsubDomain = project.current.subdomains.find(item => item === value );
        if (foundsubDomain) {
          subdomainsList = project.current.subdomains.filter(item => item !== value )
        } else {
          subdomainsList.push(value)
        }
        updatedProject.subdomains = [...new Set(subdomainsList)];
        break;
      case 'domains':
          var domainsList = updatedProject.domains ;
          var foundDomain = project.current.domains.find(item => item._id === value._id );
          if (foundDomain) {
            domainsList = project.current.domains.filter(item => item._id !== value._id )
          } else {
            domainsList.push(value)
          }
          updatedProject.domains = [...new Set(domainsList)];
        break;
      case 'contributors':
        var contributorsList = updatedProject.contributors ;
        var foundCompany = project.current.contributors.find(item => item === value );
        if (foundCompany && value !== param.companyId) {
          contributorsList = project.current.contributors.filter(item => item !== value )
        } else {
          contributorsList.push(value)
        }
        if (contributorsList.length) {
          updatedProject.contributors = [...new Set(contributorsList)];
        } else {
          updatedProject.contributors = [];
        }
        break;
      case 'video':
        updatedProject.video = value[0];
        break;
      case 'file':
        updatedProject.files = value;
        break;
      case 'zoom':
        if (!updatedProject.location) updatedProject.location = {};
        updatedProject.location.zoom = value;
        break;
      case 'geojson':
        if (!updatedProject.location) updatedProject.location = {};
        updatedProject.location.geojson = value;
        break;
      case 'city':
        if (!updatedProject.location) updatedProject.location = {};
        updatedProject.location.city = value;
        break;
      case 'country':
        if (!updatedProject.location) updatedProject.location = {};
        updatedProject.location.country = value;
        break;
      case 'isInternational':
        if (!updatedProject.location) updatedProject.location = {};
        if (value === false) updatedProject.type = null
        updatedProject.location.isInternational = value;
        break;
      case 'zone':
          if (!updatedProject.location) updatedProject.location = {};
        updatedProject.location.zone = value.value;
        setSelectedZone(value);
        break;
      case 'card':
        updatedProject.card = value[0];
        break;
      case 'cover':
        updatedProject.cover = value[0];
        break;
      case 'pictures':
        updatedProject.pictures = value;
        break;
      default:
        break;
    }
    project.current = updatedProject;
    setIsUpdated(!isUpdated);
    setIsSaved(false);
  }

  function saveProject(status) {
    let savedProject = {...project.current , text: text }
    savedProject.status = status
    updateProjectAction(dispatch , savedProject );
    setIsSaved(true)
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaType('image');
    setMediaKey('cover')
    if (project.current.cover) {
      var coverArray = []
      coverArray.push(project.current.cover);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectCard() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaType('image');
    setMediaKey('card');
    if (project.current.card) {
      var cardArray = []
      cardArray.push(project.current.card);
      setSelectedMediaList(cardArray)
    }
  }

  function openSelectProjectSheet() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(true);
    setMediaType('file');
    setMediaKey('file')
    if (project.current.files) {
      var fileArray = [...project.current.files]
      setSelectedMediaList(fileArray)
    }
  }

  function openSelectVideo() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('video');
    setMediaType('video')
    handleChange('video' , [null] )
    if (project.current.video) {
      var coverArray = []
      coverArray.push(project.current.video);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectIllus() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(true);
    setMediaKey('pictures')
    setMediaType('image');
    if (project.current.pictures) {
      var pictures = []
      project.current.pictures.forEach(p => {
        pictures.push(p._id ? p._id : p )
      })
      setSelectedMediaList(pictures)
    }
  }

  function handleChangeText(value) {
    setText(value);
  }

  function handleChangeImg( key , value) {
    handleChange( key , value)
  };

  function renderSelectCompanies() {
    console.log(context?.projects?.lists?.contributors , context.companies.companiesList);
    if(context?.projects?.lists?.contributors) {
      return context?.projects?.lists?.contributors.filter(item => item._id !== param.companyId ).map((company, i ) => {
        var found = project.current.contributors.find(item => item === company._id );
        return (
          <button
            key={i}
            onClick={() => handleChange('contributors' , company._id  )}
            className={`icon ${found ? company.className+"-bg" : "notActive"}`}>
            <span>{company.icon}</span>
          </button>
        );
      }) 
    }
  }

  function renderSelectDomains() {
    if(context.domains.domainsList && context.domains.domainsList.length ) {
      return context.domains.domainsList.filter(domain => {
          if (domain.company === param.companyId ) {
            return true;
          } else {
            return false;
          }
        }).map((domain, i ) => {
          var found = project.current.domains.find(item => item === domain._id || item._id === domain._id);
          return (
            <button
              key={i}
              onClick={() => handleChange('domains' , domain )}
              className={`icon ${found ? theme +"-bg" : "notActive"}`}>
              <span>{domain.title.replace('<br>' , ' ')}</span>
            </button>
          )

      }) 
    }
  }

  function renderSelectSubDomains() {
    if(context.subdomains.subdomainsList && context.subdomains.subdomainsList.length ) {
      let subdomains = context.subdomains.subdomainsList.filter(domain => {
        if (context.domains.domainsList.find(d => d.company === param.companyId ) ) {
          return domain
        } else {
          return null
        }
      });
      return context.domains.domainsList.filter(d => d.company === param.companyId ).map((domain , i ) => {
        let subdomainsList = subdomains.filter(subdomain => subdomain.domain.find((d) => d === domain._id ));
        let selected = subdomains.filter(subdomain => subdomain.domain.find((d) => d === domain._id ) && project.current.subdomains.find(item => item === subdomain._id ) )
        return (
          <div key={`subdomain-${i}`}className="sub-domain-list">
            <p>{domain.title} {selected.length}/{subdomainsList.length}</p>
            {subdomainsList.map((subdomain, i ) => {
              var found = project.current.subdomains.find(item => item === subdomain._id );
              return (
                <button
                  key={i}
                  onClick={() => handleChange('subdomains' , subdomain._id )}
                  className={`icon ${found ? theme +"-bg" : "notActive"}`}>
                  <span>{subdomain.title.replace('<br>' , ' ')}</span>
                </button>
              )
            }) }
          </div>
        )
      })
    }
  }

  function renderSelectMissions() {
    if(context.missions.missionsList) {
      return context.missions.missionsList.map((mission, i ) => {
        var found = project.current.missions.find(item => item === mission._id || item._id === mission._id);
        return (
          <button
            key={i}
            onClick={() => handleChange('missions' , mission )}
            className={`icon ${found ? theme +"-bg" : "notActive"}`}>
            <span>{mission.title}</span>
          </button>
        )
      }) 
    }
  }

  function renderFiles() {
    if(project.current && project.current.files  && context.medias.mediasList) {
      return project.current.files.map((file , i ) => { 
        var fileFound = context.medias.mediasList.find(item => item._id === file._id || item._id === file);
        if (fileFound) return (
          <div
            key={i}
            className="file-row bold">
            <button
              type="button"
              className="bold"
              onClick={() =>  window.open(API_URL + '/' + fileFound.path)}
              >
              {fileFound.name}
            </button>
            <button
              type="button"
              onClick={() => handleChange('file' , project.current.files.filter(f => f._id !== fileFound._id || f._id === file ) ) }
              >
              <img src={remove} alt="remove" />
            </button>
          </div>
        )
        return null;
      });
    }
  }

  function renderCardImg() {
    if(project.current.card && context.medias.mediasList) {
      var cardFound = context.medias.mediasList.find(item => item._id === project.current.card )
      if (cardFound) {
        return <img src={API_URL + '/' + cardFound.path} alt="card"/>
      } else if (project.current.card.path) {
        return <img src={API_URL + '/' + project.current.card.path} alt="card" />
      }
    }
  }

  function renderCoverImg() {
    if(project.current.cover && context.medias.mediasList) {
      var imgFound = context.medias.mediasList.find(item => item._id === project.current.cover )
      if (imgFound) {
        return <img src={API_URL + '/' +imgFound.path} alt="cover"/>
      } else if (project.current.cover.path) {
        return <img src={API_URL + '/' + project.current.cover.path} alt="cover"/>
      }
    }
  }

  function renderVideo() {
    if(project.current.video && context.medias.mediasList) {
      var videoFound = context.medias.mediasList.find(item => item._id === project.current.video )
      if (videoFound) {        
        return (
          <video controls="controls">
              <source src={API_URL +'/'+ videoFound.path}  />
          </video>
        )
      } else if (project.current.video.path) {
        return (
          <video controls="controls">
              <source src={API_URL +'/'+ project.current.video.path } />
          </video>
        )
      }
    }
  }

  function renderIllusImg() {
    if(project.current.pictures && project.current.pictures.length && context.medias.mediasList) {
      return project.current.pictures.map((illu , i )=> {
        var imgFound = context.medias.mediasList.find(item => item._id === illu );

        if (imgFound) {
          return <img key={"illu" + i} src={API_URL + '/' +imgFound.path} alt="illu"/>
        } else if (illu.path) {
          return <img key={"illu" + i} src={API_URL + '/' + illu.path} alt="illu"/>
        }
        return null;
      });
    }
  }

  return (
    <>
      <div className={`page-container ${theme}`}>
        {project.current ?
          <div className="page-content">

            <div className="title-row space-between">

              <div className="flex">
                <Link to={`/projects-list/${param.companyId}/${lang}`}>
                  <button
                    className="btn primary back">
                    Retour
                  </button>
                </Link>

                <div className="title">
                  <h1>Edition d'un projet ({lang})</h1>
                  <div className="bar primary"></div>
                  <p><span className={`${theme}-color required bold`}>*</span> champ requis</p>
                </div>
              </div>

              <div>
                <button
                  onClick={() => saveProject("draft")}
                  style={{ marginRight: 20 }}
                  className="btn primary">
                    Enregistrer comme brouillon
                </button>
                <button
                  onClick={() => saveProject("published")}
                  className="btn success">
                    Publier
                </button>
              </div>

            </div>


            <div className="edit-container">

              <div className="col-content">
      
                <label className="m-10" htmlFor="name">Nom du projet <span className={`${theme}-color required`}>*</span></label>
                <input
                  defaultValue={project.current.name ? project.current.name : ""}
                  onChange={(e) => handleChange('name' , e.target.value)}
                  name="name"
                />

                <label className="m-10" htmlFor="skills">Compétences mobilisées <span className={`${theme}-color required`}>*</span></label>
                <input
                  defaultValue={project.current.skills ? project.current.skills : ""}
                  placeholder=" ex : Génie civil / VRD / Urbanisme ..."
                  onChange={(e) => handleChange('skills' , e.target.value)}
                  name="skills"
                />

                <label className="m-10" htmlFor="mission">Mission</label>
                <span className="note">Séparez chaque mission par un / pour opérer un retour à la ligne</span>
                <textarea
                  defaultValue={project.current.mission ? project.current.mission.split('<br/>').join('/') : ""}
                  onChange={(e) => handleChange('mission' , e.target.value.split('/').join('<br/>'))}
                  name="mission"
                />

                <label className="m-10" htmlFor="textTitle">Titre du paragraphe</label>
                <input
                  defaultValue={project.current.textTitle ? project.current.textTitle : ""}
                  onChange={(e) => handleChange('textTitle' , e.target.value)}
                  name="textTitle"
                />

                <label className="m-10" htmlFor="editor">Paragraphe <span className={`${theme}-color required`}>*</span></label>
                <TextEditor
                  value={text}
                  onChange={newContent => handleChangeText( newContent )}
                />

                <label className="m-10">Chiffres clés</label>
                <EditKeysFigures keyFigures={project.current.keyFigures} setRemoveAction={setRemoveAction} handleChange={keyList => handleChange('keyFigures' , keyList)} theme={theme}/>

                <label className="m-10">Carte <span className={`${theme}-color required`}>*</span></label>
                <div className="map-editor">
                  <MapEditor theme={theme} lang={lang} project={project.current} handleChange={(key , value) => handleChange( key , value)} />
                </div>

              </div>

              <div className="col-aside">

                {param.companyId !== "5e9ec41b25ab1e65768e2ff0" ?
                    <>
                      <label htmlFor="calendar">Demande de publication sur le site groupe</label>
                      <div style={{ marginTop: 8 , fontWeight: 'bold'}} className={theme+"-color"}>
                        {project.current.isGroup === "pending" && "Demande en attente de validation"}
                        {project.current.isGroup === "published" && "Demande validée"}
                      </div>
                      <div style={{ marginTop: 8}}>
                        {project.current.isGroup === "not group" ?
                          <button
                            className={`btn primary`}
                            onClick={() => handleChange('isGroup' , "pending")}
                            >
                            Publier
                          </button>
                          :
                          <button
                            className="btn grey"
                            onClick={() => handleChange('isGroup' , "not group")}
                            >
                            Annuler
                          </button>
                        }
                      </div>
                  </>
                  :
                  <>
                    {project.current.company !== "5e9ec41b25ab1e65768e2ff0" &&
                    <>
                      <label htmlFor="calendar">Demande de publication sur le site groupe</label>
                      <div style={{ marginTop: 8}} className={theme+"-color"}>
                        {project.current.isGroup === "pending" && "Demande en attente de validation"}
                        {project.current.isGroup === "published" && "Demande validée"}
                        {project.current.isGroup === "not group" && "Pas de demande en cours"}
                      </div>
                      <div style={{ marginTop: 8}}>
                        <button
                          className={`btn ${project.current.isGroup === "published" ? "success" : "grey"}`}
                          onClick={() => handleChange('isGroup' , "published")}
                          >
                          Publier
                        </button>
                        <button
                          className={`btn ${project.current.isGroup === "pending" ? "pending" : "grey"}`}
                          style={{ marginLeft: 8 , marginRight: 8 , }}
                          onClick={() => handleChange('isGroup' , "pending")}
                          >
                          En attente
                        </button>
                        <button
                          className={`btn ${project.current.isGroup === "not group" ? "danger" : "grey"}`}
                          onClick={() => handleChange('isGroup' , "not group")}
                          >
                          Rejeter
                        </button>
                    </div>
                  </>
                }
                </>
                }

                <label htmlFor="calendar">Date de publication<span className={`${theme}-color required`}>*</span></label>
                <div className="select-dates">
                  <DatePicker
                    selected={project.current.publicationDate && Date.parse(project.current.publicationDate)}
                    onChange={date => handleChange('publicationDate' , date )}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <label htmlFor="client">Nom du client <span className={`${theme}-color required`}>*</span></label>
                <input
                  defaultValue={project.current.client ? project.current.client : ""}
                  onChange={(e) => handleChange('client' , e.target.value)}
                  name="client"
                />
                
                <label htmlFor="beneficiary">Nom du bénéficiaire <span className={`${theme}-color required`}>*</span></label>
                <input
                  className="mb-20"
                  defaultValue={project.current.beneficiary ? project.current.beneficiary : ""}
                  onChange={(e) => handleChange('beneficiary' , e.target.value)}
                  name="beneficiary"
                />

                <label htmlFor="isInternational">Localisation<span className={`${theme}-color required`}>*</span></label>
                <Select
                  styles={customStyles}
                  value={localisationsOptions.find(l => l.value === project.current?.location?.isInternational) ? localisationsOptions.find(l => l.value === project.current?.location?.isInternational) : null } 
                  options={localisationsOptions}
                  isMulti={false}
                  placeholder={"Sélectionner une localisation"}
                  isSearchable={false}
                  onChange={(opt) => handleChange('isInternational' , opt.value)}
                />
                {project.current?.location?.isInternational !== false &&
                  <div className="check" style={{ marginBottom: 10 }}>
                    <input id="type"
                      onChange={(e) => handleChange('type' , e.target.checked ? 'short' : null )}
                      type="checkbox"
                      className="switch"
                      checked={project.current.type === 'short' ? project.current.type : false }
                      style={{ marginRight: 20 }}
                      />
                    {project.current.type === 'short' ? <span>Projet court</span> : <span>Projet long</span> }
                  </div>
                }
                <label htmlFor="country">Pays <span className={`${theme}-color required`}>*</span></label>
                <input
                  defaultValue={project.current.location && project.current.location.country ? project.current.location.country : ""}
                  onChange={(e) => handleChange('country' , e.target.value)}
                  name="country"
                />

                {zonesOptions &&
                  <>
                    <label htmlFor="zone">Zone <span className={`${theme}-color required`}>*</span></label>
                    <Select
                      styles={customStyles}
                      value={selectedZone}
                      options={zonesOptions}
                      isMulti={false}
                      placeholder={"Sélectionner une zone"}
                      isSearchable={false}
                      onChange={(val) => handleChange('zone' , val)}
                    />
                  </>
                }
                
                <label htmlFor="city">Ville / territoire <span className={`${theme}-color required`}>*</span></label>
                <input
                  defaultValue={project.current.location && project.current.location.city ?  project.current.location.city : ""}
                  onChange={(e) => handleChange('city' , e.target.value)}
                  name="city"
                />

                <label>Partenaires</label>
                <EditPartners partners={project.current.partners} setRemoveAction={setRemoveAction} handleChange={handleChange} theme={theme} />

                <label htmlFor="companies">Intervenants</label>
                <div className="select-companies mb-20" name="companies">
                  {param.companyId !== "5e9ec41b25ab1e65768e2ff0" &&
                    <button
                      className={`icon no-pointer ${context.companies?.company?.className ? context.companies?.company.className+"-bg" : "notActive"}`}>
                      <span>{context.companies?.company?.icon}</span>
                    </button>
                  }
                  {renderSelectCompanies()}
                </div>

                <label htmlFor="calendar">Calendrier <span className={`${theme}-color required`}>*</span></label>
                <div className="select-dates">
                  <DatePicker
                    selected={project.current.dates && Date.parse(project.current.dates.start)}
                    onChange={date => handleChange('startDate' , date )}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                  />
                  <p className="bold">au</p>
                    <DatePicker
                      selected={project.current.dates && Date.parse(project.current.dates.end)}
                      onChange={date => handleChange('endDate' , date )}
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                    />
                </div>

                {param.companyId === "5eb2745576b8fc776002c3c6" ?
                  <>
                    <label htmlFor="domains">Sous-domaines d’activités</label>
                    {renderSelectSubDomains()}
                  </>
                  :
                  <>
                    <label htmlFor="domains">Domaines d’activités</label>
                    <div className="select-domains mb-20" name="domains">
                      {renderSelectDomains()}
                    </div>
                  </>
                }

                <label htmlFor="missions">Métiers <span className={`${theme}-color required`}>*</span></label>
                <div className="select-domains mb-20" name="missions">
                  {renderSelectMissions()}
                </div>

                <label>Fiches Projets <span className={`${theme}-color required`}>*</span><br/><span className="note">nb: Taille miniature 294 x 390 px</span></label>
                <div className="select-media mb-20">
                  {renderFiles()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectProjectSheet(true)}
                    >
                    Parcourir
                  </button>

                  {project.current.files &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('file' , [])})}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photo dans la liste <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px , 72 dpi</span></label>
                <div className="select-media mb-20">
                  {renderCardImg()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectCard(true)}
                    >
                    Parcourir
                  </button>

                  {project.current.card &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('card' , [null]) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1236 x 698 px , 72 dpi</span></label>
                <div className="select-media mb-20">
                  {renderCoverImg()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {project.current.cover &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('cover' , [null]) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Vidéo à la une <span className="note">minimum 1920 x 1080 px , 72 dpi</span></label>
                <div className="select-media mb-20">
                  {renderVideo()}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectVideo(true)}
                    >
                    Parcourir
                  </button>

                  {project.current.video &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('video' , [null]) })  }
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photos du diaporama<br/><span className="note">minimum 1920 x 1080 px , 72 dpi</span></label>
                <div className="select-media mb-20">
                  <div className="illus-list">
                    {project.current.pictures && project.current.pictures.length > 0 && renderIllusImg()}
                  </div>
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectIllus(true)}
                    >
                    {project.current.pictures && project.current.pictures.length > 0 ? "Modifier" : "Parcourir"}
                  </button>

                  {project.current.pictures.length > 0 &&
                    <button className="btn primary"
                      onClick={() => setRemoveAction({ function: () => handleChange('pictures' , [] )})}
                      >
                      Tout supprimer
                    </button>
                  }
                </div>

              </div>

            </div>

          </div>
        :
          null
        }
      </div>
      <SelectMedia
        displaySelectMedia={displaySelectMedia}
        dispatch={dispatch}
        theme={theme}
        context={context}
        mediaType={mediaType}
        companyId={param.companyId}
        selectedMediaList={selectedMediaList}
        isMulti={mediaIsMulti}
        handleChange={(value) => handleChangeImg( mediaKey , value)}
        setDisplaySelectMedia={setDisplaySelectMedia}
        />
      <PromptRouter when={!isSaved} />
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>
  )
}
