import React from 'react';
import { Link } from "react-router-dom";
import * as moment from 'moment';

import { useGlobalContext } from '../../context/GlobalContext';

import { deleteArticleAction , updateArticleAction } from '../../../actions/articlesActions';

import { clearMarkup } from '../../../utils/utils';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';

// import editoIcon from '../../../assets/images/icons/edito_white.svg';
// import articleIcon from '../../../assets/images/icons/article_white.svg';
// import flashIcon from '../../../assets/images/icons/flash_white.svg';
import star from '../../../assets/images/icons/star.svg';
import starSuccess from '../../../assets/images/icons/star-success.svg';

export default function ArticleRow(props) {
  const { article , companyId , theme , setRemoveAction } = props;

  const [ context, dispatch ] = useGlobalContext();

  function renderUpdatedLabel() {
    var updatedDate = new Date(article.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }

  function renderCompaniesList() {
    if (context.companies.companiesList && context.companies.companiesList.length) {
      var logosList = []
      article.companies.forEach(companyId => {
        var found  = context.companies.companiesList.find(item => item._id === companyId)
        if (found) {
          logosList.push(found);
        }
      });
      if (logosList.length) {
        return logosList.map((logo, i) => {
          return (
            <div className={`icon ${logo.className}-bg`} key={i}>
              <span className="bold">{logo.icon}</span>
            </div>
          )
        })
      }
    }
  };

  // const icons = {
  //   linkedin: flashIcon,
  //   youtube: flashIcon,
  //   article: articleIcon
  // }
  
  function renderStatus() {
    var textStatus = "brouillon";
    if (article.status === "published") {
      textStatus = "publié";
    }
    return (
      <div className={`tooltip ${article.status}`}>
        {/* {article.type === 'article' && <img src={icons[article.type]} alt={article.type}/>} */}
        {article.type}
        <span className="tooltip-text" >{textStatus}</span>
      </div>
    );
  }

  return (
    <div className="list-item article-row">

      <div className="title bold border">
        {renderUpdatedLabel()}
        {article.title && clearMarkup(article.title)}
      </div>

      <div className="date border">
        {moment(article.updatedAt).format('DD/MM/YYYY')}
      </div>

      <div className="companies">
        {renderCompaniesList()}
      </div>

      <div className="domains">
        {/* {renderDomains()} */}
      </div>

      <div className="status border">
        {renderStatus()}
      </div>

      <div className="actions">
        <button
          onClick={() => updateArticleAction(dispatch , {_id : article._id , favorite: article.favorite ? false : true } )} 
          >
          <img src={article.favorite ? starSuccess : star} alt="favorite"/>
        </button>

        <Link to={`/edit-article/${companyId}/${article._id}`}>
          <button>
            <img src={edit} alt="edit"/>
          </button>
        </Link>
        <button
          className={article.companies?.length > 1 && article.status === "published" ? "disabled" : ""}
          onClick={() => setRemoveAction({ function: () => deleteArticleAction(dispatch , article._id )})}>
          <img src={remove} alt="remove"/>
        </button>

      </div>
    </div>
  )
}
