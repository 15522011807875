import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as Icons from '@fortawesome/free-solid-svg-icons';

import edit from '../../assets/images/icons/edit.svg';
import remove from '../../assets/images/icons/remove.svg';

import './keysfigures.scss';

export default function EditKeysFigures({ keyFigures , setRemoveAction , handleChange  , theme , isCompany }) {

  
  const [ displayAddKey , setDisplayAddKey ] = useState(false);
  const [ displayEditKey , setDisplayEditKey] = useState(false);
  const [ key, setKey] = useState();
  const [ keyIndex , setKeyIndex ] = useState();

  function addKey() {
    var keysList = keyFigures ? keyFigures : [];
    if (key) {
      if (!key.pos) key.pos = keyFigures.length + 1
      keysList.push(key)
      handleChange(keysList);
      setDisplayAddKey(false);
      setKey();
    }
  };

  function submitkey( ) {
    var keysList = [...keyFigures];
    keysList[keyIndex] = key;
    handleChange(keysList);
    setDisplayEditKey(false);
    setKey();
    setKeyIndex();
  }

  function removeKey(index) {
    var keysList = [...keyFigures];
    handleChange(keysList.filter(item => keysList.indexOf(item) !== index ));
  }

  function editKey(key, index ) {

    setKeyIndex(index);
    if (!key.pos) key.pos = index + 1
    setKey(key);
    setDisplayEditKey(true);
  }

  function addNewKey(e) {
    setDisplayAddKey(true)
  }


  function renderKeysList() {
    if (keyFigures && keyFigures.length) {
      return (
        <>
          {keyFigures.sort((a , b ) => a.pos - b.pos ).map(( key, i ) => {
            return (
              <div key={"key" + i } className="key-row">

                <div className="key-infos">
                  <h3 className="bold">
                    {key.pos !== 0 && <span>{key.pos} - </span>}
                    <span className={theme + "-color"}>{key?.number} {key.unit} </span>
                    <span>{key.text}</span>
                  </h3>
                </div>

                <div className="icons">
                  <button
                    type="button"
                    onClick={() => editKey(key, i)}>
                    <img src={edit} alt="edit"/>
                  </button>
                  <button
                    type="button"
                    onClick={() => setRemoveAction({ function: () => removeKey(i) })} >
                    <img src={remove} alt="remove"/>
                  </button>
                </div>
              </div>
            ) 
          })}
        </>
      );
    }
  }

  return (
    <div className="keys-list">
      {displayEditKey && key &&

        <div className="edit-key">
          <label htmlFor="pos">Position</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.pos ? key.pos : null }
            onChange={(e) => setKey({...key, pos: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null})}
            name="pos"
          />

          <label htmlFor="number">Nombre</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.number ? key.number : null }
            onChange={(e) => setKey({...key, number: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null })}
            name="number"
          />

          <label htmlFor="unit">Unité</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.unit}
            onChange={(e) => setKey({...key, unit: e.target.value })}
            name="unit"
          />

          {!isCompany &&
            <>
              <label htmlFor="title">Titre</label>
              <input
                style={{ marginBottom : 10 }}
                defaultValue={key.title}
                onChange={(e) => setKey({...key, title: e.target.value })}
                name="title"
              />
            </>
          }

          <label htmlFor="text">texte</label>
          <textarea
            style={{ marginBottom : 10 }}
            defaultValue={key.text}
            onChange={(e) => setKey({...key, text: e.target.value })}
            name="text"
          />

          <button className="btn success" type="button" onClick={() => submitkey()}>Valider</button>
          <button className="btn primary" type="button" style={{ marginLeft: 10 }} onClick={() => setDisplayEditKey(false)}>Annuler</button>
        </div>
      }
      {displayAddKey &&

        <div className="edit-key">
          <label htmlFor="pos">Position</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={keyFigures.length + 1 }
            onChange={(e) => setKey({...key, pos: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null})}
            name="pos"
          />

          <label htmlFor="number">Nombre</label>
          <input
            type="number"
            onChange={(e) => setKey({...key, number: e.target.value })}
            name="number"
          />

          <label htmlFor="unit">Unité</label>
          <input
            onChange={(e) => setKey({...key, unit: e.target.value })}
            name="unit"
          />

          {!isCompany &&
            <>
              <label htmlFor="title">Titre</label>
              <input
                onChange={(e) => setKey({...key, title: e.target.value })}
                name="title"
              />
            </>
          }

         
          <label htmlFor="text">Texte</label>
          <textarea
            onChange={(e) => setKey({...key, text: e.target.value })}
            name="text"
          />

          <button className="btn success" type="button" onClick={() => addKey()}>Valider</button>
          <button className="btn primary" type="button" style={{ marginLeft: 10 }} onClick={() => setDisplayAddKey(false)}>Annuler</button>
        </div>
      }
      {!displayAddKey && !displayEditKey &&
        <>
        {renderKeysList()}
        <button
          className="btn primary"
          type="button"
          onClick={(e) => addNewKey(e)}>Ajouter un chiffre clé</button>
        </>
      }
    </div>
  )
}
