import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams , Link , useHistory } from "react-router-dom";

import Select from "react-select";
import { companiesColors } from '../../../utils/utils';

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions/';
import { createDomainAction , updateDomainAction } from '../../../actions/domainsActions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getMediasAction } from '../../../actions/mediasActions';
import { getDomainsAction , getDomainAction } from '../../../actions/domainsActions';
import { getSubDomainsAction , createSubDomainAction } from '../../../actions/subDomainsActions';
import { getProjectsAction } from '../../../actions/projectsActions';

import SubDomainRow from '../domains/SubDomainRow';
import TextEditor from '../../partials/TextEditor';

import './domains.scss';
import remove from '../../../assets/images/icons/remove.svg';


export default function EditDomain({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , setActivePage , theme , lang }) {

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const param = useParams();

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();
  const [ isSaved , setIsSaved ] = useState(false);

  const history = useHistory();

  const domainRef = useRef();
  const domain = domainRef.current;
  const [ isUpdated , setIsUpdated ] = useState(false);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ projectsOptions , setProjectsOptions ] = useState();
  const [ selectedProjects , setSelectedProjects ] = useState();
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);
  const [ defaultProjects , setIsDefaultProjects] = useState(true);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getDomainsAction(dispatch , lang );
    getSubDomainsAction(dispatch , lang );
    getDomainAction(dispatch , param.domainId )
    getMediasAction(dispatch);
    const projectParam = {
      companyId: param.companyId,
      lang : lang
    } 
    getProjectsAction(dispatch , projectParam );

  },[dispatch , param.companyId , param.domainId ,  lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(false);
    setDisplaySiteNav(true)
    setActivePage("domains");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (JSON.stringify(domain) === JSON.stringify(context.domains.domain)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[domain , context.domains.domain]);

  useEffect(() => {
    if (defaultProjects) setSelectedProjects([]);
  },[defaultProjects])


  useEffect(() => {

    if (context.domains.domain && context.projects.projectsList  ) {
      domainRef.current = {...context.domains.domain};
      setProjectsOptions(context.projects.projectsList.filter(p => p.company === param.companyId && p.status === "published").map(item => { return { value: item._id , label: item.name}}));
    };

    dispatch({
      type:'MESSAGE_DOMAINS',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.domains.domain , context.projects.projectsList ]);

  useEffect(() => {
    if (context.domains.domain && projectsOptions ) {
      var projects = [];
      if (context.domains.domain.projects && context.domains.domain.projects.length) {
        projectsOptions.forEach(item => {
          if (context.domains.domain.projects.find(project =>  project === item.value)) {
            projects.push(item);
          }
        })
        setSelectedProjects(projects);
        setIsDefaultProjects(false);
      } 
      

    }
    // eslint-disable-next-line
  }, [projectsOptions])

  useEffect(() => {
    if (context.domains.message === 'Domain successfully updated!' || context.domains.message === 'domain saved' ) {
      
      setPublishIsLoading(false)

      dispatch({
        type:'MESSAGE_DOMAINS',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le domaine a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.domains.message , context.domains.error]);


  function submitMedia(value) {
    let updatedDomain = {...domainRef.current}
    if (mediaKey === "icon") {
      updatedDomain.icon = value[0];
    } 
    if (mediaKey === "cover") {
      updatedDomain.cover = value[0];
    }
    if (mediaKey === "card") {
      updatedDomain.card = value[0];
    }
    if (mediaKey === "pictures") {
      updatedDomain.pictures = value
    }
    if (mediaKey === "picture") {
      updatedDomain.picture = value[0];
    }
    if (mediaKey === "files") {
      let list = updatedDomain.files
      list.push(value[0]);
      updatedDomain.files = list
    }
    if (mediaKey === "video") {
      updatedDomain.video = value[0];
    }
    domainRef.current = {...updatedDomain};
    setIsUpdated(!isUpdated)
  };

  function handleChange( value , obj) {
    setIsSaved(false);
    var data = Object.assign({} , domainRef.current );
    if (data[obj] || obj === "text") {
      data[obj] = value
      console.log(data)
    }
    domainRef.current = data
    setIsUpdated(!isUpdated)
  };

  function openSelectCard() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaType('image');
    setMediaKey('card');
    if (domain.card) {
      var cardArray = []
      cardArray.push(domain.card);
      setSelectedMediaList(cardArray)
    }
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('cover');
    setMediaType('image');
    if (domain && domain.cover) {
      var coverArray = [];
      coverArray.push(domain.cover);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectPicture() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('picture');
    setMediaType('image');
    if (domain && domain.picture) {
      var pictureArray = [];
      pictureArray.push(domain.picture);
      setSelectedMediaList(pictureArray)
    }
  }


  function openSelectIllus() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(true);
    setMediaKey('pictures');
    setMediaType('image');
    if (domain && domain.pictures) {
      setSelectedMediaList(domain.pictures)
    }
  }

  function openSelectFiles() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('files');
    setMediaType('file');
    if (domain && domain.files) {
      setSelectedMediaList()
    }
  }

  function openSelectVideo() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('video');
    setMediaType('video')
    handleChange( null , 'video')
    if (domain.video) {
      var coverArray = []
      coverArray.push(domain.video);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectIcon() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('icon');
    setMediaType('image');
    if (domain && domain.icon) {
      var iconArray = [];
      iconArray.push(domain.icon);
      setSelectedMediaList(iconArray)
    }
  }

  const submitDomain = (values)  => {
    var domainData = {...values, ...domainRef.current };
    domainData.lang = lang;
    domainData.company = param.companyId;
    domainData.projects = selectedProjects ? selectedProjects.map(item => { return item.value }) : [];
    setPublishIsLoading(true)
    if (domain && domain._id) {
      domainData._id = domain._id
      updateDomainAction(dispatch , domainData );
    } else {
      createDomainAction(dispatch , domainData );
    }
    dispatch({
      type:'ERROR_DOMAINS',
      payload: null
    });
    dispatch({
      type:'MESSAGE_DOMAINS',
      payload: null
    });
  };

  function createSubDomain() {
    var domainData = {};
    domainData.lang = lang;
    domainData.company = param.companyId;
    domainData.domain = domain._id;
    createSubDomainAction(dispatch , domainData  )
  }

  useEffect(() => {
    if (context.subdomains && context.subdomains.message === "subdomain saved" && context.subdomains.subdomain ) {
      history.push(`/edit-sub-domain/${param.companyId}/${param.domainId}/${context.subdomains.subdomain._id}`)
    }
    // eslint-disable-next-line
  }, [context.subdomains.message , context.subdomains.subdomain]);

  function renderIconImg() {
    var imgFound;
    if(domain && domain.icon && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === domain.icon )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" className="icon"/>
    }
  }

  function renderCardImg() {
    if(domain && domain.card && context.medias.mediasList) {
      var cardFound = context.medias.mediasList.find(item => item._id === domain.card )
      if (cardFound) return <img src={API_URL + '/' +cardFound.path} alt="card"/>
    }
  }

  function renderCoverImg() {
    var imgFound;
    if(domain && domain.cover && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === domain.cover )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" />
    }
  }

  function renderPictureImg() {
    var imgFound;
    if(domain && domain.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === domain.picture )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="domain" />
    }
  }

  function renderVideo() {
    if(domain && domain.video && context.medias.mediasList) {
      var videoFound = context.medias.mediasList.find(item => item._id === domain.video )
      if (videoFound) return (
        <video controls="controls">
            <source src={API_URL +'/'+ videoFound.path}  type={videoFound.mimetype} />
        </video>
      )
    }
  }

  function renderIllusImg() {
    if(domain && domain.pictures.length && context.medias.mediasList) {
      return domain.pictures.map((illu , i )=> {
        var imgFound = context.medias.mediasList.find(item => item._id === illu )
        if (imgFound) return <img key={"illu" + i} src={API_URL + '/' +imgFound.path} alt="illu"/>
        return null;
      });
    }
  }

  function renderFiles() {
    if(domain && domain.files && domain.files.length > 0 && context.medias.mediasList) {
      return domain.files.map((file , i ) => { 
        var fileFound = context.medias.mediasList.find(item => item._id === file );
        if (fileFound) return (
          <div
            key={i}
            className="file-row bold">
            <button
              type="button"
              className="bold"
              onClick={() =>  window.open(API_URL + '/' + fileFound.path)}
              >
              {fileFound.name}
            </button>
            <button
              type="button"
              onClick={() => handleChange( domain.files.filter(f => f !== fileFound._id ) , 'files') }
              >
              <img src={remove} alt="remove" />
            </button>
          </div>
        )
        return null;
      });
    }
  }

  function renderSubDomainsList() {
    if (context.subdomains.subdomainsList && context.subdomains.subdomainsList.filter(domain => domain.domain.find((d) => d === param.domainId)).length) {
      return context.subdomains.subdomainsList.sort((a,b) => a.position - b.position ).filter(domain => domain.domain.find((d) => d === param.domainId)).map((subDomain) => {
        return <SubDomainRow subDomain={subDomain} domainId={param.domainId} companyId={param.companyId} theme={theme} key={subDomain._id} lang={lang}/>
      })
    } else {
      return <span>Aucun sous-domaine n'a été trouvé</span>
    }
  }

  function renderEditGroupDomain() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitDomain)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  {renderIconImg()}
                  <h1>{domain && domain.title ? domain.title : null }</h1>
                </div>

                <div className="select-icon">
                  <p><span className="note">Minimum 100px x 100px, format png fond transparent</span></p>
                  <button type="button" className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectIcon(true)}
                    >
                    Séléctionner une icône
                  </button>

                  {domain && domain.icon &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'icon')}
                      >
                      Supprimer l'icône
                    </button>
                  }
                </div>

                <label htmlFor="title">Titre</label>
                <input
                  name="title"
                  onChange={(e) => handleChange( e.target.value , 'title')}
                  defaultValue={domain && domain && domain.title ? domain.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <label htmlFor="position">Position</label>
                <input
                  name="position"
                  onChange={(e) => handleChange( e.target.value , 'position')}
                  type="number"
                  defaultValue={domain && domain && domain.position ? domain.position  : 0 }
                  ref={register({
                    required: "Compléter la position",
                  })}
                />

                <label htmlFor="text">Texte</label>
                <textarea
                  name="text"
                  onChange={(e) => handleChange( e.target.value , 'text')}
                  defaultValue={domain && domain.text ? domain.text  : undefined }
                  ref={register({
                    required: "Compléter le text",
                  })}
                />
                <p className="error">{errors && errors.text && errors.text.message}</p>

              </div>
              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo d'illustration<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 692 x 692 px</span></label>
                {renderPictureImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture(true)}
                    >
                    Parcourir
                  </button>

                  {domain && domain.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'picture')}
                      >
                      Supprimer
                    </button>
                  }

                </div>
              </div>
            </div>
          </form>
        </div>
    )
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitDomain)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  {renderIconImg()}
                  <h1>{domain && domain.title ? domain.title : null }</h1>
                </div>

                <div className="select-icon">
                  <p><span className="note">Minimum 100px x 100px, format png fond transparent</span></p>
                  <button type="button" className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectIcon(true)}
                    >
                    Séléctionner une icône
                  </button>

                  {domain && domain.icon &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'icon')}
                      >
                      Supprimer l'icône
                    </button>
                  }
                </div>

                <label htmlFor="title">Titre</label>
                <input
                  name="title"
                  onChange={(e) => handleChange( e.target.value , 'title')}
                  defaultValue={domain && domain && domain.title ? domain.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>
                                
                <input id="isWhite"
                  onChange={(e) => handleChange( !e.target.checked , 'titleIsWhite')}
                  type="checkbox"
                  className="switch"
                  defaultChecked={domain.titleIsWhite ? false : true }
                  style={{ height: 20 }}
                  />
                <label htmlFor="isWhite">{domain.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>

                <div className="section"></div>

                <label htmlFor="position">Position</label>
                <input
                  name="position"
                  onChange={(e) => handleChange( e.target.value , 'position')}
                  type="number"
                  defaultValue={domain && domain && domain.position ? domain.position  : 0 }
                  ref={register({
                    required: "Compléter la position",
                  })}
                />

                <div className="section"></div>

                <label htmlFor="why">Titre paragraphe</label>
                <textarea
                  name="why"
                  onChange={(e) => handleChange( e.target.value , 'why')}
                  defaultValue={domain?.why ? domain.why  : undefined }
                  ref={register({
                    required: "Compléter le 'why'",
                  })}
                />
                <p className="error">{errors && errors.why && errors.why.message}</p>

                {param.companyId !== '661801566cf939d1592427a3' &&
                  <>
                    <div className="section"></div>
                    <label htmlFor="editor">Paragraphe</label>
                    <TextEditor
                      value={domainRef.current?.text || domain?.text}
                      onChange={( value) => handleChange( value , 'text')}
                    />
                    <p className="error">{errors && errors.text && errors.text.message}</p>
                    <div className="section"></div>
                </>
              }
                
                <label htmlFor="intro">Description</label>
                <textarea
                  name="intro"
                  onChange={(e) => handleChange( e.target.value , 'intro')}
                  defaultValue={domain && domain.intro ? domain.intro  : undefined }
                  ref={register}
                />
                <p className="error">{errors && errors.intro && errors.intro.message}</p>

              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>


                <label>Photo dans la liste <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1030 x 516 px</span></label>
                {renderCardImg()}
                <div className="btn-container">
                  <button className="btn primary"
                    type="button"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectCard(true)}
                    >
                    Parcourir
                  </button>

                  {domain && domain.card &&
                    <button className="btn primary"
                      type="button"
                      onClick={() => handleChange( null , 'card')}
                      >
                      Supprimer
                    </button>
                  }
                </div>
                {param.companyId !== '661801566cf939d1592427a3' &&
                  <>
                <label>Photo de couverture<span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {domain && domain.cover &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'cover')}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Vidéo à la une <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1920 x 1080 px</span></label>
                {renderVideo()}
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn primary"
                    onClick={() => openSelectVideo(true)}
                    >
                    Parcourir
                  </button>

                  {domain.video &&
                    <button
                      type="button"
                      className="btn primary"
                      onClick={() => handleChange( null , 'video')}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photos d'illustration<br/><span className="note">minimum 2058 x 1160 px</span></label>
                  <div className="illus-list">
                    {renderIllusImg()}
                  </div>
                  <div className="btn-container">

                    <button
                      type="button"
                      className="btn primary"
                      style={{ marginRight: 20}}
                      onClick={() => openSelectIllus(true)}
                      >
                      Parcourir
                    </button>

                  {domain && domain.pictures.length > 0 &&
                    <button
                      type="button"  
                      className="btn primary"
                      onClick={() => handleChange( [] , 'pictures')}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Fichier(s)<br/><span className="note">nb: Taille miniature 294 x 390 px</span></label>
                {renderFiles()}
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectFiles(true)}
                    >
                    Parcourir
                  </button>
                </div>

                <label>Projets associés</label>
                <p className="default-projects" htmlFor="isDefault">{defaultProjects ? <span>Par défault les 4 derniers projets sont sélectionnés</span> : <span>Administration personnalisée des projets associés</span> }</p>
                <input id="isDefault"
                  onChange={() => setIsDefaultProjects(!defaultProjects)}
                  type="checkbox"
                  className="switch"
                  checked={defaultProjects}
                  style={{ height: 20 }}
                  />
                {!defaultProjects &&
                  <Select
                    styles={customStyles}
                    value={selectedProjects}
                    placeholder={'Sélectionner'}
                    options={projectsOptions}
                    onChange={(value) => setSelectedProjects(value)}
                    isMulti
                  />
                }
                </>}
              </div>
            </div>
            
        </form>
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />
      {domain && domain._id &&    
        <div className={`page-container site ${theme}`}>
          <div className="page-content">

            <div className="title-row space-between">

              <div className="flex">
                <Link to={`/domains-list/${param.companyId}/${lang}`}>
                  <button
                    className="btn primary back">
                    Retour
                  </button>
                </Link>

                <div className="title">
                  <h1>Edition d'un domaine ({lang})</h1>
                  <div className="bar primary"></div>
                </div>

              </div>

            </div>

            {param.companyId !== "5e9ec41b25ab1e65768e2ff0"? 
              <>
                <div className="list-container">
                  {renderEdit()}
                </div>
                {param.companyId !== '661801566cf939d1592427a3' &&
                  <>
                  <div className="title-row space-between">
                    <div className="title">
                      <h2>Liste des sous-domaines associées</h2>
                      <div className="bar primary"></div>
                    </div>
                    <button
                      onClick={() => createSubDomain()}
                      className="btn primary">
                        Ajouter un sous-domaine
                    </button>
                  </div>
                  <div style={{ marginBottom: 70 }} className="list-container">
                    {renderSubDomainsList()}
                  </div>
                </>}
              </>
            :
              <div style={{ marginBottom: 70 }} className="list-container">
              {renderEditGroupDomain()}
              </div>
            }

          </div>
          <SelectMedia
            displaySelectMedia={displaySelectMedia}
            dispatch={dispatch}
            theme={theme}
            context={context}
            mediaType={mediaType}
            selectedMediaList={selectedMediaList}
            companyId={param.companyId}
            isMulti={mediaIsMulti}
            handleChange={(value) => submitMedia(value)}
            setDisplaySelectMedia={setDisplaySelectMedia}
          />
        </div>
      }
    </>

  )
}
