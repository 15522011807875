import {
  GET_TALENTS,
  POST_TALENT,
  DELETE_TALENT,
  UPDATE_TALENT,
  ERROR_TALENTS,
  MESSAGE_TALENTS
} from "../actions/types"

const initialState = {
  talentsList: null,
  error: null,
  message: null
};


export default function contactsReducer(state = initialState, action) {

  var talentsList = state.talentsList;

  switch (action.type) {

    case POST_TALENT:
      talentsList.push(action.payload.talent)
      return {...state ,
        talentsList: talentsList ,
        message: action.payload.message
      };

    case UPDATE_TALENT:
      
      if (talentsList) {
        var index = talentsList.findIndex(talent => talent._id === action.payload.talent._id);
        if (index >= 0) {
          talentsList[index] = action.payload.talent
          // talentsList = talentsList[index];
        }
      } else {
        talentsList = [];
        talentsList.push(action.payload.talent);
      }

      return {
        ...state,
        talentsList: talentsList,
        message: action.payload.message
      }

    case GET_TALENTS:
      return {...state , talentsList: action.payload }

    case DELETE_TALENT:
      talentsList = state.talentsList.filter(talent =>talent._id !== action.payload)
      return {...state , talentsList: talentsList }

    case MESSAGE_TALENTS:
        return {...state , message: action.payload}

    case ERROR_TALENTS:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}