import React from 'react';
import { Link } from "react-router-dom";

import { deleteAgencyAction } from '../../../actions/agenciesActions';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';


export default function AgencyRow({ agency , dispatch ,  companyId , setRemoveAction,  theme }) {

  function renderUpdatedLabel() {
    var updatedDate = new Date(agency.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }

  return (
    <div className="list-item agency-row">

      <div className="title bold border">
        {renderUpdatedLabel()}
        {agency.name}
      </div>

      <div className="large border">
        {agency.city}
      </div>

      <div className="address border">
        {agency.country}
      </div>

      <div className="address border">
        {agency.phone}
      </div>

      <div className="large border">
        {agency.email}
      </div>

      <div className="address border">
        {agency.isInternational? "Internationale" : "Nationale"}
      </div>

      <div className="actions">

        <Link to={`/edit-agency/${companyId}/${agency._id}`}>
          <button>
            <img src={edit} alt="edit"/>
          </button>
        </Link>

        <button
          onClick={() => setRemoveAction({ function: () => deleteAgencyAction(dispatch , agency._id )})}>
          <img src={remove} alt="remove"/>
        </button>

      </div>
    </div>
  )
}
