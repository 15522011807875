import React from 'react';
import { Link } from "react-router-dom";

import './nav.scss';

export default function SiteNav(props) {

  const { activePage , theme , lang , context , companyId } = props;


  function renderLinks() {
    if (context.companies.company) {
      var company = context.companies.company;
      return (
        <>
        {companyId !== "5e9ec41b25ab1e65768e2ff0" ?
          <>
            <Link
              to={`/home-company/${company._id}/${lang}`}
              className={activePage === "home-company" || activePage === "home-group" ? `${theme +"-bg light"}` : ""}>
              Accueil
            </Link>
            <Link
              to={`/company/${company._id}/${lang}`}
              className={activePage === "company" ? `${theme +"-bg light"}` : ""}>
              Entreprise
            </Link>
            <Link
              to={`/domains-list/${company._id}/${lang}`}
              className={activePage === "domains" ? `${theme +"-bg light"}` : ""}>
              Domaines d'activités
            </Link>
            {company._id !== "661801566cf939d1592427a3" &&
            <Link
              to={`/jobs/${company._id}/${lang}`}
              className={activePage === "jobs" ? `${theme +"-bg light"}` : ""}>
              Nos métiers
            </Link>
            }
            <Link
              to={`/missions/${company._id}/${lang}`}
              className={activePage === "missions" ? `${theme +"-bg light"}` : ""}>
              Nos missions
            </Link>
            {company._id !== "661801566cf939d1592427a3" ?
              <>        
                <Link
                  to={`/innovations/${company._id}/${lang}`}
                  className={activePage === "innovation" ? `${theme +"-bg light"}` : ""}>
                  Innovations
                </Link>
                <Link
                  to={`/approach/${company._id}/${lang}`}
                  className={activePage === "approach" ? `${theme +"-bg light"}` : ""}>
                  Approche globale
                </Link>
                <Link
                  to={`/quality/${company._id}/${lang}`}
                  className={activePage === "qualityAndSecurity" ? `${theme +"-bg light"}` : ""}>
                  Qualité et sécurité
                </Link>
                <Link
                  to={`/international/${company._id}/${lang}`}
                  className={activePage === "international" ? `${theme +"-bg light"}` : ""}>
                  International
                </Link>
                <Link
                  to={`/career/${company._id}/${lang}`}
                  className={activePage === "career" ? `${theme +"-bg light"}` : ""}>
                  Carrières
                </Link>
              </>
              : <>
                <Link
                  to={`/team/${company._id}/${lang}`}
                  className={activePage === "team" ? `${theme +"-bg light"}` : ""}>
                  Équipes
                </Link>
                <Link
                  to={`/career/${company._id}/${lang}`}
                  className={activePage === "career" ? `${theme +"-bg light"}` : ""}>
                  Carrières
                </Link>
              </>
            }
   
   
          </>
          :
          <>
             <Link
              to={`/home-group/${company._id}/${lang}`}
              className={activePage === "home-group" ? `${theme +"-bg light"}` : ""}>
              Accueil
            </Link>
            <Link
              to={`/group/${company._id}/${lang}`}
              className={activePage === "group" ? `${theme +"-bg light"}` : ""}>
              Groupe
            </Link>
            <Link
              to={`/school/${company._id}/${lang}`}
              className={activePage === "school" ? `${theme +"-bg light"}` : ""}>
              École
            </Link>
            <Link
              to={`/domains-list/${company._id}/${lang}`}
              className={activePage === "domains" ? `${theme +"-bg light"}` : ""}>
              Domaines d'activités
            </Link>
            <Link
              to={`/rse/${company._id}/${lang}`}
              className={activePage === "rse" ? `${theme +"-bg light"}` : ""}>
              Entreprise à mission
            </Link>
            <Link
              to={`/innovations/${company._id}/${lang}`}
              className={activePage === "innovation" ? `${theme +"-bg light"}` : ""}>
              Innovations
            </Link>
            <Link
              to={`/approach/${company._id}/${lang}`}
              className={activePage === "approach" ? `${theme +"-bg light"}` : ""}>
              Approche globale
            </Link>
            <Link
              to={`/international/${company._id}/${lang}`}
              className={activePage === "international" ? `${theme +"-bg light"}` : ""}>
              International
            </Link>
            <Link
              to={`/career/${company._id}/${lang}`}
              className={activePage === "career" ? `${theme +"-bg light"}` : ""}>
              Carrières
            </Link>
          </>

        }
      </>
      )
    }
  }

  return (
    <div className={`nav-container site-nav ${theme}`}>
      <div className="nav site-nav">

        <div className="nav-links site-nav">
          {renderLinks()}
        </div>


      </div>
    </div>
  )
}
