import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as Icons from '@fortawesome/free-solid-svg-icons';

import edit from '../../assets/images/icons/edit.svg';
import remove from '../../assets/images/icons/remove.svg';

import './keysfigures.scss';

export default function EditMapItems({ items , setRemoveAction , handleChange  , theme , add = "Ajouter" }) {

  
  const [ displayAddItem , setDisplayAddItem ] = useState(false);
  const [ displayEditItem , setDisplayEditItem] = useState(false);
  const [ key, setItem] = useState();
  const [ itemIndex , setItemIndex ] = useState();

  function addKey() {
    var itemsList = items ? items : [];
    if (key) {
      itemsList.push(key)
      handleChange(itemsList);
      setDisplayAddItem(false);
      setItem();
    }
  };

  function submitkey( ) {
    var itemsList = [...items];
    itemsList[itemIndex] = key;
    handleChange(itemsList);
    setDisplayEditItem(false);
    setItem();
    setItemIndex();
  }

  function removeKey(index) {
    var itemsList = [...items];
    handleChange(itemsList.filter(item => itemsList.indexOf(item) !== index ));
  }

  function editItem(key, index ) {

    setItemIndex(index);
    if (!key.pos) key.pos = index + 1
    setItem(key);
    setDisplayEditItem(true);
  }

  function addNewKey(e) {
    setDisplayAddItem(true)
  }


  function renderItemsList() {
    if (items && items.length) {
      return (
        <>
          {items.sort((a , b ) => a.pos - b.pos ).map(( key, i ) => {
            return (
              <div key={"key" + i } className="key-row">

                <div className="key-infos">
                  <h3 className="bold">
                    <span className={theme + "-color"}>{key?.title}</span>
                    <span> - {key.contact}</span>
                  </h3>
                </div>

                <div className="icons">
                  <button
                    type="button"
                    onClick={() => editItem(key, i)}>
                    <img src={edit} alt="edit"/>
                  </button>
                  <button
                    type="button"
                    onClick={() => setRemoveAction({ function: () => removeKey(i) })} >
                    <img src={remove} alt="remove"/>
                  </button>
                </div>
              </div>
            ) 
          })}
        </>
      );
    }
  }

  return (
    <div className="keys-list">
      {displayEditItem && key &&

        <div className="edit-key">
      
          <label htmlFor="title">Titre</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key?.title}
            onChange={(e) => setItem({...key, title: e.target.value })}
            name="title"
          />

          <label htmlFor="contact">Titre</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.contact}
            onChange={(e) => setItem({...key, contact: e.target.value })}
            name="contact"
          />


          <label htmlFor="count1">Nombre 1</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.count1 ? key.count1 : null }
            onChange={(e) => setItem({...key, count1: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null })}
            name="count1"
          />

          <label htmlFor="count2">Nombre 2</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={key.count2 ? key.count2 : null }
            onChange={(e) => setItem({...key, count2: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null })}
            name="count2"
          />


          <button className="btn success" type="button" onClick={() => submitkey()}>Valider</button>
          <button className="btn primary" type="button" style={{ marginLeft: 10 }} onClick={() => setDisplayEditItem(false)}>Annuler</button>
        </div>
      }
      {displayAddItem &&

        <div className="edit-key">
          <label htmlFor="title">Titre</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={""}
            onChange={(e) => setItem({...key, title: e.target.value })}
            name="title"
          />

          <label htmlFor="contact">Contact</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={""}
            onChange={(e) => setItem({...key, contact: e.target.value })}
            name="contact"
          />


          <label htmlFor="count1">Nombre 1</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={0}
            onChange={(e) => setItem({...key, count1: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null })}
            name="count1"
          />

          <label htmlFor="count2">Nombre 2</label>
          <input
            style={{ marginBottom : 10 }}
            defaultValue={0}
            onChange={(e) => setItem({...key, count2: !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null })}
            name="count2"
          />
          <button className="btn success" type="button" onClick={() => addKey()}>Valider</button>
          <button className="btn primary" type="button" style={{ marginLeft: 10 }} onClick={() => setDisplayAddItem(false)}>Annuler</button>
        </div>
      }
      {!displayAddItem && !displayEditItem &&
        <>
        {renderItemsList()}
        <button
          className="btn primary"
          type="button"
          onClick={(e) => addNewKey(e)}>{add}</button>
        </>
      }
    </div>
  )
}
