import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';

import EditPage from '../../partials/EditPage';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';
import { getTalentsAction } from '../../../actions/talentsActions';

import '../../../styles/pages-sites.scss';

export default function Team({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , requireRight , setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'team';

  const { handleSubmit } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const team = useRef({});

  const [ pages , setPages ] = useState([]);

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();
  const [ isUpdated , setIsUpdated ] = useState(true);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ index , setIndex ] = useState();
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
    setPages([]);
    dispatch({
      type:'RESET_PAGE',
    });
  },[dispatch , param.companyId]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("team");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setIsMounted(false);
      dispatch({
        type:'RESET_PAGE',
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireRight("talent")) getTalentsAction(dispatch , lang);
    // eslint-disable-next-line
  }, [context.auth.userAuth]);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    team.current = null;
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      console.log(context.theme.page)
      team.current = {...context.theme.page};
      if (context.theme.page?.blocs) {
        setPages(context.theme.page.blocs)
      }
      setIsMounted(true);
      setIsSaved(true);
    } else {
      team.current = null;
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  // useEffect(() => {
  //   if (team.current) {
  //     if (team.current && context.theme.page && JSON.stringify(team.current) === JSON.stringify(context.theme.page)) {
  //       setIsSaved(true);
  //     } else {
  //       setIsSaved(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[team , context.theme.page])


  useEffect(() => {
    if (context.talents.message === 'Talent successfully updated!' || context.talents.message === "talent saved") {
      setIsSaved(true);  
      dispatch({
        type:'MESSAGE_TALENTS',
        payload: null
      });

    };

    // eslint-disable-next-line
  },[context.talents.message]);

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  useEffect(() => {
    if (!displaySelectMedia) {
      setIndex();
    }
  }, [displaySelectMedia])

  console.log(mediaKey);
  function submitMedia(value) {
    var pageList = [...pages]
    switch (mediaKey) {
      case "cover":
        team.current.cover = value[0];
        break;

      case "file":
        pageList[index].file = value[0];
        setPages(pageList)
        submitTeam({ pages : pageList })        
      break;

      case "pageCover":
        pageList[index].picture = value[0];
        setPages(pageList)
        submitTeam({ pages : pageList })        
      break;
  
      default:
        break;
    }
    setIsSaved(false);
    setIsUpdated(!isUpdated);
  };

  function handleChangeSection(obj) {
    let data = {...team.current, ...obj}
    data = Object.assign({} , data );
    team.current = data;
    if (isSaved) {
      setIsSaved(false);
    }
    setIsUpdated(!isUpdated);

  };

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('cover');
    setMediaType('image');
    if (team && team.cover && team.cover._id) {
      var pictureArray = [];
      pictureArray.push(team.cover._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectPicture(index , key ) {
    console.log(key)
    setIndex(index)
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(key);
    setMediaType(key === 'file' ? 'file': 'image');
  }

  const submitTeam = (values)  => {
    var teamData = {...values, ...team.current};
    teamData.blocs = pages ;
    var obj = {};
    for (const [key, value] of Object.entries(teamData)) {
        if( key !== 'articles' ) obj[`${page}.${key}`] = value;
    }

    setPublishIsLoading(true)

    if (param.companyId === "5e9ec41b25ab1e65768e2ff0") {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );
    } else {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );
    }

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(team.current && team.current.cover && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === team.current.cover )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="team" />
      } else if (team.current.cover.path) {
        return <img src={API_URL + '/' + team.current.cover.path} alt="team" />
      }
    }
  }

  function renderEdit() {

    return (
      <div className="list-item transition edit-row">
        {isMounted && team?.current &&
          <form onSubmit={handleSubmit(submitTeam)} autoComplete="nope">
        
              <div className="form-row">
                <div className="col-1">

                  <label htmlFor="title">Titre de la page</label>
                  <input
                    name="title"
                    style={{ marginBottom: 10 }}
                    onChange={(e) => handleChangeSection({ title : e.target.value })}
                    defaultValue={team?.current?.title ? team.current.title  : "" }
                  />

                  <div style={{ display: "flex" , marginBottom: 10 }}>
                    <input id="titleIsWhite"
                      onChange={() => handleChangeSection({titleIsWhite: !team.current.titleIsWhite })}
                      type="checkbox" className="switch" checked={team?.current?.titleIsWhite}
                      />
                    <label htmlFor="titleIsWhite">{team?.current?.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>
                  </div>
                 
                
                  <div className="title">
                    <h2>Équipe</h2>
                  </div>

                  {pages && pages.sort((a , b ) => a.pos - b.pos ).map((page , i) => <EditPage
                    key={'pages' + i }
                    pageValue={page}
                    pages={pages}
                    setPages={(array) => {
                      setPages(array);
                      if (isSaved) {
                        setIsSaved(false);
                      }
                    }}
                    companyId={param.companyId}
                    context={context}
                    isSubTitle={true}
                    setRemoveAction={setRemoveAction}
                    openSelectPicture={openSelectPicture}
                    dispatch={dispatch}
                    index={i}
                    />)
                  }

                  <div className="btn-container" >
                    <button
                      className={`btn primary ${theme}-bg`}
                      style={{ marginBottom: 20}}
                      onClick={() => setPages([...pages ,{ title: null , pos: pages.length + 1 , blocs: [], cover: null } ]) }
                      type="button"
                      >
                      Ajouter un membre
                    </button>
                  </div>
                  
                </div>

                <div className="col-2">

                  <div className="btn-actions-container" >
                    {publishIsLoading ?
                      null
                      :
                      <button
                        className={`btn primary publish ${theme}-bg`}
                        type="submit"
                        >
                        Publier
                      </button>
                    }
                  </div>

                  <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1132 x 660 px</span></label>
                  {renderCoverImg()}
                  <div className="btn-container">
                    
                    <button
                      type="button"
                      className={`btn primary ${theme}-bg`}
                      onClick={() => openSelectCover()}
                      >
                      Parcourir
                    </button>

                    {team?.current?.cover &&
                      <button
                        type="button" 
                        className={`btn primary ${theme}-bg`}
                        onClick={() => setRemoveAction({ function:() =>  handleChangeSection( { cover: null })}) }
                        >
                        Supprimer
                      </button>
                    }
                  </div>
                </div>
              </div>
              
          </form>
        }
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />    
      {isMounted && team.current && <div id="innovation" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition de la page équipes ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
