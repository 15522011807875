import {
  IS_AUTH,
  ERROR_AUTH,
  USER_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_TOAST
} from "../actions/types";

const initialState = {
  isAuth: false,
  error: null,
  userAuth: null,
  message: null,
  toast: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case IS_AUTH:
      return {...state , isAuth: action.payload}
    case ERROR_AUTH:
      return {...state , error: action.payload}
    case USER_AUTH:
      return {...state , userAuth: action.payload}
    case SET_TOAST:
      return {...state , toast: action.payload}
    case FORGOT_PASSWORD:
      return {...state , message : { forgot: action.payload.message}}
    case RESET_PASSWORD:
      return {...state , message : { reset: action.payload.message}}
    default:
      return {...state};
  }
}