import React, { useEffect , useCallback , useState , useRef } from 'react';
import { useParams , Link , Prompt } from "react-router-dom";

import Select from "react-select";
import { companiesColors } from '../../../utils/utils';

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import TextEditor from '../../partials/TextEditor';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getMediasAction } from '../../../actions/mediasActions';
import { getDomainsAction } from '../../../actions/domainsActions';
import { getSubDomainAction , updateSubDomainAction  } from '../../../actions/subDomainsActions';
import { getProjectsAction } from '../../../actions/projectsActions';

import './domains.scss';
import remove from '../../../assets/images/icons/remove.svg';


export default function EditSubDomain({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , setActivePage , theme , lang }) {

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const param = useParams();

  const { handleSubmit, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const subDomainRef = useRef();
  const subDomain = subDomainRef.current;
  const [ isUpdated , setIsUpdated ] = useState(false);

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ projectsOptions , setProjectsOptions ] = useState();
  const [ selectedProjects , setSelectedProjects ] = useState();
  const [ defaultProjects , setIsDefaultProjects] = useState(true);
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getDomainsAction(dispatch , lang );
    getSubDomainAction(dispatch , param.subDomainId );
    getMediasAction(dispatch);
    const projectParam = {
      companyId: param.companyId,
      lang : lang
    } 
    getProjectsAction(dispatch , projectParam );
  },[dispatch , param.companyId , param.subDomainId ,  lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(false);
    setDisplaySiteNav(true)
    setActivePage("domains");
    getCompany();
    dispatch({
      type:'MESSAGE_SUB_DOMAINS',
      payload: null
    });
   
    return () => {
      setDisplaySelectLang(false);
      subDomainRef.current = null;
      dispatch({
        type : 'GET_SUB_DOMAIN',
        payload : null
      });
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (context.subdomains.subdomain && context.projects.projectsList) {
      subDomainRef.current = {...context.subdomains.subdomain };
      setIsUpdated(!isUpdated)
      setProjectsOptions(context.projects.projectsList.filter(p => p.company === param.companyId && p.status === "published" ).map(item => { return { value: item._id , label: item.name}}));
    };

    dispatch({
      type:'MESSAGE_DOMAINS',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.subdomains.subdomain , context.projects.projectsList]);

  useEffect(() => {
    if (context.subdomains.subdomain && projectsOptions ) {
      var projects = [];
      if (context.subdomains.subdomain.projects && context.subdomains.subdomain.projects.length) {
        projectsOptions.forEach(item => {
          if (context.subdomains.subdomain.projects.find(project =>  project === item.value)) {
            projects.push(item);
          }
        })
        setSelectedProjects(projects);
        setIsDefaultProjects(false);
      }

    }
    // eslint-disable-next-line
  }, [projectsOptions])

  useEffect(() => {
    if (defaultProjects) setSelectedProjects([]);
  },[defaultProjects]);

  useEffect(() => {
    if (context.subdomains.message === 'Subdomain successfully updated!' || context.subdomains.message === 'domain saved' ) {

      setPublishIsLoading(false)

      dispatch({
        type:'MESSAGE_SUB_DOMAINS',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le sous-domaine a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.subdomains.message , context.subdomains.error]);


  function submitMedia(value) {
    let updatedSub = {...subDomainRef.current}
    if (mediaKey === "icon") {
      updatedSub.icon = value[0];
    } 
    if (mediaKey === "cover") {
      updatedSub.cover = value[0];
    }
    if (mediaKey === "card") {
      updatedSub.card = value[0];
    }
    if (mediaKey === "pictures") {
      updatedSub.pictures = value
    }
    if (mediaKey === "files") {
      let list = updatedSub.files
      list.push(value[0]);
      updatedSub.files = list
    }
    subDomainRef.current = {...updatedSub};
    setIsUpdated(!isUpdated);

  };

  function openSelectCard() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaType('image');
    setMediaKey('card');
    if (subDomain.card) {
      var cardArray = []
      cardArray.push(subDomain.card);
      setSelectedMediaList(cardArray)
    }
  }


  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('cover');
    setMediaType('image');
    if (subDomain && subDomain.cover) {
      var coverArray = [];
      coverArray.push(subDomain.cover);
      setSelectedMediaList(coverArray)
    }
  }

  function openSelectIllus() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(true);
    setMediaKey('pictures');
    setMediaType('image');
    if (subDomain && subDomain.pictures) {
      setSelectedMediaList(subDomain.pictures)
    }
  }

  function openSelectFiles() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('files');
    setMediaType('file');
  }

  function openSelectIcon() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('icon');
    setMediaType('image');
    if (subDomain && subDomain.icon) {
      var iconArray = [];
      iconArray.push(subDomain.icon);
      setSelectedMediaList(iconArray)
    }
  }

  function handleChange( value , obj) {
    var data = Object.assign({} , subDomainRef.current );
    data[obj] = value
    subDomainRef.current = data
    setIsUpdated(!isUpdated);
  };

  const submitSubDomain = (values)  => {
    var subDomainData = {...values , ...subDomainRef.current};
    subDomainData.lang = lang;
    subDomainData.company = param.companyId;
    subDomainData.projects = selectedProjects ? selectedProjects.map(item => { return item.value }) : []
    if (subDomain && subDomain._id) {
      subDomainData._id = subDomain._id
      updateSubDomainAction(dispatch , subDomainData );
    }
    dispatch({
      type:'ERROR_DOMAINS',
      payload: null
    });
    dispatch({
      type:'MESSAGE_DOMAINS',
      payload: null
    });
  };

  function renderIconImg() {
    var imgFound;
    if(subDomain && subDomain.icon && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === subDomain.icon )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" className="icon"/>
    }
  }

  function renderCardImg() {
    if(subDomain && subDomain.card && context.medias.mediasList) {
      var cardFound = context.medias.mediasList.find(item => item._id === subDomain.card )
      if (cardFound) return <img src={API_URL + '/' +cardFound.path} alt="card"/>
    }
  }

  function renderCoverImg() {
    var imgFound;
    if(subDomain && subDomain.cover && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === subDomain.cover )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover" />
    }
  }

  function renderIllusImg() {
    if(subDomain && subDomain.pictures && context.medias.mediasList) {
      return subDomain.pictures.map((illu , i )=> {
        var imgFound = context.medias.mediasList.find(item => item._id === illu )
        if (imgFound) return <img key={"illu" + i} src={API_URL + '/' +imgFound.path} alt="illu"/>
        return null;
      });
    }
  }

  function renderFiles() {
    if(subDomain && subDomain.files && subDomain.files.length > 0 && context.medias.mediasList) {
      return subDomain.files.map((file , i ) => { 
        var fileFound = context.medias.mediasList.find(item => item._id === file );
        if (fileFound) return (
          <div
            key={i}
            className="file-row bold">
            <button
              type="button"
              className="bold"
              onClick={() =>  window.open(API_URL + '/' + fileFound.path)}
              >
              {fileFound.name}
            </button>
            <button
              type="button"
              onClick={() => handleChange( subDomain.files.filter(f => f !== fileFound._id ) , 'files') }
              >
              <img src={remove} alt="remove" />
            </button>
          </div>
        )
        return null;
      });
    }
  }

  function renderEdit() {
    return (
      <div style={{ marginBottom: 70 }} className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitSubDomain)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  {renderIconImg()}
                  <h1>{subDomain && subDomain.title ? subDomain.title : null }</h1>
                </div>

                <div className="select-icon">
                  
                  <button type="button" className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectIcon(true)}
                    >
                    Séléctionner une icône
                  </button>

                  {subDomain && subDomain.icon &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'icon' )}
                      >
                      Supprimer l'icône
                    </button>
                  }
                </div>

                <label htmlFor="title">Titre</label>
                <input
                  name="title"
                  onChange={(e) => handleChange( e.target.value , 'title' ) }
                  defaultValue={subDomain && subDomain && subDomain.title ? subDomain.title  : undefined }
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>
                
                <input id="isWhite"
                  onChange={(e) => handleChange( !e.target.checked , 'titleIsWhite')}
                  type="checkbox"
                  className="switch"
                  defaultChecked={subDomain && subDomain.titleIsWhite ? false : true }
                  style={{ height: 20 }}
                  />
                <label htmlFor="isWhite">{subDomain && subDomain.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>

                <div className="section"></div>


                <label htmlFor="position">Position</label>
                <input
                  name="position"
                  onChange={(e) => handleChange( e.target.value , 'position')}
                  type="number"
                  defaultValue={subDomain && subDomain.position ? subDomain.position  : 0 }
                  style={{ marginBottom: 12 }}
                />

                <label htmlFor="why">Titre paragraphe</label>
                <textarea
                  name="why"
                  onChange={(e) => handleChange( e.target.value , 'why')}
                  defaultValue={subDomain?.why ? subDomain?.why  : undefined }
                />
                <p className="error">{errors && errors.why && errors.why.message}</p>

                <label htmlFor="editor">Paragraphe</label>
                <TextEditor
                  value={subDomain && subDomain.text ? subDomain.text : ""}
                  onChange={( value) => handleChange( value , 'text')}
                />
                <p className="error">{errors && errors.text && errors.text.message}</p>
                                
              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>


                <label>Photo dans la liste <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1030 x 516 px</span></label>
                {renderCardImg()}
                <div className="btn-container">
                  <button className="btn primary"
                    type="button"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectCard(true)}
                    >
                    Parcourir
                  </button>

                  {subDomain && subDomain.card &&
                    <button className="btn primary"
                      type="button"
                      onClick={() => handleChange( null , 'card' )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 350 px</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {subDomain && subDomain.cover &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => handleChange( null , 'cover' )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Photos d'illustration<br/><span className="note">minimum 2058 x 1160 px</span></label>
                  <div className="illus-list">
                    {renderIllusImg()}
                  </div>
                  <div className="btn-container">

                    <button
                      type="button"
                      className="btn primary"
                      style={{ marginRight: 20}}
                      onClick={() => openSelectIllus(true)}
                      >
                      Parcourir
                    </button>

                  {subDomain && subDomain.pictures && subDomain.pictures.length > 0 &&
                    <button
                      type="button"  
                      className="btn primary"
                      onClick={() => handleChange([] , 'pictures' )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <label>Fichier(s)<br/><span className="note">nb: Taille miniature 294 x 390 px</span></label>
                {renderFiles()}
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn primary"
                    style={{ marginRight: 20}}
                    onClick={() => openSelectFiles(true)}
                    >
                    Parcourir
                  </button>

                </div>

                <label>Projets associés</label>
                <p className="default-projects" htmlFor="isDefault">{defaultProjects ? <span>Par défault les 4 derniers projets sont sélectionnés</span> : <span>Administration personnalisée des projets associés</span> }</p>
                <input id="isDefault"
                  onChange={() => setIsDefaultProjects(!defaultProjects)}
                  type="checkbox"
                  className="switch"
                  checked={defaultProjects}
                  style={{ height: 20 }}
                  />
                {!defaultProjects &&
                  <Select
                    styles={customStyles}
                    value={selectedProjects}
                    placeholder={'Sélectionner'}
                    options={projectsOptions}
                    onChange={(value) => setSelectedProjects(value)}
                    isMulti
                  />
                }

                {/* <label>Projets associés
                  <br/><span className="note">Par défault les 4 derniers projets sont sélectionnés</span>
                </label>
                <Select
                  styles={customStyles}
                  value={selectedProjects}
                  placeholder={'Sélectionner'}
                  options={projectsOptions}
                  onChange={(value) => setSelectedProjects(value)}
                  isMulti
                /> */}

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <Prompt when={true} message={"Voulez-vous vraiment quitter cette page ?"}/>        
      <div className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">
              <Link to={`/edit-domain/${param.companyId}/${param.domainId}`}>
                <button
                  className="btn primary back">
                  Retour
                </button>
              </Link>

              <div className="title">
                <h1>Edition d'un sous-domaine ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {subDomain && renderEdit()}
          </div>

        </div>
      </div>
    </>
  )
}
