import {
  GET_DOMAINS,
  GET_DOMAIN,
  MESSAGE_DOMAINS,
  UPDATE_DOMAIN,
  POST_DOMAIN,
  ERROR_DOMAINS,
  DELETE_DOMAIN
} from "../actions/types";

import { sortByUpdateDate } from './../utils/utils';

const initialState = {
  domainsList: [],
  domain: null,
  error: null,
  message: null
};

export default function domainsReducer(state = initialState, action) {

  var domainsList = state.domainsList;

  switch (action.type) {

    case GET_DOMAINS:
      return {...state , domainsList: sortByUpdateDate(action.payload)}
    
    case GET_DOMAIN:
      return {...state , domain: action.payload}

    case POST_DOMAIN:

      var list = [...domainsList];
      list.push(action.payload.domain);

      return {...state ,
        domainsList: sortByUpdateDate(list),
        domain: action.payload.domain,
        message: action.payload.message
      };

    case UPDATE_DOMAIN:
    
      if (domainsList) {
        var foundIndex = domainsList.indexOf(domainsList.find( domain => domain._id === action.payload.domain._id));
        if (foundIndex) {
          domainsList[foundIndex] = action.payload.domain;
        }
      }


      return {
        ...state,
        domainsList: sortByUpdateDate(domainsList),
        domain: action.payload.domain,
        message: action.payload.message
      }
    
    case DELETE_DOMAIN:
      domainsList = domainsList.filter( domain => domain._id !== action.payload)
      return {...state , domainsList: domainsList }

    case MESSAGE_DOMAINS:
      return {...state , message: action.payload }

    case ERROR_DOMAINS:
      return {...state , error: action.payload }

    default:
      return {...state};
  }
}