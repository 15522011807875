import React, { useEffect , useCallback , useState } from 'react';
import { useParams , useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction , getCompaniesAction } from '../../../actions/companiesActions';
import {
  getAgenciesAction ,
  createAgencyAction,
} from '../../../actions/agenciesActions';
import { getContactsAction } from '../../../actions/contactsActions';

import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 
import AgencyRow from '../agencies/AgencyRow';

import './agenciesList.scss';

export default function AgenciesList({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav, requireRight ,  setActivePage , theme , lang }) {

  const [ context, dispatch ] = useGlobalContext();

  const param = useParams();
  const history = useHistory();

  const [ removeAction , setRemoveAction ] = useState();

  const getAgencies = useCallback(() => {
    getAgenciesAction(dispatch ,  param );
    if (requireRight('contact')) getContactsAction(dispatch ,  param.lang );
    dispatch({
      type : 'GET_AGENCY',
      payload : null
    });
  }, [dispatch , param , requireRight])

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getCompaniesAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setActivePage("agencies");
    setDisplaySiteNav(false);
    getAgencies();
    getCompany();
    return () => {
      setDisplaySelectLang(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    history.push(`/agencies-list/${param.companyId}/${lang}`);
    // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    getAgencies();
    // eslint-disable-next-line
  }, [param.lang]);

  useEffect(() => {
    if(context.agencies.message === "agency saved") {
      history.push(`/edit-agency/${param.companyId}/${context.agencies.agency._id}`);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'article a bien été créé",
          type: "success"
        }
      });

      dispatch({
        type : 'MESSAGE_AGENCIES',
        payload : null
      });
    }
    // eslint-disable-next-line
  }, [context.agencies.message])


  function createAgency() {
    let reqBody = {
      lang: lang,
      company: param.companyId
    }
    createAgencyAction(dispatch , reqBody);
  }

  function renderAgenciesList() {
    if (context.agencies.agenciesList && context.agencies.agenciesList.length) {
      return context.agencies.agenciesList.map((agency) => {
        return <AgencyRow agency={agency} companyId={param.companyId} setRemoveAction={setRemoveAction} dispatch={dispatch} theme={theme} key={agency._id} />
      })
    } else {
      return <span>Aucune agence n'a été trouvée</span>
    }
  }

  return (    
    <div className={`page-container ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="title">
            <h1>Agences</h1>
            <div className="bar primary"></div>
          </div>
 
           <button
             onClick={() => createAgency()}
             className="btn primary">
             Créer une nouvelle agence
           </button>

        </div>

        <div className="list-container">
          {renderAgenciesList()}
        </div>

      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </div>
  )
}
