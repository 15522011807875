import { getData  , postFiles , putData , deleteData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_FILES,
  POST_FILE,
  PUT_FILE,
  ERROR_FILES,
  DELETE_FILE
} from "./types"

export async function getMediasAction(dispatch) {
  let url = "/file";
  var files;

  await getData(ERROR_FILES, url, dispatch , true ).then((response)=>{
    files = response.files
  });

  dispatch({
    type : GET_FILES,
    payload : sortByUpdateDate(files)
  });

};


export async function putMediaAction( dispatch , id, data  ) {
  
  const url = `/file/${id}`;
  var putFile;

  await putData(PUT_FILE, ERROR_FILES ,  url, dispatch, data , true ).then((response)=>{
    putFile = response.file
  });
  
  dispatch({
    type : PUT_FILE,
    payload : putFile
  });

};

export async function postMediaAction( dispatch , data ) {
  
  const url = "/file";
  var addFile;

  await postFiles(POST_FILE, ERROR_FILES , url, dispatch, data , true ).then((response)=>{
    addFile = response.files
  });

  dispatch({
    type : POST_FILE,
    payload : addFile
  });

};

export async function deleteMedia(dispatch , id) {
  const url = "/file/" + id;
  var deleteFile;

  await deleteData(ERROR_FILES, url, dispatch, true ).then((response)=>{
    deleteFile = response
  });

  if(deleteFile) {
    dispatch({
      type : DELETE_FILE,
      payload : id
    });
  }
}