import React, { useState , useEffect } from 'react';
import { companiesColors  } from '../../../utils/utils';
import photo1 from '../../../assets/images/gabarit/photo_1.png';
import photo2 from '../../../assets/images/gabarit/photo_2.png';
import photo3 from '../../../assets/images/gabarit/photo_3.png';
import photo4 from '../../../assets/images/gabarit/photo_4.png';
import photo5 from '../../../assets/images/gabarit/photo_5.png';
import photo6 from '../../../assets/images/gabarit/photo_6.png';
import photo7 from '../../../assets/images/gabarit/photo_7.png';
import photo8 from '../../../assets/images/gabarit/photo_8.png';
import photo9 from '../../../assets/images/gabarit/photo_9.png';
import photo10 from '../../../assets/images/gabarit/photo_10.png';

import './code.scss';

export default function CodePage({ setDisplayNavBar, setActivePage , theme , setDisplaySiteNav }) {


  const [ title , setTitle ] = useState(`<h1>L'aménagement !</br><span style='color:${companiesColors[theme] ? companiesColors[theme]  : companiesColors['K'] }'>durable</span><span style="font-style: italic;">des territoires<span></h1>`);

  useEffect(() => {
    setDisplayNavBar(true);
    setActivePage("code");
    setDisplaySiteNav(false)
    // eslint-disable-next-line
  }, []);


  return (
    <div className={`page-container ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="title">
            <h1>Mise en forme des titres</h1>
            <div className="bar primary"></div>
          </div>

        </div>

        <div className="code-container">

          <div className="code">

            <div dangerouslySetInnerHTML={{ __html: title}} >

            </div>
              
            <textarea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Recherche"
              />

          </div>
         
        </div>


        <div className="title-row space-between">

          <div className="title">
            <h1>Format des images</h1>
            <div className="bar primary"></div>
          </div>

        </div>

        <div className="code-container">

          <div className="images">
            <img src={photo1} alt="recommandation-1" />
            <img src={photo2} alt="recommandation-2" />
            <img src={photo3} alt="recommandation-3" />
            <img src={photo4} alt="recommandation-4" />
            <img src={photo5} alt="recommandation-5" />
            <img src={photo6} alt="recommandation-6" />
            <img src={photo7} alt="recommandation-7" />
            <img src={photo8} alt="recommandation-8" />
            <img src={photo9} alt="recommandation-9" />
            <img src={photo10} alt="recommandation-10" />
          </div>  
         
        </div>

      </div>
    </div>
  )
}
