import { getData , postData , putData ,  deleteData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_AGENCIES,
  POST_AGENCY,
  GET_AGENCY,
  DELETE_AGENCY,
  UPDATE_AGENCY,
  ERROR_AGENCIES,
  GET_ZONES
} from "./types"

export async function getZonesAction(dispatch , lang , company ) {
  let url = `/agency/list/lang/${lang}/${company}`;

  var zones;

  await getData(ERROR_AGENCIES, url, dispatch , true ).then((response)=>{
    zones = response.zones
  });

  dispatch({
    type : GET_ZONES,
    payload : zones.map(z => ({ label: z , value: z }))
  });

};

export async function getAgenciesAction(dispatch , params ) {
  let url = "/agency/lang/" + params.lang;
  var agencies = [];

  await getData(ERROR_AGENCIES, url, dispatch , true ).then((response)=>{
    agencies = response.agencies.filter(agency => agency.lang === params.lang ).filter(agency => agency.company._id === params.companyId);
  });

  dispatch({
    type : GET_AGENCIES,
    payload : sortByUpdateDate(agencies)
  });

};

export async function createAgencyAction(dispatch , params ) {
  let url = "/agency"
  var agency;

  await postData(POST_AGENCY , ERROR_AGENCIES , url, dispatch , params, true ).then((response)=>{
    agency = response
  });

  dispatch({
    type : POST_AGENCY,
    payload : agency
  });

}

export async function getAgencyAction(dispatch , id ) {
  let url = "/agency/" + id
  var agency;

  await getData(ERROR_AGENCIES, url, dispatch , true ).then((response)=>{
    agency = response.agency
  });

  dispatch({
    type : GET_AGENCY,
    payload : agency
  });

}

export async function updateAgencyAction(dispatch , param ) {
  let url = "/agency/" + param._id
  var agency;

  await putData(UPDATE_AGENCY, ERROR_AGENCIES ,  url, dispatch, param , true ).then((response)=>{
    agency = response
  });

  dispatch({
    type : UPDATE_AGENCY,
    payload : agency
  });

}

export async function deleteAgencyAction(dispatch , id ) {
  let url = "/agency/" + id

  await deleteData(ERROR_AGENCIES, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "L'agence a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_AGENCY,
    payload : id
  });

}