import React , { useState , useEffect , useRef } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import { companiesColors } from '../../../utils/utils';
import { createContactAction , updateContactAction , deleteContactAction  } from '../../../actions/contactsActions';
import SelectMedia from '../medias/SelectMedia';
 
import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';
// import Creatable from 'react-select/creatable';
import Select from 'react-select';

export default function ContactRow({ contact , companyId , agencyId , lang ,  setRemoveAction , theme , isEdit , setAddContact }) {

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }


  const [ isEditState , setIsEditState ] = useState(false);

  const [ context, dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors } = useForm();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ selectedTags , setSelectedTags ] = useState([]);
  const [ selectedDomains , setSelectedDomains ] = useState([]);
  const [ picture , setPicture ] = useState();
  const [ phone , setPhone ] = useState();

  let tagsOptions = context.contacts.tags.map(item => ({ value: item._id , label: item.value }));
  // tagsOptions = [...tagsOptions , { value: 'Tous types' , label: 'Tous types'}];
  let domainsOptions = context.domains.domainsList.filter(d => d.company === companyId).map(item => ({ value: item._id , label: item.title }));;
  // domainsOptions = [...domainsOptions , { value: 'Tous domaines' , label: 'Tous domaines'}];

  const contactRef = useRef();

  useEffect(() => {

    if (contact && contact.picture) {
      setPicture(contact.picture);
    }

    if (contact && contact.tags) {
      setSelectedTags(tagsOptions.filter(item => {
        if(contact.tags.find(id => id === item.value || id === 'Tous types')) {
          return item; 
        }
        return false;
      }));
      setSelectedDomains(domainsOptions.filter(item => {
        if(contact.domains.find(id => id === item.value )) {
          return item; 
        }
        return false;
      }));
    }

    if (contact && contact.phone ) {
      setPhone(contact.phone);
    }

    if (isEdit) {
      setIsEditState(true)
    }

    if (isEditState) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    dispatch({
      type:'MESSAGE_CONTACTS',
      payload: null
    });
    return () => {
      setPicture();
    }
    // eslint-disable-next-line
  }, [isEditState, contact]);

  useEffect(() => {
    if (context.contacts.message === 'Contact successfully updated!' || context.contacts.message === 'contact saved' ) {
      
      dispatch({
        type:'MESSAGE_CONTACTS',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le contact a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.contacts.message , context.contacts.error]);



  function closeEditState() {
    isEdit && setAddContact(false);
    setIsEditState(false);
  }

  function openSelectPicture() {
    setDisplaySelectMedia(true);
    if (contact && contact.picture) {
      var coverArray = []
      coverArray.push(contact.picture);
      setSelectedMediaList(coverArray)
    }
  }

  const submitUser = (values)  => {
    var contactData = values;
    contactData.agency = agencyId;
    contactData.company = companyId;
    contactData.picture = picture ? picture : null ;
    contactData.phone = phone ? phone : "";
    contactData.domains = selectedDomains ? selectedDomains.map(item => item.value) : []
    contactData.tags = selectedTags ?  selectedTags.map(item => item.value) : []
    if (contact && contact._id) {
      contactData._id = contact._id
      updateContactAction(dispatch , contactData );
    } else {
      createContactAction(dispatch , contactData );
      setAddContact(false)
    }
    dispatch({
      type:'ERROR_CONTACTS',
      payload: null
    });
    dispatch({
      type:'MESSAGE_CONTACTS',
      payload: null
    });
  };

  // function handleChangeTags(newValue , actionMeta) {
  //   if (actionMeta.action === "create-option") {
  //     createTagAction(dispatch, { 
  //       lang: lang,
  //       type: "contact",
  //       value: newValue.find(item => item.__isNew__ ).value
  //     })
  //   }
  //   if (actionMeta.action === "select-option") {
  //     setSelectedTags(newValue)
  //   }
  //   if (actionMeta.action === "clear") {
  //     setSelectedTags([])
  //   }
  //   if (actionMeta.action === "remove-value") {
  //     setSelectedTags(newValue)
  //   }
  // };

  function handleChangeDomains(newValue) {
    setSelectedDomains(newValue)
  };


  function renderPictureImg() {
    var imgFound;
    if(picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === picture )
      if (imgFound) return <img src={API_URL + '/' +imgFound.path} alt="cover"/>
    }
  }

  function renderUpdatedLabel() {
    var updatedDate = new Date(contact.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }
  
  function renderRow() {
      return (
        <div className="list-item contact-row">

          <div className="name bold">
            {contact && renderUpdatedLabel()}
            {contact && contact.firstName} {contact && contact.lastName}
          </div>

          <div className="large">
            {contact && contact.function}
          </div>

          <div className="actions">
            <button
              onClick={() => setIsEditState(true)}>
              <img src={edit} alt="edit"/>
            </button>

            {contact?._id &&
              <button
                onClick={() => setRemoveAction({ function: () => deleteContactAction(dispatch , contact._id )})}>
                <img src={remove} alt="remove"/>
              </button>
            }

        </div>
        </div>
    )
  }

  function renderEdit() {
    return (
      <>
        <form onSubmit={handleSubmit(submitUser)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                  
                <label htmlFor="firstName">Prénom</label>
                  <input
                    name="firstName"
                    defaultValue={contact && contact && contact.firstName ? contact.firstName  : undefined }
                    ref={register({
                      required: "Compléter le prénom",
                    })}
                  />
                  <p className="error">{errors && errors.firstName && errors.firstName.message}</p>

                  <label htmlFor="lastName">Nom</label>
                  <input
                    name="lastName"
                    defaultValue={contact && contact && contact.lastName ? contact.lastName  : undefined }
                    ref={register({
                      required: "Compléter le nom",
                    })}
                  />
                  <p className="error">{errors && errors.lastName && errors.lastName.message}</p>

                  <label htmlFor="function">Fonction</label>
                  <input
                    name="function"
                    defaultValue={contact && contact && contact.function ? contact.function  : undefined }
                    ref={register({
                      required: "Compléter la fonction",
                    })}
                  />
                  <p className="error">{errors && errors.function && errors.function.message}</p>

                  <label htmlFor="phone">Téléphone</label>
                  <input
                    name={"phone"}
                    defaultValue={phone ? phone  : "" }
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <p className="error">{errors && errors.phone && errors.phone.message}</p>

                  <label htmlFor="email">Email</label>
                  {contact && contact.email &&
                    <input
                      name="email"
                      defaultValue={contact.email}
                      ref={register({
                        required: "Compléter l'email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Adresse mail invalide"
                        }
                      })}
                    />
                  }
                  
                  {!contact &&
                    <input
                      name="email"
                      ref={register({
                        required: "Compléter votre email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Adresse mail invalide"
                        }
                      })}
                    />
                  }
                  <p className="error">{errors.email && errors.email.message}</p>

                  <label htmlFor="tags">Tags</label>
                  {/* <Creatable
                    styles={customStyles}
                    value={selectedTags}
                    options={tagsOptions}
                    formatCreateLabel={(val) =>  `Ajouter le tag "${val}`}
                    placeholder={'Sélectionner un tag'}
                    onChange={handleChangeTags}
                    isMulti
                    /> */}
                  <Select
                    styles={customStyles}
                    value={selectedTags}
                    options={tagsOptions}
                    placeholder={'Sélectionner un tag'}
                    onChange={setSelectedTags}
                    isMulti
                  />

                  <label htmlFor="domains">Domaines</label>
                  <Select
                    styles={customStyles}
                    value={selectedDomains}
                    options={domainsOptions}
                    placeholder={'Sélectionner un domaine'}
                    onChange={handleChangeDomains}
                    isMulti
                  />

                  <label htmlFor="linkedin">Linkedin</label>
                  <input
                    name="linkedin"
                    defaultValue={contact && contact && contact.linkedin ? contact.linkedin  : undefined }
                    ref={register({
                      pattern: {
                        value: /^(?:http(s)?:\/\/)?[\w.-]+(?:[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/,
                        message: "Url invalide"
                      }
                    })}
                  />
                  <p className="error">{errors && errors.linkedin && errors.linkedin.message}</p>
                                
              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  <button
                    type="button"
                    className={`btn primary`}
                    onClick={() => closeEditState()}
                    >
                    Fermer
                  </button>
                  <button
                    className={`btn success`}
                    type="submit"
                    >
                    Publier
                  </button>
                </div>

                <label>Photo du Contact <span className="note">minimum 692 x 692 px , 72 dpi</span></label>
                {renderPictureImg()}
                <div className="btn-container">
                  
                  <button type="button" className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture(true)}
                    >
                    Parcourir
                  </button>

                  {picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({ function: () => setPicture()})}
                      >
                      Supprimer
                    </button>
                  }

                </div>
              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          selectedMediaList={selectedMediaList}
          mediaType={'image'}
          companyId={companyId}
          isMulti={false}
          handleChange={(value) => setPicture(value[0])}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </>
    )
  }

  return (
    <div ref={contactRef} className={`list-item transition ${isEditState ? "edit-row" : " domain-row"}`} >
     {isEditState ? renderEdit() : renderRow() }
    </div>
  )
}
