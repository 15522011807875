import {
  GET_CONTACTS,
  POST_CONTACT,
  GET_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  ERROR_CONTACTS,
  MESSAGE_CONTACTS,
  GET_TAGS,
  POST_TAG
} from "../actions/types"

const initialState = {
  contactsList: [],
  contact: null,
  error: null,
  message: null,
  tags: [],
};


export default function contactsReducer(state = initialState, action) {

  var contactsList = state.contactsList;

  switch (action.type) {
    case POST_TAG: 
    return {...state , tags: [...state.tags , action.payload.tag] , message : action.payload.message};
    case GET_TAGS: 
    return {...state , tags: action.payload};
    case GET_CONTACTS:
      return {...state , contactsList: action.payload};
      
    case POST_CONTACT:
      contactsList.push(action.payload.contact)
      return {...state ,
        contactsList: contactsList,
        contact: action.payload.contact,
        message: action.payload.message
      };

    case UPDATE_CONTACT:
      
      if (contactsList) {
        var found = contactsList.find(contact =>contact._id === action.payload.contact._id);
        if (found) {
          contactsList = contactsList.filter(contact =>contact._id !== action.payload.contact._id);
        }
      } else {
        contactsList = [];
      }

      contactsList.push(action.payload.contact);

      return {
        ...state,
        contactsList: contactsList,
        contact: action.payload.contact,
        message: action.payload.message
      }

    case GET_CONTACT:
      return {...state ,contact: action.payload }

    case DELETE_CONTACT:
      contactsList = state.contactsList.filter(contact => contact._id !== action.payload)
      return {...state , contactsList: state.contactsList.filter(contact => contact._id !== action.payload) }

    case MESSAGE_CONTACTS:
        return {...state , message: action.payload}

    case ERROR_CONTACTS:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}