import { getData , postData , deleteData , putData } from './index';

import { sortByUpdateDate } from '../utils/utils';

import {
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  ERROR_USERS
} from "./types"

export async function getUsersAction(dispatch) {
  let url = "/user";
  var users;

  await getData(ERROR_USERS , url, dispatch , true ).then((response)=>{
    users = response.users
  });

  dispatch({
    type : GET_USERS,
    payload : sortByUpdateDate(users)
  });

};

export async function createUserAction(dispatch , data ) {
  let url = "/user";
  var user;

  await postData(CREATE_USER ,ERROR_USERS , url, dispatch , data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type: 'SET_TOAST',
    payload: {
      message: "L'utilisateur a bien été créé",
      type: "success"
    }
  });

  dispatch({
    type : CREATE_USER,
    payload : user
  });

};

export async function updateUserAction(dispatch , data ) {
  let url = "/user/" + data._id;
  var user;

  await putData(UPDATE_USER , ERROR_USERS , url, dispatch , data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type: 'SET_TOAST',
    payload: {
      message: "L'utilisateur a bien été modifié",
      type: "success"
    }
  });

  dispatch({
    type : UPDATE_USER,
    payload : user
  });

};

export async function deleteUserAction(dispatch , id ) {
  let url = "/user/" + id;
  var user;

  await deleteData(ERROR_USERS , url, dispatch , true ).then((response)=>{
    user = response.user
  });

  dispatch({
    type: 'SET_TOAST',
    payload: {
      message: "L'utilisateur a bien été supprimé",
      type: "success"
    }
  });

  dispatch({
    type : DELETE_USER,
    payload : user
  });

};