import React from 'react';

import { deleteUserAction } from '../../../actions/usersActions';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';

export default function UsersRow(props) {
  const { user ,  theme  , context , editUser , dispatch , setRemoveAction
  } = props;


  function renderUpdatedLabel() {
    var updatedDate = new Date(user.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }

  function renderCompaniesList() {
    if (context.companies.companiesList && context.companies.companiesList.length) {
      var logosList = []
      user.companies.forEach(companyId => {
        var found  = context.companies.companiesList.find(item => item._id === companyId)
        if (found) {
          logosList.push(found);
        }
      });
      if (logosList.length) {
        return logosList.map((logo, i) => {
          return (
            <div className={`icon ${logo.className}-bg`} key={i}>
              <span className="bold">{logo.icon}</span>
            </div>
          )
        })
      }
    }
  };

  return (
    <div className="list-item user-row">

      <div className="title bold border">
        {renderUpdatedLabel()}
        {user.profile.firstName} {user.profile.lastName}
      </div>

      <div className="role bold border">
        {user.role}
      </div>

      <div className="companies bold">
        {renderCompaniesList()}
      </div>

      <div className="actions">

        <button
          onClick={() => editUser(user)}
          >
          <img src={edit} alt="edit"/>
        </button>

        <button
          onClick={() => setRemoveAction({ function: () => deleteUserAction(dispatch , user._id)})}
          >
          <img src={remove} alt="remove"/>
        </button>

      </div>
    </div>
  )
}
