import {
  GET_FILES,
  ERROR_FILES,
  DELETE_FILE,
  POST_FILE,
  PUT_FILE
} from "../actions/types"

const initialState = {
  mediasList: null,
  error: null,
};

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case POST_FILE: 
      return {...state , mediasList: state.mediasList.concat(action.payload) }
    case PUT_FILE:
      let list = state.mediasList;
      let index = list.findIndex(m => m._id === action.payload._id)
      list[index] = action.payload
      return {...state , mediasList: [...list]}
    case DELETE_FILE:
      return {...state , mediasList: state.mediasList.filter(item => item._id !== action.payload)}
    case GET_FILES:
      return {...state , mediasList: action.payload}
    case ERROR_FILES:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}