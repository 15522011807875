import {
  GET_USERS,
  ERROR_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  MESSAGE_USER
} from "../actions/types";

import { sortByUpdateDate } from './../utils/utils';

const initialState = {
  usersList: null,
  error: null,
  message: null
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER:
      return {...state , usersList: state.usersList.filter(user => user._id !== action.payload)}
    case UPDATE_USER:
      let updatelist = [...state.usersList.filter(user => user._id !== action.payload.user._id)];
      updatelist.push(action.payload.user)
      updatelist = sortByUpdateDate(updatelist)
      return {...state , usersList: updatelist , message: action.payload.message}
    case CREATE_USER:
      let list = [...state.usersList];
      list.push(action.payload.user)
      list = sortByUpdateDate(list)
      return {...state , usersList: list , message: action.payload.message }
    case GET_USERS:
      return {...state , usersList: action.payload}
    case MESSAGE_USER:
        return {...state , message: action.payload}
    case ERROR_USERS:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}