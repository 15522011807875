import React, { useState , useEffect , useCallback } from 'react';
import { useParams , useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction , getCompaniesAction } from '../../../actions/companiesActions';
import { getArticlesAction , createArticleAction } from '../../../actions/articlesActions';

import { companiesColors } from '../../../utils/utils';

import RemoveModale from '../../partials/RemoveModale/RemoveModale'; 
import ArticleRow from '../articles/ArticleRow';
import ArticleIcon from '../../partials/ArticleIcon';
import Modale from '../../partials/Modale';

import './articlesList.scss';

export default function ArticlesList(props) {

  const [ context, dispatch ] = useGlobalContext();

  const param = useParams();
  const history = useHistory();

  const { setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , setActivePage , theme , lang } = props;

  const [ removeAction , setRemoveAction ] = useState();
  const [ articleModaleIsOpen , setArticleModaleIsOpen ] = useState(false);
  const [ selectedArticleType , setSelectedArcticleType ] = useState();
  const [ search , setSearch ] = useState();

  const getArticles = useCallback(() => {
    getArticlesAction(dispatch ,  param );
    dispatch({
      type : 'GET_ARTICLE',
      payload : null
    });
  }, [dispatch , param])

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getCompaniesAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(false);
    setActivePage("articles");
    getArticles();
    getCompany();
    return () => {
      setDisplaySelectLang(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    history.push(`/articles-list/${param.companyId}/${lang}`)
    // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    getArticles();
    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    if(context.articles.message === "article saved") {
      history.push(`/edit-article/${param.companyId}/${context.articles.article._id}`);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'article a bien été créé",
          type: "success"
        }
      });

      dispatch({
        type : 'MESSAGE_ARTICLES',
        payload : null
      });
    }
    // eslint-disable-next-line
  }, [context.articles.message])


  function createArticle() {
    let reqBody = {
      lang: lang,
      type: selectedArticleType,
      companies:[param.companyId]
    }
    createArticleAction(dispatch , reqBody);
  }

  function renderArticlesList() {
    let articlesList = context?.articles?.articlesList?.filter(p => {
      if (!search) return p;
      if (p?.title?.toLowerCase().includes(search?.toLowerCase())) return p
      return null;
    });
    if (articlesList?.length) {
      return articlesList.map((article, i ) => {
        return <ArticleRow article={article} companyId={param.companyId} setRemoveAction={setRemoveAction} theme={theme} key={i} />
      })
    } else {
      return <span>Aucun article n'a été trouvé</span>
    }
  }

  function closeArticleModale() {
    setArticleModaleIsOpen(false);
    setSelectedArcticleType();
  }

  return (    
    <div className={`page-container ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="title">
            <h1>Articles ({lang})</h1>
            <div className="bar primary"></div>
          </div>

          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche"
            />

          <button
            onClick={() => setArticleModaleIsOpen(true)}
            className="btn primary">
            Créer un nouvel article
          </button>
        </div>

        <div className="list-container">
          {renderArticlesList()}
        </div>

      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
      <Modale
        modaleIsOpen={articleModaleIsOpen}
        modaleToggle={closeArticleModale}
        className={"create-article-modale"}
        >
          <>
            <div className="create-article-container">
              <button
                className={selectedArticleType === "article" ? "primary light" : ""}
                onClick={() => setSelectedArcticleType("article")}
                >
                <ArticleIcon width={50} height={50} icon={"article"} color={companiesColors[theme]} />
                <span className="bold">Article</span>
              </button>
              <button
                className={selectedArticleType === "youtube" ? "primary light" : ""}
                onClick={() => setSelectedArcticleType("youtube")}
                >
                <ArticleIcon width={50} height={50} icon={"youtube"} color={companiesColors[theme]} />
                <span className="bold">Youtube</span>
              </button>
              <button
                className={selectedArticleType === "linkedin" ? "primary light" : ""}
                onClick={() => setSelectedArcticleType("linkedin")}
                >
                <ArticleIcon width={50} height={50} icon={"linkedin"} color={companiesColors[theme]} />
                <span className="bold">Linkedin</span>
              </button>
            </div>

            <div className="validate-create-article">
              {selectedArticleType &&
                <button
                  onClick={() => createArticle()}
                  className="btn primary">
                  Valider
                </button>
              }
            </div>
          </>
        </Modale>
    </div>
  )
}
