import { getData , putData , deleteData , postData } from './index';

import {
  GET_DOMAINS,
  POST_DOMAIN,
  GET_DOMAIN,
  DELETE_DOMAIN,
  UPDATE_DOMAIN,
  ERROR_DOMAINS,
} from "./types"

export async function getDomainsAction(dispatch , lang) {
  let url = "/domain/lang/" + lang;
  var domains;

  await getData(ERROR_DOMAINS , url, dispatch , true ).then((response)=>{
    domains = response.domains
  });

  dispatch({
    type : GET_DOMAINS,
    payload : domains
  });

};

export async function createDomainAction(dispatch , params ) {
  let url = "/domain"
  var domain;

  await postData(POST_DOMAIN , ERROR_DOMAINS , url, dispatch , params, true ).then((response)=>{
    domain = response
  });


  dispatch({
    type : POST_DOMAIN,
    payload : domain
  });

}

export async function getDomainAction(dispatch , id ) {
  let url = "/domain/" + id
  var domain;

  await getData(ERROR_DOMAINS, url, dispatch , true ).then((response)=>{
    domain = response.domain
  });

  dispatch({
    type : GET_DOMAIN,
    payload : domain
  });

}

export async function updateDomainAction(dispatch , param ) {
  let url = "/domain/" + param._id
  var domain;

  await putData(UPDATE_DOMAIN, ERROR_DOMAINS ,  url, dispatch, param , true ).then((response)=>{
    domain = response
  });

  dispatch({
    type : UPDATE_DOMAIN,
    payload : domain
  });

}

export async function deleteDomainAction(dispatch , id ) {
  let url = "/domain/" + id

  await deleteData(ERROR_DOMAINS, url, dispatch, true ).then((response)=>{
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le domaine a bien été supprimé",
        type: "success"
      }
    });
  });

  dispatch({
    type : DELETE_DOMAIN,
    payload : id
  });

}