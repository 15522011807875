import React, { useState } from 'react';
import Select from 'react-select';
import { useForm } from "react-hook-form";
import { API_URL } from "../../../actions/index";

import { postMediaAction } from '../../../actions/mediasActions';
import { useGlobalContext } from '../../context/GlobalContext';
import pdfIcon from '../../../assets/images/icons/pdf-icon.png';
import exelIcon from '../../../assets/images/icons/exel.png';
import './medias.scss';
import { companiesColors, langsOptions } from '../../../utils/utils';

export default function AddMedia(props) {
  const { companyId , theme , setAddMediaIsOpen , lang } = props;
  const { handleSubmit, register, errors } = useForm();
  const [ displayImgPreview , setDisplayImgPreview ] = useState(false);
  const [ displayVideoPreview , setDisplayVideoPreview ] = useState(false);
  const [ fileSizeError , setFileSizeError ] = useState();
  const [ isThumbnail , setIsThumbnail ] = useState(false);
  const [ isPriority , setIsPriority ] = useState(false);
  const [ isImage , setIsImage ] = useState(false);
  const [ isSchool , setIsSchool ] = useState(false);
  const [ withThumbnail , setWithThumbnail ] = useState(false);
  const [ selectedThumbnail , setSelectedThumbnail ] = useState();
  const [ selectedLangs, setSelectedLangs] = useState(langsOptions.filter((l) => l.value === lang));

  const [ context , dispatch ] = useGlobalContext();

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? companiesColors[theme] : companiesColors[theme] 
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginTop:7,
      marginBottom:20,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 5px"
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      minHeight: 44,
      borderRadius: 10,
      border: state.isFocused || state.isSelected ? "1px solid " + companiesColors[theme] : "1px solid #E0E3EF"
    })
  }

  const submitMedia = (values)  => {

    const formData = new FormData();
    const langs = selectedLangs?.map((l) => l.value)?.join(',');
    formData.append("file", values.file[0], values.file[0].name);
    formData.append("company", companyId );
    formData.append("title", values.title );
    formData.append("description", values.description);
    formData.append("alt", values.title );
    if (isSchool) {
      formData.append("type" , "school" );
    }
    formData.append("isThumbnail" , isThumbnail );
    formData.append("isPriority" , isPriority );
    formData.append("langs" , langs );
    selectedThumbnail && formData.append("thumbnail" , selectedThumbnail );

    postMediaAction(dispatch , formData );

    document.getElementById("file").value = ""
    setDisplayImgPreview(false);
    setDisplayVideoPreview(false)
    setAddMediaIsOpen(false);
    setFileSizeError();

  };

  function onChangeFile() {
    setFileSizeError();
    setDisplayImgPreview(false);
    setDisplayVideoPreview(false);
    var fileInput = document.getElementById("file");
    var preview = document.getElementById("preview-img");
    var imgSrc;
  
    if (fileInput.files && fileInput.files[0]) {

      if (fileInput.files[0].size/1024/1024 > 40 && fileInput.files[0].type === "video/mp4" ) {
        setFileSizeError("Les fichiers vidéos ne doivent pas étre suppérieur à 40 MB") ;
        return fileInput = null;
      }
      if (fileInput.files[0].size/1024/1024 > 5 && fileInput.files[0].type !== "video/mp4" ) {
        setFileSizeError("Les fichiers ne doivent pas étre suppérieur à 5 MB") ;
        return fileInput = null;
      }

      imgSrc = new FileReader();
      setIsImage(false);

      imgSrc.onload = function(e) {
        if (
            fileInput.files[0].type === "image/svg+xml" ||
            fileInput.files[0].type === "image/jpeg" ||
            fileInput.files[0].type === "image/png" 
          ) {
          preview.setAttribute('src', e.target.result);
        }
      }
  
      if (fileInput.files[0].type === "application/pdf") {
        preview = document.getElementById("preview-img");
        setDisplayImgPreview(true);
        setWithThumbnail(true);
        setIsImage(false);
      }

      if (fileInput.files[0].type === "image/svg+xml") {
        imgSrc.readAsDataURL(fileInput.files[0]);
        setDisplayImgPreview(true);
        setIsImage(true);
      }

      if (fileInput.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileInput.files[0].type === "text/csv" ) {
        preview.setAttribute('src', exelIcon);
        setDisplayImgPreview(true);
        setWithThumbnail(true);
        setIsImage(false);

      }

      if (fileInput.files[0].type === "image/jpeg" || fileInput.files[0].type === "image/png" ) {
        imgSrc.readAsDataURL(fileInput.files[0]);
        setDisplayImgPreview(true);
        setIsImage(true);

      }

      if (fileInput.files[0].type === "video/mp4" ) {
        var video = document.getElementById("video-mp4");
        var reader = new FileReader();

        reader.onload = function(e) {
          video.setAttribute('src',  e.target.result);
        };

        reader.readAsDataURL(fileInput.files[0]);
        setDisplayVideoPreview(true);
        setIsImage(false);
        setWithThumbnail(true);
      }

    }
  }

  return (
    <form className="add-media-container" onSubmit={handleSubmit(submitMedia)}>
      <div className="image">
        {!displayVideoPreview && <img id="preview-img" src={pdfIcon} alt="uploaded img" className={displayImgPreview ? "preview" : "d-none"} />}

        <video controls width="250" id="video-mp4" className={displayVideoPreview ? "preview" : "d-none"} >
          <source 
            type="video/mp4"/>
        </video>
        <label htmlFor="file" className={`label-file btn primary ${theme}-bg`} >Télécharger un fichier</label>
      </div>
      <input
        name="file"
        type="file"
        id="file"
        onChange={(e) => onChangeFile(e) }
        className="input-file"
        ref={register({
          required: "Télécharger un fichier",
        })}
      />
      <p className="error">{errors.file && errors.file.message}</p>

      <label htmlFor="title">Titre</label>
      <input
        name="title"
        ref={register({
          required: "Compléter un titre",
        })}
      />
      <p className="error">{errors.title && errors.title.message}</p>

      <label htmlFor="description">Description</label>
      <textarea
        name="description"
        ref={register({
          required: "Compléter une description",
        })}
      />
      {withThumbnail && !isSchool &&<p className="note">Taille miniature pour fichier pdf : 294 x 390 px</p>}

      <p className="error">{errors.description && errors.description.message}</p>

      <label htmlFor="langs">Langues</label>
      <Select
        styles={customStyles}
        value={selectedLangs}
        options={langsOptions}
        placeholder={'Sélectionner des langues'}
        onChange={setSelectedLangs}
        isMulti
      />

      {withThumbnail && !isSchool && 
        <>
        <label>Miniature (format 294 x 390 px)</label>
        <div className="thumbnail-list">
          {context.medias.mediasList.filter(item => item.company === companyId && item.isThumbnail ).map((item , i )  => {
            return (
              <button
                key={'button' +i}
                type="button"
                onClick={() => setSelectedThumbnail(item._id)}
                className="thumbnail">
                <img src={API_URL + '/' + item.path} className={`${selectedThumbnail === item._id ? "active" : ""} `} alt="approach" />
                <p>{item.title}</p>
              </button>
            )
          })}
        </div>
        </>
      }

      {isImage &&
        <div>
          <input id="isThumbnail"
            onChange={(e) => setIsThumbnail(e.target.checked)}
            type="checkbox" className="switch" checked={isThumbnail}
            />
          <label htmlFor="isThumbnail">{isThumbnail ? <span>Miniature ( pdf / exel / vidéo)<br/><span className="note">format 294 x 390 px</span></span> : <span>Image d'illustation</span> }</label>
        </div>
      }

      {!isImage &&
        <div>
          <input id="setIsSchool"
            onChange={(e) => setIsSchool(e.target.checked)}
            type="checkbox" className="switch" checked={isSchool}
            />
          <label htmlFor="setIsSchool">Ne pas afficher dans la médiathèque</label>
        </div>
      }


      <div>
        <input id="setIsPriority"
          onChange={(e) => setIsPriority(!isPriority)}
          type="checkbox" className="switch" defaultChecked={isPriority}
          />
        <label htmlFor="isPriority">{isPriority ? <span>Média prioritaire</span> : <span>Média non prioritaire</span> }</label>
      </div>

      <div className="btn-container">
        {fileSizeError ? 
          <p className="error">{fileSizeError}</p>
          :
          <>
          {withThumbnail && !selectedThumbnail && !isSchool ?
            <p className="error">Sélectionner une miniature</p>
            :
            <button className={`btn success`} type="submit">Valider</button>
          }
          </>
        }
      </div>
    </form>
  )
}
