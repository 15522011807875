import React, { useEffect , useCallback } from 'react';
import * as moment from 'moment';

import { useGlobalContext } from '../../context/GlobalContext';
import { getCompaniesAction , getCompaniesChangesAction } from '../../../actions/companiesActions';
import { sortByUpdateDate  , clearMarkup } from  '../../../utils/utils';

import { Link } from "react-router-dom";

import './home.scss';

export default function HomePage(props) {

  const [ context, dispatch ] = useGlobalContext();

  const userAuth = context.auth.userAuth;

  const { setDisplayNavBar , lang , setDisplaySiteNav , requireRight } = props ;

  const getCompanies = useCallback(() => {
    getCompaniesAction(dispatch);
    getCompaniesChangesAction(dispatch);
    dispatch({ type:'RESET_PAGE' });

    dispatch({
      type : 'GET_COMPANY',
      payload : null
    });

    dispatch({
      type : 'GET_ARTICLES',
      payload : null
    });
    
  },[dispatch]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySiteNav(false);
    getCompanies();
  }, [setDisplayNavBar, setDisplaySiteNav, getCompanies]);

  function renderUpdatedLabel(date) {
    var updatedDate = new Date(date);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label`}></div>
      );
    };
  }

  function renderNotifMessage(change) {

    var action = "Modification"
    if (change.action === "creation") action = "Création"
    if (change.section && requireRight("page")) {
      var sectionArray = change.section.split('/');
      var sectionName = sectionArray[0].trim()
      if (sectionArray.length > 1 ) action += "s";
      if (sectionName === "group" ) sectionName = "groupe";
      if (sectionName === "recruitment" || sectionName === "career" ) sectionName = "nous rejoindre"
      if (sectionName === "mission" ) sectionName = "missions"
      if (sectionName === "innovation" ) sectionName = "innovations"
      if (sectionName === "home" ) sectionName = "accueil"
      if (sectionName === "approach" ) sectionName = "approche globale"
      if (sectionName.length < 20 ) {
        return (<p><span className="bold">{sectionArray.length} {action}</span> sur la page <span className="bold">{sectionName}</span></p>)
      }
    } else if (change.article && change.article.title && requireRight("article")) {
      return (<p>{action} de l'article <span className="bold">{clearMarkup(change.article.title)}</span></p>)
    } else if (change.project && change.project.name && requireRight("projet")) {
      return (<p>{action} du projet <span className="bold">{clearMarkup(change.project.name)}</span></p>)
    } else if (change.updatedUser && change.updatedUser.profile && requireRight("utilisateur")) {
      return (<p>{action} de l'utilisateur <span className="bold">{change.updatedUser.profile.firstName} {change.updatedUser.profile.lastName}</span></p>)
    } else if (change.file && requireRight("file")) {
      if (change.action === "creation") action = "Ajout d'un fichier"
      return (<p className="bold">{action}</p>)
    } else {
      return null;
    }
  }

  function renderCompaniesList(change) {
    if (context.companies.companiesList && context.companies.companiesList.length) {
      var logosList = []
      change.companies.forEach(company => {
        var found  = context.companies.companiesList.find(item => item._id === company._id)
        if (found) {
          logosList.push(found);
        }
      });
      if (logosList.length) {
        return logosList.map((logo, i) => {
          return (
            <div className={`icon ${logo.className}-bg`} key={i}>
              <span className="bold">{logo.icon}</span>
            </div>
          )
        })
      }
    }
  };

  const Notification = ({ change } ) => {
    var  message = renderNotifMessage(change);
    return (
      <>
        {message &&
          <div className="notification">
            <div className="logo-list">
              {change.companies && renderCompaniesList(change)}
            </div>
            <div className="message">
              {renderUpdatedLabel(change.updatedAt)} {message}
            </div>
            {change.user?.profile && <span className="text-grey">par {change.user?.profile.firstName} {change.user?.profile.lastName} le {moment(change.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>}
          </div>
        }
      </>
    )
  }

  function renderCompaniesLinks() {
    if (context.companies.companiesList && userAuth) {
      return (
        <div className="links-container">
          {context.companies.companiesList
            .filter(c => c?.category !== "external")
            .filter(c => c.name !== "Créocéan Océan Indien").map((link , i ) => {
            if (requireRight("page")) {
              return (
                <Link to={link.name === "Keran" ? `/home-group/${link._id}/${lang}` : `/home-company/${link._id}/${lang}`} key={i} className={`btn ${link.className}`}>
                  <div className="icon primary">
                    <span>{link.icon}</span>
                  </div>
                  <span>{link.name}</span>
                </Link>
              )
            } else if (requireRight("projet")) {
              return (
                <Link
                  to={`projects-list/${link._id}/${lang}`}
                  key={i}
                  className={`btn ${link.className}`}>
                  <div className="icon primary">
                    <span>{link.icon}</span>
                  </div>
                  <span>{link.name}</span>
                </Link>
              )
            } else if (requireRight("article")) {
              return (
                <Link
                  to={`articles-list/${link._id}/${lang}`}
                  key={i}
                  className={`btn ${link.className}`}>
                  <div className="icon primary">
                    <span>{link.icon}</span>
                  </div>
                  <span>{link.name}</span>
                </Link>
              )
            } else if (requireRight("agency")) {
              return (
                <Link
                  to={`agencies-list/${link._id}/${lang}`}
                  key={i}
                  className={`btn ${link.className}`}>
                  <div className="icon primary">
                    <span>{link.icon}</span>
                  </div>
                  <span>{link.name}</span>
                </Link>
              )
            } else if (requireRight("utilisateur")) {
              return (
                <Link
                  to={`/users/${link._id}/`}
                  key={i}
                  className={`btn ${link.className}`}>
                  <div className="icon primary">
                    <span>{link.icon}</span>
                  </div>
                  <span>{link.name}</span>
                </Link>
              )
            } else {
              return null;
            }
          }) 
        }

        </div>
      )
    }
  }

  return (
    <div className="page-container home-container">
      <div className="page-content home-row">
        <div className="col-companies">
          {context.companies.companiesList && userAuth && userAuth.profile &&
            <>
              <h1>Hello {userAuth.profile.firstName}</h1>
              <h2>Sites administrables</h2>
              {renderCompaniesLinks()}
            </>
          }
        </div>
        <div className="col-changes">
          {context.companies.changes && sortByUpdateDate(context.companies.changes).filter(item => item.section !== "").map((change , i ) => <Notification key={'notif' + i} change={change} />)}
        </div>
      </div>
    </div>
  )
}
