import React from 'react';

export default function ArticleIcon(props) {
  const { icon , width , height , color } = props;

  function renderIcon() {
    if (icon === "linkedin") {
      return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <path d="M11.0738 5.125C10.6294 5.12466 10.1892 5.2119 9.77844 5.38172C9.36768 5.55154 8.99441 5.80062 8.67995 6.11473C8.36548 6.42884 8.11597 6.80183 7.94569 7.21239C7.7754 7.62294 7.68767 8.06303 7.6875 8.5075C7.6875 10.3781 9.20322 11.9284 11.07 11.9284C12.9393 11.9284 14.4563 10.3781 14.4563 8.51006C14.4567 8.06565 14.3694 7.62553 14.1996 7.21485C14.0298 6.80417 13.7807 6.43098 13.4665 6.11662C13.1524 5.80225 12.7794 5.55287 12.3689 5.38272C11.9583 5.21258 11.5183 5.125 11.0738 5.125V5.125ZM27.5917 14.0938C24.7486 14.0938 23.1227 15.58 22.3412 17.0586H22.2592V14.4922H16.6562V33.3125H22.4949V23.9965C22.4949 21.5417 22.6794 19.1688 25.7173 19.1688C28.7115 19.1688 28.7551 21.9683 28.7551 24.1516V33.3125H34.5938V22.9754C34.5938 17.917 33.506 14.0938 27.5917 14.0938ZM8.15259 14.4909V33.3125H13.9951V14.4909H8.15259V14.4909Z" fill={color}/>
        </svg>
      )
    }
    if (icon === "youtube") {
      return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g transform="translate(7, 0)" clip-path="url(#clip0)">
            <path d="M36.7572 10.7524C36.7572 7.50045 34.3652 4.88443 31.4095 4.88443C27.406 4.69727 23.323 4.625 19.1504 4.625H17.8496C13.6871 4.625 9.59689 4.69727 5.59337 4.88516C2.64493 4.88516 0.252942 7.51562 0.252942 10.7676C0.0722778 13.3395 -0.00432373 15.9122 1.2207e-05 18.4848C-0.00721436 21.0575 0.0746867 23.6325 0.245715 26.21C0.245715 29.462 2.63771 32.0997 5.58615 32.0997C9.792 32.2948 14.1063 32.3815 18.4928 32.3743C22.8865 32.3887 27.1887 32.2972 31.3994 32.0997C34.3551 32.0997 36.7471 29.462 36.7471 26.21C36.9205 23.6301 37 21.0575 36.9928 18.4776C37.0092 15.9049 36.9306 13.3299 36.7572 10.7524ZM14.959 25.5741V11.3739L25.4375 18.4704L14.959 25.5741Z" fill={color}/>
          </g>
          <defs>
            <clipPath id="clip0">
            <rect width="37" height="37" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )
    }
    if (icon === "article") {
      return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <defs>
              <polygon id="path-1" points="0 41 41 41 41 0 0 0"></polygon>
          </defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Desktop-HD" transform="translate(-907.000000, -382.000000)">
                  <g id="Group-5" transform="translate(419.000000, 243.000000)">
                      <g id="Group-12" transform="translate(488.000000, 139.000000)">
                          <path d="M38.5976563,21.7011719 L38.5976563,36.1953125 C38.5976563,37.5200449 37.5203652,38.5976563 36.1953125,38.5976563 C34.8705801,38.5976563 33.7929688,37.5200449 33.7929688,36.1953125 L33.7929688,21.7011719 L38.5976563,21.7011719 Z M4.8046875,38.5976563 C3.47995508,38.5976563 2.40234375,37.5200449 2.40234375,36.1953125 L2.40234375,2.40234375 L31.390625,2.40234375 L31.390625,36.1953125 C31.390625,37.0702461 31.6267754,37.8904063 32.0368555,38.5976563 L4.8046875,38.5976563 Z M33.7929688,0 L0,0 L0,36.1953125 C0,38.8447773 2.15522266,41 4.8046875,41 L36.1953125,41 L36.1959531,41 C38.8447773,41 41,38.8447773 41,36.1953125 L41,19.2988281 L33.7929688,19.2988281 L33.7929688,0 Z" id="Fill-1" fill={color}></path>
                          <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1"></use>
                          </mask>
                          <g id="Clip-4"></g>
                          <path d="M7.38410596,12.5206612 L26.615894,12.5206612 L26.615894,7.47933884 L7.38410596,7.47933884 L7.38410596,12.5206612 Z M5,15 L29,15 L29,5 L5,5 L5,15 Z" id="Fill-3" fill={color} mask="url(#mask-2)"></path>
                          <polygon id="Fill-5" fill={color} mask="url(#mask-2)" points="5 19 17 19 17 17 5 17"></polygon>
                          <polygon id="Fill-6" fill={color} mask="url(#mask-2)" points="19 19 29 19 29 17 19 17"></polygon>
                          <polygon id="Fill-7" fill={color} mask="url(#mask-2)" points="5 24 17 24 17 22 5 22"></polygon>
                          <polygon id="Fill-8" fill={color} mask="url(#mask-2)" points="19 24 29 24 29 22 19 22"></polygon>
                          <polygon id="Fill-9" fill={color} mask="url(#mask-2)" points="5 28 17 28 17 26 5 26"></polygon>
                          <polygon id="Fill-10" fill={color} mask="url(#mask-2)" points="5 34 17 34 17 32 5 32"></polygon>
                          <path d="M21.4793388,31.6666667 L26.5206612,31.6666667 L26.5206612,29.3333333 L21.4793388,29.3333333 L21.4793388,31.6666667 Z M19,34 L29,34 L29,27 L19,27 L19,34 Z" id="Fill-11" fill={color} mask="url(#mask-2)"></path>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
    }
  };

  const style = {
    objectFit: 'contain',
    width: width,
    height: height,
  }
  
  return (
    <div style={style}>
      {renderIcon()}
    </div>
  )
}
