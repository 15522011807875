import React from 'react';
import { Link } from "react-router-dom";
import * as moment from 'moment';

import { useGlobalContext } from '../../context/GlobalContext';

import { deleteProjectAction , updateProjectAction } from '../../../actions/projectsActions';
import { clearMarkup , companiesColors } from '../../../utils/utils';

import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';
import star from '../../../assets/images/icons/star.svg';
import starSuccess from '../../../assets/images/icons/star-success.svg';

export default function ProjectRow(props) {
  const { project, companyId , theme , setRemoveAction } = props;

  const [ context, dispatch ] = useGlobalContext();

  function renderUpdatedLabel() {
    var updatedDate = new Date(project.updatedAt);
    var today = new Date();
    if ( today.getTime() < updatedDate.getTime() + 86400000) {
      return (
        <div className={`updated-label ${theme}-bg`}></div>
      );
    };
  }

  function renderCompaniesList() {
    if (context.companies.companiesList && context.companies.companiesList.length) {
      var logosList = [];

      var found  = context.companies.companiesList.find(item => item._id === project.company );
      if (found) {
          logosList.push(found);
      };
      
      if (project.company !== "5e9ec41b25ab1e65768e2ff0" && project.isGroup === "published") {
        found  = context.companies.companiesList.find(item => item._id === "5e9ec41b25ab1e65768e2ff0" );
        if (found) {
          logosList.push(found);
        };
      };

      if (logosList.length) {
        return logosList.map((logo, i) => {
          return (
            <div className={`icon ${logo.className}-bg`} key={i}>
              <span className="bold">{logo.icon}</span>
            </div>
          )
        })
      }
    }
  };

  
  function renderStatus() {
    var textStatus = "brouillon";
    if (project.status === "published") {
      textStatus = "publié";
    }
    return (
      <div className={`tooltip ${project.status}`}>
        <p>{textStatus}</p>
        <span className="tooltip-text" >{textStatus}</span>
      </div>
    );
  }

  return (
    <div className="list-item project-row">

      <div className="title bold border" >
        {renderUpdatedLabel()}
        <span>{project.name && clearMarkup(project.name)}</span>
      </div>

      <div className="date border">
        {moment(project.publicationDate).format('DD/MM/YYYY')}
      </div>

      <div className="companies">
        {renderCompaniesList()}
      </div>

      <div className="domains">
        {project.company !== "5e9ec41b25ab1e65768e2ff0" && project.isGroup === "pending" && 
          <p className="bold" style={{ backgroundColor: companiesColors["K"]}}>Demande de publication</p>
        }
      </div>

      <div className="status border">
        {renderStatus()}
      </div>

      <div className="actions">
        <button
          onClick={() => updateProjectAction(dispatch , {_id : project._id , favorite: project.favorite ? false : true } )} 
          >
          <img src={project.favorite ? starSuccess : star} alt="favorite"/>
        </button>

        <Link to={`/edit-project/${companyId}/${project._id}`}>
          <button>
            <img src={edit} alt="edit"/>
          </button>
        </Link>

        <button
          className={project.company !== "5e9ec41b25ab1e65768e2ff0" && project.isGroup !== "not group" ? "disabled" : ""}
          onClick={() => setRemoveAction({ function : () => deleteProjectAction(dispatch , project._id ) })}>
          <img src={remove} alt="remove"/>
        </button>

      </div>
    </div>
  )
}
