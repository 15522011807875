import React, { useEffect , useCallback , useState } from 'react';
import { useParams , useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { getCompanyAction } from '../../../actions/companiesActions';
import { getMediasAction } from '../../../actions/mediasActions';
import { getDomainsAction , createDomainAction } from '../../../actions/domainsActions';

import DomainRow from '../domains/DomainRow';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';
import './domains.scss';

export default function DomainsList(props) {

  const [ context, dispatch ] = useGlobalContext();
  const [ removeAction , setRemoveAction ] = useState();

  const param = useParams();
  const history = useHistory();

  const { setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav , setActivePage , theme , lang } = props;

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getDomainsAction(dispatch , lang );
    getMediasAction(dispatch)
  },[dispatch , param.companyId , lang ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("domains");
    dispatch({
      type : 'GET_DOMAIN',
      payload : null
    });
    getCompany();
    return () => {
      setDisplaySelectLang(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    history.push(`/domains-list/${param.companyId}/${lang}`)
    // eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    if (context.domains.message === "domain saved" && context.domains.domain ) {
      history.push(`/edit-domain/${param.companyId}/${context.domains.domain._id}`)
    }
    // eslint-disable-next-line
  }, [context.domains.message , context.domains.domain]);

  function createDomain() {
    var domainData = {};
    domainData.lang = lang;
    domainData.company = param.companyId;
    createDomainAction(dispatch , domainData )
  }


  function renderDomainsList() {
    if (context.domains.domainsList && context.domains.domainsList.filter(domain => domain.company === param.companyId).length) {
      return context.domains.domainsList.filter(domain => domain.company === param.companyId).sort((a ,b) => a.position - b.position ).map((domain) => {
        return <DomainRow domain={domain} companyId={param.companyId} setRemoveAction={setRemoveAction} theme={theme} key={domain._id} lang={lang}/>
      })
    } else {
      return <span>Aucun domaine d'activités n'a été trouvé</span>
    }
  }

  return (
    <>    
      <div className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="title">
              <h1>Domaines d'activités ({lang})</h1>
              <div className="bar primary"></div>
            </div>

            {param.companyId !== "5e9ec41b25ab1e65768e2ff0" &&
              <button
                onClick={() => createDomain()}
                className="btn primary">
                Créer un nouveau domaine
              </button>
            }

          </div>

          <div className="list-container">
            {renderDomainsList()}
          </div>
        </div>
      </div>
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
