import React, { useEffect , useCallback , useState } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import BlocText from '../../partials/BlocText';
import EditKeysFigures from '../../partials/EditKeysFigures';


import '../../../styles/pages-sites.scss';

export default function Missions({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'mission';

  const { handleSubmit, register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const [ missions , setMissions ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(false);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage("missions");
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setMissions();
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    setMissions();
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      setMissions({...context.theme.page });
      setIsMounted(true);
    } else {
      setMissions();
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);

  useEffect(() => {
    if (JSON.stringify(missions) === JSON.stringify(context.theme.page)) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
    // eslint-disable-next-line
  },[missions , context.theme.page])

  

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);



  function submitMedia(value) {
    switch (mediaKey) {
      
      case "picture":
        setMissions({...missions, picture: value[0]})
        break;
    
      default:
        break;
    }
  };

  function handleChangeBloc(val , i) {
    var blocs = [...missions.blocs];
    blocs[i] = val;
    setMissions({...missions , blocs: blocs })
  }

  function addBloc() {
    var addBlocs = [...missions.blocs];
    addBlocs.push({ title: "" , text: "" });
    setMissions({...missions , blocs: addBlocs })
  }

  function deleteBloc(bloc) {
    var deleteBloc = [...missions.blocs];
    setMissions({...missions , blocs: deleteBloc.filter(item => item !== bloc)})
  }

  function openSelectCover() {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey('picture');
    setMediaType('image');
    if (missions && missions.picture) {
      var pictureArray = [];
      pictureArray.push(missions.picture);
      setSelectedMediaList(pictureArray)
    }
  }

  const submitmissions = (values)  => {
    var missionsData = {...values, ...missions};
    var obj = {};
    for (const [key, value] of Object.entries(missionsData)) {
        obj[`${page}.${key}`] = value;
    }

    setPublishIsLoading(true);

    if (param.companyId === "5e9ec41b25ab1e65768e2ff0") {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );
    } else {
      updatePageAction(dispatch , context.companies.company.theme[lang] , 'subsidiary' , page , obj  );
    }
    
    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });
    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
  };

  function renderCoverImg() {
    var imgFound;
    if(missions && missions.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === missions.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="missions" />
      } else if (missions.picture && missions.picture.path) {
        return <img src={API_URL + '/' + missions.picture.path} alt="missions" />
      }
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form onSubmit={handleSubmit(submitmissions)} autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                
                <div className="title">
                  <h1 dangerouslySetInnerHTML={{ __html: missions && missions.title ? missions.title : "" }} ></h1>
                </div>

                <label htmlFor="title">Titre</label>
                <input
                  name="title"
                  onChange={(e) => setMissions({...missions, title: e.target.value})}
                  defaultValue={missions && missions && missions.title ? missions.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.title && errors.title.message}</p>

                <div>
                  <input id="isWhite"
                    onChange={() => setMissions({...missions, titleIsWhite: !missions.titleIsWhite })}
                    type="checkbox" className="switch" checked={!missions.titleIsWhite}
                    />
                  <label htmlFor="isWhite">{missions.titleIsWhite ? <span>Titre en blanc</span> : <span>Titre en noir</span> }</label>
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Les paragraphes</h2>
                </div>

                {missions && missions.blocs && missions.blocs.map((bloc , i ) => (<BlocText
                  key={'blocktext' + i }
                  setBlockText={(val) => handleChangeBloc(val , i)}
                  deleteBloc={deleteBloc}
                  setRemoveAction={setRemoveAction}
                  blockText={bloc}
                  index={i} />
                ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 20}}
                    onClick={() => addBloc() }
                    type="button"
                    >
                    Ajouter un paragraphe
                  </button>
                </div>
                
                {param.companyId === "5e9ec41b25ab1e65768e2ff0" &&
                  <>
                    <label>Chiffres clés</label>
                    <EditKeysFigures keyFigures={missions.keyFigures} setRemoveAction={setRemoveAction} handleChange={ keyList => setMissions({...missions, keyFigures: keyList })} theme={theme}/>
                  </>
                }
              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    null
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      >
                      Publier
                    </button>
                  }
                </div>

                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1700 x 350 px</span></label>
                {renderCoverImg()}
                <div className="btn-container">
                  
                  <button
                    type="button"
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectCover(true)}
                    >
                    Parcourir
                  </button>

                  {missions && missions.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction( {function:() => setMissions({...missions, picture: null })} )}
                      >
                      Supprimer
                    </button>
                  }
                </div>

              </div>
            </div>
            
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
      <PromptRouter when={!isSaved} />
      {isMounted && missions && <div id="innovation" className={`page-container site ${theme}`}>
        <div className="page-content">

          <div className="title-row space-between">

            <div className="flex">

              <div className="title">
                <h1>Edition des missions ({lang})</h1>
                <div className="bar primary"></div>
              </div>

            </div>

          </div>

          <div className="list-container">
            {renderEdit()}
          </div>

        </div>
      </div>}
      <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/>
    </>

  )
}
